.offering-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('../../assets/images/MDEOfferings/offerings.png');
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  padding: 0 50px 41%;
}

/* .offering-button {
  width: 188px;
} */
.offering-wrapper ._button {
  width: 188px;
  margin: 16px 0;
}

.offering-heading {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 29px;
  color: #060606;
  margin: 90px 0 30px;
}
.offering-circles-section {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
}
.offering-circles-section-Row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-text {
  font-family: 'Bonheur Royale', sans-serif;
  font-weight: 400;
  font-size: 62.2549px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  bottom: 0;
}
.Brochure_link {
  text-decoration: none;
}
@media (max-width: 1112px) {
  .offering-wrapper {
    background-position: bottom;
  }
}

@media (max-width: 480px) {
  .offering-wrapper {
    padding: 0 0 45%;
  }
  .image-text {
    font-size: 19px;
  }
  .offering-heading {
    margin: 30px 0;
  }
}
