img {
  width: 100%;
}
._form_input_row {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-bottom: 10px; */
}

.label {
  display: flex;
  width: auto;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #262626;
  /* margin-bottom: 2px;
  padding-bottom: 2px; */
}
.attach-icon {
  width: 10px;
  height: 10px;
}
input[type='file'] {
  display: flex;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

._custom_file {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  /* height: 57px; */
  /* background-image: url(../../../assets/images/file-attachment.svg); */
  background-repeat: no-repeat;
  background-position: 13px 15px;
  background-size: 23px;
  background-color: #efefef;
  mix-blend-mode: normal;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #262626;
  border-radius: 4px;
  /* margin-bottom: 10px; */
  padding: 5px;
  /* padding-left: 48px; */
  resize: none;
  margin-top: 15px;
  margin-bottom: 15px;
}
.resume-text {
  padding: 0 9px;
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}
.resume-file {
  color: #094e8f;
}
