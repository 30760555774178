.drop-a-message-main {
  width: 100%;
  height: 100%;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
}
.form-header {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 25px;
  background: #094e8f;
  color: #ffffff;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  letter-spacing: -0.02em;
}

.input_field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.contact-us-form-text-field {
  /* margin-top: 24px; */
  width: calc((100% - 66px));
  margin-bottom: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  max-width: 525px;
  height: 100%;
}

.errorTexts {
  color: #000000;
  /* text-transform: capitalize; */
  font-size: 10px;
}
.error-Img {
  width: 10px;
  height: 10px;
  margin-right: 2px;
  margin-left: 2px;
}
.contactUs-submit {
  margin-top: 35px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (max-width: 991.98px) {
  .drop-a-message-main {
    box-shadow: 2.80342px 2.80342px 16.8205px rgba(0, 0, 0, 0.2);
  }

  .contactUs-submit {
    margin-top: 35px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
