.network-main-container {
  overflow-x: hidden;
  margin-bottom: 40px;
}
.our-network-product-description {
  display: flex;
  padding: 17px 0;
}
.our-network-wrapper {
  display: flex;
}
.our-network-description {
  width: 561px;
}
.our-network-carosal {
  width: 50%;
}

.DataPresence-section {
  margin-top: 60px;
}

.Strategic-partners-section {
  width: 100%;
  margin-top: 50px;
  /* height: 170px; */
  /* box-shadow: 0px -4px 4px rgb(0 0 0 / 15%), 0px 4px 4px rgb(0 0 0 / 15%); */
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.15);
  padding-bottom: 20px;
}
.CardOverview-section {
  margin-top: 37px;
}
.our-network-line {
  width: 842px;
  margin: 0px auto;
}
.our-network-line img {
  width: 100%;
}
.partners-components {
  /* width: 976px; */
  margin: 0px auto;
  /* background-image: url(../../assets//images/partnersBgImage.jpg);
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1; */
}

.banking-partners {
  width: 862px;
  margin: 0px auto;
}
.partner-type-title {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
}
.partner-type-sub-title {
  color: #262626;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.partners-logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.partners-logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin: 40px auto 20px;
}
.log-box:nth-child(1) {
  width: 223px;
  margin-right: 30px;
  align-self: center;
}
.log-box:nth-child(2) {
  width: 254px;
  margin-right: 30px;
  align-self: center;
}
.log-box:nth-child(3) {
  width: 184px;
  align-self: center;
}
.log-box:nth-child(4) {
  width: 298px;
  margin-right: 30px;
  align-self: center;
}
.log-box:nth-child(5) {
  width: 200px;
  align-self: center;
}
.log-box img {
  width: 100%;
  /* aspect-ratio: 3/2;
  object-fit: contain; */
}

.partners-logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin: 20px auto;
}
.dairy-partners .partners-logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* gap: 30px; */
  /* gap: 70px; */
  margin: 20px auto;
  /* width: 900px; */
}
.logo-section {
  display: flex;
  justify-content: center;
  gap: 60px;
}
.dairy-partners .partners-logo .log-box:nth-child(1) {
  width: 190px;
  margin: 0px;
  align-self: center;
}
.dairy-partners .partners-logo .log-box:nth-child(2) {
  width: 213px;
  margin: 0px;
  align-self: center;
}
.dairy-partners .partners-logo .log-box:nth-child(3) {
  width: 247px;
  align-self: center;
}
.dairy-partners .partners-logo .log-box:nth-child(4) {
  width: 182px;
  align-self: center;
}
.dairy-partners .partners-logo .log-box:nth-child(5) {
  width: 227px;
  align-self: center;
}
.dairy-partners .partners-logo .log-box:nth-child(6) {
  width: 160px;
  align-self: center;
}
.dairy-partners .partners-logo .log-box:nth-child(7) {
  width: 182px;
  align-self: center;
}
.dairy-partners .partners-logo .log-box:nth-child(8) {
  width: 188px;
  align-self: center;
}
.dairy-partners .partners-logo .log-box:nth-child(9) {
  width: 182px;
  align-self: center;
}
.dairy-partners .partners-logo .log-box:nth-child(10) {
  width: 168px;
  align-self: center;
}

.map-title {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
}
.map-info-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.country-map-box {
  /* width: 50%; */
  width: 583px;
}
.map-box {
  /* width: 50%; */
  width: 583px;
}
.state-map {
  /* width: 50%; */
  width: 583px;
  max-height: 400px;
}
.state-map img {
  width: 75%;
  /* width: 583px; */
  /* aspect-ratio: 3 / 2; */
}
.state-map.karnataka img {
  width: 583px;
  aspect-ratio: 3 / 2;
}
.state-map.uttar-pradesh img {
  width: 70%;
}
.map-info {
  width: 50%;
  display: flex;
  justify-content: space-around;
  gap: 0px;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
.state-map-info {
  width: 50%;
  display: flex;
  justify-content: space-around;
  gap: 0px;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
.info-box:nth-child(1) {
  width: 145px;
}
.info-box:nth-child(1) .info-logo {
  width: 75px;
  margin: 0px auto;
}
.info-box:nth-child(2) {
  width: 135px;
}
.info-box:nth-child(2) .info-logo {
  width: 61px;
  margin: 0px auto;
}
.info-box:nth-child(3) {
  width: 357px;
}
.info-box:nth-child(3) .info-logo {
  width: 71px;
  margin: 0px auto;
}

.info-logo img {
  width: 100%;
}

.info-name {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.info-count {
  font-family: Poppins;
  font-size: 50px;
  font-weight: 500;
  line-height: 75px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
}
@media screen and (max-width: 991.98px) {
  .our-network-product-description {
    flex-direction: column;
  }

  .Strategic-partners-section {
    width: 100%;
    margin-top: 50px;
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
  }

  .our-network-carosal {
    width: 100%;
  }
  .DataPresence-section {
    margin-top: 0px;
  }

  .CardOverview-section {
    margin-top: 33px;
  }
  .our-network-description {
    width: 324px;
    order: 2;
  }

  .our-network-line {
    width: 227px;
  }
  .partners-components {
    width: 100%;
    margin: 0px auto;
  }
  .banking-partners {
    width: 100%;
    margin: 0px auto;
  }
  .partner-type-title {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #094e8f;
    margin-top: 10px;
  }
  .partner-type-sub-title {
    color: #262626;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .log-box:nth-child(1) {
    width: 174px;
    margin-right: 0px;
    align-self: center;
  }
  .log-box:nth-child(2) {
    width: 178px;
    margin-right: 0px;
    align-self: center;
  }
  .log-box:nth-child(3) {
    width: 177px;
    align-self: center;
  }
  .log-box:nth-child(4) {
    width: 175px;
    margin-right: 0px;
    align-self: center;
  }
  .log-box:nth-child(5) {
    width: 175px;
    align-self: center;
  }
  .dairy-partners .partners-logo {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 30px;
    margin: 20px auto;
    flex-direction: column;
  }
  .dairy-partners .partners-logo .log-box:nth-child(1) {
    width: 191px;
    align-self: center;
  }
  .dairy-partners .partners-logo .log-box:nth-child(2) {
    width: 213px;
    align-self: center;
  }
  .dairy-partners .partners-logo .log-box:nth-child(3) {
    width: 247px;
    align-self: center;
  }
  .dairy-partners .partners-logo .log-box:nth-child(4) {
    width: 182px;
    align-self: center;
  }
  .dairy-partners .partners-logo .log-box:nth-child(5) {
    width: 227px;
    align-self: center;
  }
  .dairy-partners .partners-logo .log-box:nth-child(6) {
    width: 160px;
    align-self: center;
  }
  .dairy-partners .partners-logo .log-box:nth-child(7) {
    width: 182px;
    align-self: center;
  }
  .dairy-partners .partners-logo .log-box:nth-child(8) {
    width: 188px;
    align-self: center;
  }
  .dairy-partners .partners-logo .log-box:nth-child(9) {
    width: 161px;
    align-self: center;
  }
  .dairy-partners .partners-logo .log-box:nth-child(10) {
    width: 268px;
    align-self: center;
  }

  .map-title {
    color: #094e8f;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 37.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 10px;
  }
  .map-info-wrapper {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .map-info {
    width: 65%;
    display: flex;
    justify-content: space-around;
    gap: 5px;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    flex-direction: row;
  }
  .state-map-info {
    width: 95%;
    display: flex;
    justify-content: space-around;
    gap: 5px;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
  }
  .info-box:nth-child(1) {
    width: 66px;
  }
  .info-box:nth-child(1) .info-logo {
    width: 40px;
    margin: 0px auto;
  }
  .info-box:nth-child(2) {
    width: 71px;
  }
  .info-box:nth-child(2) .info-logo {
    width: 31px;
    margin: 0px auto;
  }
  .info-box:nth-child(3) {
    width: 163px;
  }
  .info-box:nth-child(3) .info-logo {
    width: 32px;
    margin: 0px auto;
  }
  .info-name {
    font-family: Poppins;
    font-size: 10.45px;
    font-weight: 500;
    line-height: 15.68px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .info-count {
    font-family: Poppins;
    font-size: 26.14px;
    font-weight: 500;
    line-height: 39.2px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

@media (max-width: 480px) {
  .state-map {
    /* width: 50%; */
    width: 431px;
    margin: 0px auto;
    max-height: 400px;
  }
  .state-map img {
    /* width: 50%; */
    width: 60%;
    /* aspect-ratio: 3/2; */
  }
  .state-map.karnataka img {
    width: 60%;
    aspect-ratio: 2 / 2;
  }
}
