.header-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: #094e8f;
  box-shadow: 0px 2px 2px rgba(6, 6, 6, 0.15);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
  height: 90px;
}

.info-container {
  width: calc(100% - 22%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* flex-grow: 1; */
}

/* .logo {
  width: 177px;
  height: 50px;
  object-fit: cover;
  left: 8.68%;
} */
.logo {
  width: 177px;
}
.MobileLogo {
  display: none;
}

/* .logo-image {
  padding-left: 8%;
} */
/* changes by monali */
.logo-image {
  width: 21%;

  padding-left: 8%;
}

.checkbox,
.hamburger-lines-logo,
.hamburgerClose {
  display: none;
}

@media screen and (max-width: 991.98px) {
  /*------------------Hamburger icon css start--------------------------------------*/
  .logo-image {
    /* width: 22%; */

    padding-left: 10px;
  }
  .header-navbar .checkbox {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    top: 20px;
    right: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .header-navbar .hamburger-lines {
    position: absolute;
    top: 17px;
    right: 20px;
    z-index: 2;
  }

  .hamburger-lines-logo {
    height: 16px;
    width: 30px;
    display: block;
  }

  .hamburgerClose {
    height: 18px;
    width: 18px;
    display: none;
    top: 12px !important;
  }
  /* .header-navbar .hamburger-lines .line {
    display: block;
    height: 2px;
    width: 70%;
    border-radius: 10px;
    background: #ffffff;
  }

  .header-navbar .hamburger-lines .line1 {
    width: 45%;
    align-self: flex-end;
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .header-navbar .hamburger-lines .line2 {
    width: 45%;
    align-self: center;
    transition: transform 0.2s ease-in-out;
  }

  .header-navbar .hamburger-lines .line3 {
    width: 50%;
    align-self: flex-start;
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  .header-navbar input[type='checkbox']:checked ~ .hamburger-lines {
    width: 32px;
  }

  .header-navbar input[type='checkbox']:checked ~ .hamburger-lines .line {
    width: 100%;
  }

  .header-navbar input[type='checkbox']:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .header-navbar input[type='checkbox']:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .header-navbar input[type='checkbox']:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  } */

  .header-navbar input[type='checkbox']:checked ~ .nav-links {
    transform: none;
    padding: 1rem 1rem;
  }
  .header-navbar input[type='checkbox']:checked ~ .header-navbar {
    overflow: hidden;
  }
  .header-navbar input[type='checkbox']:checked ~ .hamburger-lines-logo {
    display: none;
  }
  .header-navbar input[type='checkbox']:checked ~ .hamburgerClose {
    display: block;
  }

  /*------------------Hamburger icon css end----------------------------------------*/
  .logo {
    display: none;
  }
  .MobileLogo {
    display: flex;
    width: 80px;
    padding-left: 4%;
  }

  .contact-info {
    display: none;
  }

  .arrow {
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    margin-left: 0.4rem;
  }

  .header-navbar .logo-image {
    display: flex;
    align-items: center;
  }

  .header-navbar {
    height: 48px;
    background-color: #094e8f;
    /* padding-right: 3%; */
    position: fixed;
    width: 100%;
    top: 0;
  }
}
