._button{
    width: 157px;
    background-color: #ED1C24;
    font-family: "Poppins",sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 24px;
    color: #fff;
    padding: 8px 0;
    margin: 0 0 15px;
    border-radius: 100px;
    cursor: pointer;
}

._button:hover {
    transform: scale(1.1) !important;
    transition: 0.6s !important;
    -webkit-transition: 0.6s !important;
}