.dairy-bre-main-container {
  position: relative;
  cursor: default;
  margin-bottom: 40px;
}
.dairy-bre-info-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dairy-bre-title {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 653px;
  height: 60px;
  color: #094e8f;
  margin: 10px auto;
}
.dairy-bre-image {
  width: 856px;
  margin: 0px auto;
}
.dairy-bre-image img {
  width: 100%;
}

.dairy-bre-feature-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 30px; */
}

.dairy-bre-feature-title {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 180px;
  height: 60px;
  color: #094e8f;
  margin: 20px auto;
}

.dairy-bre-feature-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 25px;
}
.dairy-bre-feature-list .dairy-bre-feature {
  width: 361px;
}
.dairy-bre-feature-list :nth-child(2).dairy-bre-feature {
  width: 340px;
}

@media (max-width: 480px) {
  .dairy-bre-main-container {
    position: relative;
    cursor: default;
    margin-bottom: 20px;
  }
  .dairy-bre-info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .dairy-bre-title {
    width: 223px;
    height: 76px;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 37.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 10px auto;
  }

  .dairy-bre-image {
    width: 325px;
    margin: 0px auto;
  }
  .dairy-bre-image img {
    width: 100%;
  }
  .dairy-bre-feature-title {
    width: 112px;
    height: 38px;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 37.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .dairy-bre-feature-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
  }
  .dairy-bre-feature-list .dairy-bre-feature {
    width: 152px;
    /* height: 355px; */
  }
  .dairy-bre-feature-list .dairy-bre-feature img {
    width: 100%;
    /* height: 355px; */
  }
  .dairy-bre-feature-list :nth-child(2).dairy-bre-feature {
    width: 145px;
  }
}
