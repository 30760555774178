.perks-logo-container {
  width: 150px;
  height: 175px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #094e8f;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  padding: 0.7rem;
  margin: 0.3rem;
}
.perk-logo-title {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  padding: 0.3rem;
}
@media (max-width: 991.98px) {
  .perks-logo-container {
    width: 96px;

    height: 107px;

    display: flex;

    flex-direction: column;

    align-items: center;

    justify-content: center;

    border: 1.7px solid #094e8f;

    border-top-right-radius: 17px;

    border-bottom-left-radius: 17px;

    padding: 5px;

    margin: 0.7rem;
  }

  .perks-logo-container img {
    padding: 3px 10px 5px;
  }

  .perk-logo-title {
    font-size: 9.45225px;

    line-height: 11px;

    padding: 0 0 5px 0;
  }
}

@media (max-width: 375px) {
  .perks-logo-container {
    margin: 0.4rem;
  }
}
