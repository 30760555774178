._MDEForm_plot {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-left: 75px;
}
._MDEForm_plot ._MDEform_header {
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #094e8f;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 21.4914px;
  text-align: center;
  color: #ffffff;
  border-radius: 8.59655px 8.59655px 0px 0px;
}
._mdeform {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 27px;
  background: #ffffff;
  box-shadow: 0px 0px 25.7897px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8.59655px 8.59655px;
}

._mdeform ._form_input_row {
  margin-bottom: 26px;
}
._mdeform ._form_input_row .fClass {
  color: #060606;
}

/* ._mdeform input[type='text']::placeholder {
  color: #060606;
  opacity: 1;
} */
.errorTexts {
  font-family: 'Poppins', sans-serif;
  color: #ed1c24;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 4px 0;
}
@media (max-width: 1112px) {
  ._MDEForm_plot {
    width: 100%;
    padding-left: 0;
    margin: 30px 0;
  }
}
@media (max-width: 480px) {
  ._mdeform {
    padding: 16px;
  }
}
