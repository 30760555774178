img {
  width: 100%;
}

.apply_failure_popup {
  width: 40%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
}

.apply_failure_header {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #094e8f;
  padding: 46px 0;
  border-radius: 6px;
  position: relative;
}
.red-flag {
  width: 60px;
  height: 60px;
  background-image: url(../../assets/images/Careers/redFlag.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  margin-top: 12px;
}

.apply_failure_content {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  padding: 8% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fail-text {
  width: auto;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  color: #262626;
  font-weight: 600;
  text-align: center;
  padding: 20px 0;
}

@media (max-width: 768px) {
  .apply_failure_popup {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .apply_failure_popup {
    width: 90%;
  }
}
