.events-desktop-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.container-2x1 {
  display: flex;
  /* align-items: start; */
  align-items: stretch;
  gap: 10px;
}
.container-60 {
  width: 58%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.container-60 .line {
  width: 90%;
  margin: 10px auto 10px;
  border: 1.42px solid #e0e1e3;
}
.container-60 .component-4X2-text {
  width: 100%;
  font-family: Poppins;
  font-size: 24.02px;
  font-weight: 500;
  line-height: 36.03px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
  margin: 0px auto;
  padding: 0px 10px 32px;
}

.container-1x1 {
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 10px;
}
