/* ._board_of_director {
  width: 100%;
  margin: 20px 0;
}

.section_title {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 40px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 50px;
}

.director_block_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0em;
}
.director_block_slider {
  background-image: url('../../components//MediaEvents/media_events/background_img2.png');
  background-size: cover;
  overflow-x: hidden;
  filter: drop-shadow(1px 4px 0px rgba(0, 0, 0, 0.25));
  padding: 10px;
}

.director_block_slider .slick-slider .slick-prev::before {
  background-image: url('../../components//MediaEvents/event/left_arrow.png');
  background-size: cover;
  font-size: 50px;
}

.director_block_slider .slick-slider .slick-next::before {
  background-image: url('../../components//MediaEvents/event/right_arrow.png');
  background-size: cover;
  font-size: 50px;
}
.director_block_slider .slick-slider .slick-prev,
.director_block_slider .slick-slider .slick-next {
  position: absolute;
  top: 50%;
  font-size: 60px;
  z-index: 2;
}
.director_block_slider .slick-slider .slick-next {
  right: 35px;
}
.director_block_slider .slick-slider .slick-prev {
  left: 5px;
}
.director_block_slider .slick-slide {
  margin-right: 16px;
  text-align: center;
}
.slick-prev:before,
.slick-next:before {
  color: transparent;
}

.director-img {
  width: 290px;
  height: 310px;
  display: flex;
}

.director_block {
  display: flex;
  flex-direction: column;
  border: 1px solid #094e8f;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
  position: relative;
  flex: 0 0 24%;
}
.director_block:hover .director-info {
  opacity: 1;
  transition: opacity 1s;
}
.director-name {
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  padding: 3px;
}
.director-info {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #094e8f;
  padding-left: 5px;
  padding-top: 10px;
  text-align: left;
  opacity: 0;
  border: none;
}
ul.director-info-ul {
  list-style-type: none;
}

li.director-info-list {
  display: flex;
  align-items: center;
  position: relative;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding-left: 15px;
}

li.director-info-list::before {
  content: '';
  background-color: #ffc444;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 7px;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInUp_1 {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@media (max-width: 1112px) {
  .director_block_wrapper {
    gap: 2em;
    justify-content: center;
  }
  .director_block {
    flex: 0 0 38%;
  }
  .section_title {
    margin-top: 40px;
  }
}
@media (max-width: 635px) {
  .director_block {
    flex: 0 0 45%;
  }
}

@media (max-width: 567px) {
  .director_block {
    flex: 0 0 84%;
  }
  .director_block_wrapper {
    gap: 2em;
    justify-content: center;
  }
  li.director-info-list {
    font-size: 16px;
    line-height: 160%;
  }
  .director-img {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .section_title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  li.director-info-list {
    font-size: 16px;
    line-height: 160%;
  }
  .director-img {
    width: 100%;
  }
} */

.director-main-container {
  position: relative;
  margin-bottom: 40px;
}
.director-text-section {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 60px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 364px;
  height: 60px;
  color: #094e8f;
  margin: 0px auto;
}

.director-info-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
  flex-wrap: wrap;
  margin-top: 20px;
}
.director-wrapper {
  width: 300px;
  margin: 0px auto;
}
.director-image {
  width: 242px;
  margin: 0px auto;
}
.director-image {
  width: 100%;
}
.director-nane {
  font-family: Poppins;
  font-size: 24.82px;
  font-weight: 600;
  line-height: 37.23px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
  width: fit-content;
  margin: 0px auto;
}
.director-company-logo {
  /* height: 80px; */
  margin: 0px auto;
  /* display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px; */
  /* width: 300px; */
}
.director-wrapper:nth-child(1) .director-company-logo {
  width: 264px;
}
.director-wrapper:nth-child(2) .director-company-logo {
  width: 196px;
}
.director-wrapper:nth-child(3) .director-company-logo {
  width: 302px;
}

.director-wrapper:nth-child(4) .director-company-logo {
  width: 206px;
}
.director-wrapper:nth-child(5) .director-company-logo {
  width: 72px;
}
.director-wrapper:nth-child(6) .director-company-logo {
  width: 261px;
}

@media (max-width: 1112px) {
  .director-main-container {
    position: relative;
    margin-bottom: 0px;
  }
  .director-text-section {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 37.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 231px;
  }

  .director-info-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 items per row */
    gap: 20px;
    margin-top: 0px;
  }
  l .director-wrapper {
    width: 153px;
    margin: 5px auto;
  }
  .director-nane {
    font-family: Poppins;
    font-size: 14.59px;
    font-weight: 600;
    line-height: 21.89px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: max-content;
  }
  .director-wrapper:nth-child(1) .director-company-logo {
    width: 153px;
  }
  .director-wrapper:nth-child(2) .director-company-logo {
    width: 115px;
  }
  .director-wrapper:nth-child(3) .director-company-logo {
    width: 148px;
  }

  .director-wrapper:nth-child(4) .director-company-logo {
    width: 107px;
  }
  .director-wrapper:nth-child(5) .director-company-logo {
    width: 42px;
  }
  .director-wrapper:nth-child(6) .director-company-logo {
    width: 155px;
  }
}
@media (max-width: 635px) {
  .director-main-container {
    position: relative;
    margin-bottom: 0px;
  }
  .director-text-section {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 37.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 231px;
  }
  .director-info-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 0px;
  }
  .director-wrapper {
    width: 153px;
    margin: 5px auto;
  }
  .director-nane {
    font-family: Poppins;
    font-size: 14.59px;
    font-weight: 600;
    line-height: 21.89px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: max-content;
  }
  .director-wrapper:nth-child(1) .director-company-logo {
    width: 153px;
  }
  .director-wrapper:nth-child(2) .director-company-logo {
    width: 115px;
  }
  .director-wrapper:nth-child(3) .director-company-logo {
    width: 148px;
  }

  .director-wrapper:nth-child(4) .director-company-logo {
    width: 107px;
  }
  .director-wrapper:nth-child(5) .director-company-logo {
    width: 40px;
  }
  .director-wrapper:nth-child(6) .director-company-logo {
    width: 155px;
  }
}
@media (max-width: 567px) {
  .director-main-container {
    position: relative;
    margin-bottom: 0px;
  }
  .director-text-section {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 37.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 231px;
  }
  .director-info-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 0px;
  }
  .director-wrapper {
    width: 153px;
    margin: 5px auto;
  }
  .director-nane {
    font-family: Poppins;
    font-size: 14.59px;
    font-weight: 600;
    line-height: 21.89px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: max-content;
  }
  .director-wrapper:nth-child(1) .director-company-logo {
    width: 153px;
  }
  .director-wrapper:nth-child(2) .director-company-logo {
    width: 115px;
  }
  .director-wrapper:nth-child(3) .director-company-logo {
    width: 148px;
  }

  .director-wrapper:nth-child(4) .director-company-logo {
    width: 107px;
  }
  .director-wrapper:nth-child(5) .director-company-logo {
    width: 40px;
  }
  .director-wrapper:nth-child(6) .director-company-logo {
    width: 155px;
  }
}
@media (max-width: 480px) {
  .director-main-container {
    position: relative;
    margin-bottom: 0px;
  }
  .director-text-section {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 37.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 231px;
  }
  .director-info-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 0px;
  }
  .director-wrapper {
    width: 153px;
    margin: 5px auto;
  }
  .director-nane {
    font-family: Poppins;
    font-size: 14.59px;
    font-weight: 600;
    line-height: 21.89px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: max-content;
  }

  .director-wrapper:nth-child(1) .director-company-logo {
    width: 153px;
  }
  .director-wrapper:nth-child(2) .director-company-logo {
    width: 115px;
  }
  .director-wrapper:nth-child(3) .director-company-logo {
    width: 148px;
  }

  .director-wrapper:nth-child(4) .director-company-logo {
    width: 107px;
  }
  .director-wrapper:nth-child(5) .director-company-logo {
    width: 40px;
  }
  .director-wrapper:nth-child(6) .director-company-logo {
    width: 155px;
  }
}
