.mdesuccesspopup_plot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 6;
}
.mdesuccesspopup {
  width: 425px;
  background-color: #fff;
  border-radius: 6.39823px;
}

.success_header {
  display: flex;
  height: 101px;
  position: relative;
  background: #094e8f;
}
.success_header ._close_success {
  width: 32px;
  height: 32px;
  background-image: url(../../assets/images/close-icon.png);
  background-repeat: no-repeat;
  background-size: 14px;
  border-radius: 50%;
  background-position: center;
  position: absolute;
  right: 10px;
  top: 10px;
}
.success_header .checkmark {
  width: 60px;
  height: 60px;
  background-image: url(../../assets/images/green_tick.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 50%;
  background-position: center;
  position: absolute;
  bottom: -25px;
  left: calc((100% - 60px) / 2);
}
.success_header .checkmark.error{
background-image: url("../../assets/images/Careers/redFlag.svg");
}
.success_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 50px 20px;
}
.success_content .success-msg {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #262626;
  margin-bottom: 20px;
}
.success_content ._close_pop {
  width: 157px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ed1c24;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #f4f8ff;
  border-radius: 100px;
  cursor: pointer;
}

@media (max-width: 480px) {
  .mdesuccesspopup {
    width: 310px;
  }
  .success_content {
    padding: 40px 40px 20px;
  }
  .success_content .success-msg {
    font-size: 10px;
  }
  .success_content ._close_pop {
    width: 114px;
    height: 32px;
    font-size: 12px;
  }
}
