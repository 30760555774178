.product-info-box {
  display: flex;
  padding: 17px 0;
}

/* .Product-wrapper .Product-route{
    height: 48px;
    margin-bottom: 17px;
    flex-direction: column;
    align-content: center;
  
  } */
.Product-wrapper .Product-slider {
  width: 100%;
  margin-bottom: 50px;
}

.Product-wrapper .Product-animation {
  width: 100%;
}

.insurance-Product-benefits {
  width: 100%;
  /* margin-bottom: 50px; */
}
.insurance-Product-benefits {
  width: 950px;
  margin: 20px auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.Product-footer {
  position: relative;
  bottom: 0;
}

.image-container {
  width: 100%;
  margin: 60px 0;
}

.money-image-web {
  display: block;
}

.money-image-mobile {
  display: none;
}
.insurance-image-container {
  width: 943px;
  margin: 30px auto;
  /* background-image: url('../../../assets//images/insurance-bg-image.png'); */
}
.insurance-image-container img {
  width: 100%;
}

.insurance-benefits-title {
  width: 166px;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
  margin: 0px auto;
}

.benefites-img {
  width: 246px;
  height: 297px;
}
.benefites-img img {
  width: 100%;
  height: unset;
}
.benefites-text {
  font-family: Lato;
  font-size: 19.9px;
  font-weight: 500;
  line-height: 23.89px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.benefits-box:nth-child(1) .benefites-text {
  position: absolute;
  top: 1335px;
  left: 310px;
  width: 152px;
  width: 152px;
}
.benefits-box:nth-child(2) .benefites-text {
  position: absolute;
  top: 1335px;
  left: 605px;
  width: 164px;
}
.benefits-box:nth-child(3) .benefites-text {
  position: absolute;
  top: 1335px;
  left: 910px;
  width: 152px;
  width: 152px;
}
.benefits-box:nth-child(4) .benefites-text {
  position: absolute;
  top: 1670px;
  left: 330px;
  width: 152px;
  width: 152px;
}
.benefits-box:nth-child(5) .benefites-text {
  position: absolute;
  top: 1660px;
  left: 605px;
  width: 152px;
  width: 152px;
}
.benefits-box:nth-child(6) .benefites-text {
  position: absolute;
  top: 1660px;
  left: 905px;
  width: 172px;
}

.benefits-box:nth-child(4) {
  margin-top: 30px;
}
.benefits-box:nth-child(5) {
  margin-top: 30px;
}
.benefits-box:nth-child(6) {
  margin-top: 30px;
}

@media (max-width: 1112px) {
  .Product-wrapper .insurance-Product-benefits {
    width: 100%;
  }
  .insurance-image-container {
    width: 325px;
    margin: 0px auto 15px auto;
  }
  .insurance-image-container img {
    width: 100%;
  }
  .benefits-box:nth-child(3) {
    margin-top: 30px;
  } 
  .benefits-box:nth-child(4) {
    margin-top: 30px;
  }
  .benefits-box:nth-child(5) {
    margin-top: 30px;
  }
  .benefits-box:nth-child(6) {
    margin-top: 30px;
  }
}

@media screen and (max-width: 991.98px) {
  .main-div {
    overflow-x: hidden;
  }

  .product-info-box {
    /* flex-wrap: wrap; */
    flex-direction: column;
  }

  .money-image-web {
    display: none;
  }

  .money-image-mobile {
    display: block;
  }
  .insurance-Product-benefits {
    margin-bottom: 0;
  }
  .insurance-image-container {
    width: 325px;
    margin: 0px auto 15px auto;
  }
  .insurance-image-container img {
    width: 100%;
  }

  .insurance-Product-benefits {
    width: 315px;
    margin: 20px auto;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .benefits-box {
    width: 140px;
  }
  .benefites-img {
    width: 140px;
    height: 170px;
  }
  .benefites-img img {
    width: 100%;
  }
  .insurance-Product-benefits {
    width: 350px;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .benefites-text {
    font-family: Lato;
    font-size: 11.25px;
    font-weight: 500;
    line-height: 13.49px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .benefits-box:nth-child(1) .benefites-text {
    position: absolute;
    top: 1030px;
    left: 60px;
    width: 85px;
  }
  .benefits-box:nth-child(2) .benefites-text {
    position: absolute;
    top: 1030px;
    left: 235px;
    width: 90px;
  }
  .benefits-box:nth-child(3) .benefites-text {
    position: absolute;
    top: 1335px;
    left: 910px;
    width: 152px;
    width: 152px;
  }
  .benefits-box:nth-child(4) .benefites-text {
    position: absolute;
    top: 1670px;
    left: 330px;
    width: 152px;
    width: 152px;
  }
  .benefits-box:nth-child(5) .benefites-text {
    position: absolute;
    top: 1660px;
    left: 605px;
    width: 152px;
    width: 152px;
  }
  .benefits-box:nth-child(6) .benefites-text {
    position: absolute;
    top: 1660px;
    left: 905px;
    width: 172px;
  }
}

@media (max-width: 780px) {
  .slider {
    width: 100%;
  }

  .insurance-Product-benefits {
    width: 315px;
    margin: 20px auto;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .insurance-Product-benefits {
    width: 350px;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
@media (max-width: 480px) {
  .Product-wrapper .insurance-Product-benefits {
    width: 100%;
    /* margin-bottom: 400px; */
  }
  .insurance-image-container {
    width: 325px;
    margin: 0px auto 15px auto;
  }
  .insurance-image-container img {
    width: 100%;
  }

  .insurance-Product-benefits {
    width: 100%;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .benefits-box {
    width: 140px;
  }
  .benefites-img {
    width: 140px;
    height: 170px;
  }
  .benefites-img img {
    width: 100%;
  }
  .insurance-Product-benefits {
    width: 100%;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .benefites-text {
    font-family: Lato;
    font-size: 11.25px;
    font-weight: 500;
    line-height: 13.49px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .benefits-box:nth-child(1) .benefites-text {
    position: absolute;
    top: 1035px;
    left: 60px;
    width: 85px;
  }
  .benefits-box:nth-child(2) .benefites-text {
    position: absolute;
    top: 1035px;
    left: 220px;
    width: 90px;
  }
  .benefits-box:nth-child(3) .benefites-text {
    position: absolute;
    top: 1235px;
    left: 60px;
    width: 82px;
  }
  .benefits-box:nth-child(4) .benefites-text {
    position: absolute;
    top: 1235px;
    left: 230px;
    width: 77px;
  }
  .benefits-box:nth-child(5) .benefites-text {
    position: absolute;
    top: 1430px;
    left: 60px;
    width: 87px;
  }
  .benefits-box:nth-child(6) .benefites-text {
    position: absolute;
    top: 1430px;
    left: 220px;
    width: 97px;
  }
}
