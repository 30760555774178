.benefits-header {
  width: 100%;
  font-family: 'poppins', sans-serif;
  font-size: 40px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.benefits-wrapper {
  display: flex;

  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.benefits-outer {
  width: 200px;
  height: 240px;
  border: hsl(209, 88%, 30%) solid 1px;
  border-bottom-left-radius: 75px;
  border-top-right-radius: 75px;
  box-shadow: 3px 3px 4px #ed1c24, -3px -3px 4px #094e8f;
  padding: 6px;
}

.benefits-outer:last-child {
  margin-right: 0;
}

.benefits-inner {
  height: 100%;

  border-top-right-radius: 75px;
  border-bottom-left-radius: 75px;
  border: 0.396244px dashed #060606;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  filter: none;
}

.benefits-contain {
  font-size: 1.125rem;
  font-family: 'poppins', sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
  align-self: center;
  color: #094e8f;
  text-align: center;

  padding: 9px;
}

@media (max-width: 1112px) {
  .benefits-wrapper {
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 0px;
  }

  .benefits-outer {
    margin-bottom: 40px;

    height: 275px;
    border: hsl(209, 88%, 30%) solid 0px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 75px;
    box-shadow: 3px 3px 4px #ed1c24, -3px -3px 4px #094e8f;
    padding: 9px 7px;
  }

  .benefits-contain {
    padding: 0px 18px;
  }

  .benefits-outer:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .benefits-outer {
    width: calc((100% - 40px) / 2);
    margin-right: 40px;

    height: 189px;

    border: hsl(209, 88%, 30%) solid 0px;
    border-bottom-left-radius: 56px;
    border-top-right-radius: 56px;
    box-shadow: 3px 3px 4px #ed1c24, -3px -3px 4px #094e8f;

    padding: 6px 4px;
  }

  .benefits-inner {
    height: 100%;
    border-top-right-radius: 53px;
    border-bottom-left-radius: 56px;
  }
  .benefits-outer:nth-child(2n) {
    margin-right: 0;
  }
  .benefits-outer:nth-child(3n) {
    margin-right: 40px;
  }
  .benefit-wrapper:nth-child(4n) {
    padding-right: 10px;
  }
  .benefits-contain {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    width: 121px;
    color: #094e8f;
    padding: 0px 3px;
  }
}

@media (max-width: 360px) {
  .benefits-contain {
    font-size: 12px;
  }
  .benefits-outer {
    width: 140px;
    height: 178px;
  }
}
