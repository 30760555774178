.product-info-box {
  display: flex;
  padding: 17px 0;
}

/* .Product-wrapper .Product-route{
    height: 48px;
    margin-bottom: 17px;
    flex-direction: column;
    align-content: center;
  
  } */
.Product-wrapper .Product-slider {
  width: 100%;
  margin-bottom: 50px;
}

.Product-wrapper .Product-animation {
  width: 100%;
}

.Product-benefits {
  width: 100%;
  margin-bottom: 50px;
}

.Product-footer {
  position: relative;
  bottom: 0;
}

.pay-image-container {
  width: 659px;
  margin: 120px auto;
}
.pay-image-container-mob {
  display: none;
}

/* .money-image-web {
  display: block;
} */

.money-image-mobile {
  display: none;
}

@media (max-width: 1112px) {
  .Product-wrapper .Product-benefits {
    width: 100%;
  }
  .pay-image-container {
    display: block;
    width: 338.04px;
    height: 318px;
    top: 563px;
    left: 16px;
    gap: 0px;
    opacity: 1px;
  }
  .pay-image-container {
    width: auto;
    margin: 0px auto 40px auto;
  }
  .pay-image-container-mob {
    width: 338px;
    margin: 10px auto;
  }
}

@media screen and (max-width: 991.98px) {
  .main-div {
    overflow-x: hidden;
  }

  .product-info-box {
    /* flex-wrap: wrap; */
    flex-direction: column;
  }

  .money-image-web {
    display: none;
  }

  .money-image-mobile {
    display: block;
  }
  .Product-benefits {
    margin-bottom: 0;
  }
}

@media (max-width: 780px) {
  .slider {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .Product-wrapper .Product-benefits {
    width: 100%;
    /* margin-bottom: 400px; */
  }
}
