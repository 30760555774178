._close {
  width: 30px;
  height: 30px;
  background-image: url(../../../assets/images/close-icon.png);
  background-color: rgba(43, 83, 159, 0.15);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
  position: absolute;
  top: -18px;
  right: 0%;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 7px;
  border-radius: 50%;
  cursor: pointer;
}
._close-apply {
  width: 30px;
  height: 30px;
  background-image: url(../../../assets/images/close-icon.png);

  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
  position: absolute;
  top: 15px;
  right: 5%;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 7px;
  border-radius: 50%;
  cursor: pointer;
}
