.forMoreDetail-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.forMoreDetail-heading {
  display: flex;
  justify-content: center;
  width: 913px;
  margin: 10px auto 0px auto;
  /* border-bottom: 0.5px solid #094e8f; */
  font-weight: 600;
  color: #094e8f;
  font-size: 25px;
}
.details {
  width: 469px;
  margin: 10px auto;
  /* border-bottom: 0.5px solid #094e8f; */
}
.mail-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  gap: 15px;
}
.mail-info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px 0px 10px;
  font-weight: 600;
}
.mail-info a {
  text-decoration: none;
  color: #060606;
}
.email-icon {
  height: 32px;
  width: 32px;
}
.mail-id {
  font-weight: 600;
}
.for-more-detail-top-line {
  width: 913px;
  align-self: center;
  height: 0px;
}
.for-more-info-bottom-line {
  opacity: 1;
}
@media (max-width: 480px) {
  .forMoreDetail-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 5px;
  }
  .forMoreDetail-heading {
    display: flex;
    justify-content: center;
    width: 307px;
    margin: 10px auto 0px auto;
    /* border-bottom: 0.5px solid #094e8f; */
    font-weight: 600;
    color: #094e8f;
    font-size: 25px;
  }
  .mail-icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    gap: 5px;
  }
  .details {
    width: 135px;
    margin: 0px auto;
    /* border-bottom: 0.5px solid #094e8f; */
  }
  .mail-info {
    padding: 10px;
  }
  .mail-info a {
    text-decoration: none;
    color: #060606;
    font-size: 14px;
  }
  .for-more-info-bottom-line {
    margin-top: -15px;
  }
  .for-more-detail-top-line {
    width: 307px;
    align-self: center;
    height: 0px;
  }
  .for-more-detail-top-line img {
    width: 100%;
  }
}
