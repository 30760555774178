.qualify-main-container {
  position: relative;
  margin-bottom: 50px;
  cursor: default;
}
.qualify-logo {
  width: 484px;
  margin: 0px auto;
}
.qualify-logo img {
  width: 100%;
}
.quality-section-box {
  display: flex;
  align-items: center;
  /* gap: 75px; */
  justify-content: space-between;
}
.quality-wrapper {
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  gap: 30px;
}
.quality-left-box {
  width: 243.63px;
  height: 371.28px;
  border-radius: 42.03px 11.68px 11.68px 42.03px;
  background: linear-gradient(180deg, rgba(234, 65, 59, 0.5) 40%, rgba(255, 216, 98, 0.4) 72.18%, #ffd862 100%);
  position: relative;
  top: -52px;
}
.quality-rigth-box {
  width: 229px;
  height: 428.68px;
  top: 810px;
  left: 647px;
  gap: 0px;
  /* border-radius: 33.31px 9.25px 9.25px 33.31px; */
  border-radius: 9.25px 33.31px 33.31px 9.25px;
  /* angle: -180 deg; */
  background: linear-gradient(180deg, rgba(234, 65, 59, 0.5) 40%, rgba(255, 216, 98, 0.4) 72.18%, #ffd862 100%);
  margin-top: 10px;
}
.quality-text-left {
  font-family: Poppins;
  font-size: 18.84px;
  font-weight: 600;
  line-height: 28.26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 100%;
  height: 280px;
  padding: 30px;
}
.quality-text-right {
  font-family: Poppins;
  font-size: 18.84px;
  font-weight: 600;
  line-height: 28.26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 100%;
  height: 280px;
  padding: 23px;
}
.qualify-newspaper-box {
  width: 530px;
  margin: 0px auto;
}
.qualify-newspaper-box img {
  width: 100%;
}

.quality-click-here-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}
.quality-click-here-box a {
  font-family: Lato;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: auto;
  cursor: pointer;
  color: #060606;
  text-decoration: none;
}

@media (max-width: 480px) {
  .qualify-main-container {
    position: relative;
    margin-bottom: 20px;
    cursor: default;
  }
  .qualify-logo {
    width: auto;
    margin: 0px auto;
  }
  .qualify-logo img {
    width: 100%;
  }
  .quality-section-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 75px; */
    justify-content: space-between;
  }
  .quality-wrapper {
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    gap: 20px;
  }
  .quality-left-box {
    width: 148.48px;
    height: 226.28px;
    top: 682px;
    left: 0px;
    gap: 0px;
    border-radius: 25.62px 7.12px 7.12px 25.62px;
    position: relative;
    top: 0px;
  }
  .quality-rigth-box {
    border-radius: 9.25px 33.31px 33.31px 9.25px;
    margin-top: 45px;
    width: 139.57px;
    height: 261.27px;
    top: 730.15px;
    left: 334px;
    gap: 0px;
    background: linear-gradient(180deg, rgba(234, 65, 59, 0.5) 40%, rgba(255, 216, 98, 0.4) 72.18%, #ffd862 100%);
  }
  .quality-text-left {
    width: 140.92px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 25px 0px 0px 15px;
  }
  .quality-text-right {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 133.84px;
    height: 234px;
    padding: 10px 6px 0px 10px;
  }
  .qualify-newspaper-box {
    width: 326px;
    margin: 25px auto 0px auto;
  }
  .qualify-newspaper-box img {
    width: 100%;
  }

  .quality-click-here-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
  }
  .quality-click-here-box a {
    width: auto;
    height: 17px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
