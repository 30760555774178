@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  /* line-height: 0; */
}

.dairy-overview-container {
  width: 100%;
  margin: 0 auto;
  overflow-y: hidden;
}

.dairy-overview-title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  margin-bottom: 53px;
  text-align: center;
  color: #094e8f;
}

.chart {
  display: flex;
  gap: 48.58px;
  justify-content: space-between;
}

.dairy-overview-background-img {
  width: 157.45px;
  height: 290.95px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  background-position: center;
  background-size: cover;
  padding: 10px 12px 15px 12px;
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 40px;
  font-style: normal;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-weight: 400;
  line-height: 45px;
  color: #000;
}

/* -----------------------old css //////////////////////////////////////////////////// */
/* 
.counter-block {
  font-size: 80px;
}
.counter-block span {
  padding: 30px 10px 40px 10px;
  display: inline-block;
}
.counter-block .counter-text {
  float: left;
  padding-right: 20px;
  position: relative;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
}
.counter-number {
  animation: countdown 3s forwards;
}
.counter-number i {
  position: absolute;
  color: black;
}
.counter-number i:nth-child(1) {
  top: -100%;
}
.counter-number i:nth-child(2) {
  top: -200%;
}
.counter-number i:nth-child(3) {
  top: -300%;
}
.counter-number i:nth-child(4) {
  top: -400%;
}
.counter-number i:nth-child(5) {
  top: -500%;
}
.counter-number i:nth-child(6) {
  top: -600%;
}
.counter-number i:nth-child(7) {
  top: -700%;
}
.counter-number i:nth-child(8) {
  top: -800%;
}
.counter-number i:nth-child(9) {
  top: -900%;
}
.counter-number i:nth-child(10) {
  top: -1000%;
}
@keyframes countdown {
  from {
    transform: translateY(1000%);
  }
  to {
    transform: translateY(0);
  }
} */
/* -------------------------- old css /////////////////////////////////////// */

.sub-heading {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin-bottom: 34.03px;
}

.desc {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19.2px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 10px;
}

.icon {
  width: 26px;
  height: 24px;
  margin: 0 auto;
  margin-top: auto;
}

.icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.animate__animated.animate__delay-6s {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}
.animate__animated.animate__delay-7s {
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}
.animate__animated.animate__delay-8s {
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
}
.animate__animated.animate__delay-9s {
  -webkit-animation-delay: 9s;
  animation-delay: 9s;
}

.animate__animated.animate__delay-10s {
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
}
.animate__animated.animate__delay-11s {
  -webkit-animation-delay: 11s;
  animation-delay: 11s;
}

.animate__animated.animate__delay-12s {
  -webkit-animation-delay: 12s;
  animation-delay: 12s;
}
.animate__animated.animate__delay-13s {
  -webkit-animation-delay: 13s;
  animation-delay: 13s;
}
.animate__animated.animate__delay-15s {
  -webkit-animation-delay: 15s;
  animation-delay: 15s;
}
.animate__animated.animate__delay-16s {
  -webkit-animation-delay: 16s;
  animation-delay: 16s;
}

@media (max-width: 1220px) {
  .chart {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    padding: 0 10%;
  }
  .dairy-overview-background-imgg {
    justify-self: center;
    width: 70%;
    margin-bottom: 30px;
  }
}

@media (max-width: 860px) {
  .chart {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 10px;
    column-gap: 30px;
    row-gap: 20px;
  }
  .dairy-overview-background-img {
    justify-self: center;
    width: 100%;
    margin-bottom: 30px;
  }
}

@media (max-width: 540px) {
  .dairy-overview-title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 145.5%;
    padding: 15px 30px 0 30px;
    text-align: center;
    margin-bottom: 27px;
  }

  .chart {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 15px;
    column-gap: 46px;
    row-gap: 40px;
  }

  .dairy-overview-background-img {
    justify-self: center;
    width: 141.11px;
    height: 260.61px;
    margin-bottom: 10px;
    padding-top: 0;
  }

  .desc {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14.3313px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
  }

  .sub-heading {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17.9141px;
    line-height: 27px;
    text-align: center;
    color: #000000;
  }

  .heading {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 35.8282px;
    line-height: 54px;
    text-align: center;
    color: #000000;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin: 0 auto;
    margin-top: auto;
  }
}

@media (max-width: 340px) {
  .chart {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media (max-width: 416px) {
  .chart {
    display: grid;
    column-gap: 25px;
    row-gap: 40px;
  }
}
