.breadCrumb {
  position: fixed;
  top: 90px;
  z-index: 4;
  height: 45px;
  color: black;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  padding: 0 8%;
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.breadCrumb > .text-link {
  text-decoration: none;
  color: #000000;
  line-height: 19px;
  font-weight: 500;
  font-family: 'Lato', sans-serif;
}

.breadCrumb > .text-link:last-child {
  color: #094e8f;
  font-weight: 600;
  line-height: 19px;
  font-family: 'Lato', sans-serif;
}

.breadCrumb > .text-link > span {
  padding-left: 5px;
  padding-right: 5px;
}

@media (max-width: 991.98px) {
  .breadCrumb {
    font-size: 0.9rem;
    top: 48px;
    padding: 0 16px;
    height: 30px;
    /* padding-top: 5px; */
  }

  /* .menu-text {
    display: none;
  } */
}
