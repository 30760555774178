.benefit-title-text {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  /* color: #060606; */
  color: #094e8f;
  text-align: center;
  margin-bottom: 30px;
}

@media screen and (max-width: 991.98px) {
  .benefit-title-text {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 30px;
    text-align: center;
  }
}
