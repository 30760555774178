.aboutUs-info-plot {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.meet-our-CEO-section {
  margin-top: 0px;
  display: flex;
  justify-content: center;
}
.vision-mission-section {
  margin-top: 50px;
}

.life-at-dgv {
  margin-top: 50px;
}

.CEO-para {
  margin-top: 25px;
  margin-bottom: 25px;
}

.sepuration-line {
  width: 842px;
  margin: 0px auto;
}
.sepuration-line img {
  width: 100%;
}

.certificates-container {
  position: relative;
}
.certificate-text {
  color: #094e8f;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 60px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 270px;
  margin: 0px auto;
  margin-bottom: 20px;
}
.certificate-images-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 993px;
  margin: 0px auto;
  margin-bottom: 10px;
}
.certicate-box:nth-child(1) {
  width: 230px;
}
.certicate-box:nth-child(2) {
  width: 432px;
}
.certicate-box:nth-child(3) {
  width: 230px;
}

.vision-mission {
  background-image: url('../../../assets/images/Vision-&-Mission-bg.png');
  height: 528px;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 1025px;
  margin: 0px auto;
}
.vision-mission-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 70px;
}
.vision-section {
  width: 45%;
}
.middle-line-section {
  width: 6px;
  height: 317px;
  padding-top: 60px;
}
.visio-logo {
  margin: 0px auto;
  width: 160px;
}
.visio-logo img {
  width: 100%;
}
.visio-desc {
  width: 425px;
  margin: 0px auto;
}
.middle-line-section img {
  width: 100%;
}
.mission-section {
  width: 45%;
}
.mission-logo {
  width: 182px;
  margin: 0px auto;
}
.mission-logo img {
  width: 100%;
}
.mission-desc {
  width: 420px;
  margin: 0px auto;
}
@media screen and (max-width: 991.98px) {
  .aboutUs-info-plot {
    flex-direction: column;
    margin-top: 0;
  }

  .meet-our-CEO-section {
    margin-top: 0px;
  }
  .aboutUs-main-container {
    overflow: hidden;
  }
  .vision-mission-section {
    margin-top: 0px;
  }
  .life-at-dgv {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .sepuration-line {
    width: 325px;
    margin: 0px auto;
  }
  .CEO-para {
    margin-top: 25px;
    margin-bottom: 5px;
  }
  .certificate-text {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 37.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 172px;
  }
  .certificate-images-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 315px;
    margin: 0px auto;
    margin-bottom: 10px;
    /* flex-direction: column; */
    flex-wrap: wrap;
    gap: 10px;
  }
  .certicate-box:nth-child(1) {
    width: 148px;
  }
  .certicate-box:nth-child(2) {
    width: 148px;
  }
  .certicate-box:nth-child(3) {
    width: 315px;
  }

  .vision-mission {
    background-image: url('../../../assets/images/Mission_and_Vision_mob_bg.png');
    height: 355px;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 300px;
    margin: 0px auto;
  }

  .vision-mission-wrapper {
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .vision-section {
    width: 100%;
  }
  .visio-logo {
    margin: 0px auto;
    width: 80px;
  }
  .visio-desc {
    width: 310px;
    margin: 0px auto;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .mission-section {
    width: 100%;
  }
  .mission-logo {
    width: 90px;
    margin: 0px auto;
  }
  .mission-desc {
    width: 310px;
    margin: 0px auto;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
