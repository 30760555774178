.partner-speaks-container {
  position: relative;
}
.partner-speaks-nav {
  width: 1000px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mob-WTN-text {
  display: none;
}
.nav-btn {
  width: 133px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
  cursor: pointer;
}
.nav-btn:nth-child(3) {
  width: 327px;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
  cursor: default;
}

.nav-btn.active-btn {
  justify-content: center;
  width: 164px;
  height: 46px;
  align-items: center;
  display: flex;
  border-radius: 0px 20px 0px 20px;
  opacity: 1;
  align-self: center;
  text-align: center;
  background: linear-gradient(0deg, #094e8f, #094e8f),
    linear-gradient(90deg, rgba(237, 28, 36, 0.4) 20.12%, rgba(255, 255, 255, 0.4) 100%);

  color: #000; /* Text color */
  text-align: center;
  background: linear-gradient(to right, #d8c9d9, #d8e1f1); /* Gradient background */
  border: none; /* Remove border */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.partner-speak-title {
  background-color: #b5cadd;
  width: 450px;
  height: 61.19px;
  border-radius: 0px 15.89px 0px 15.89px;
  opacity: 1px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  top: 5px;
  left: calc((100% - 450px) / 2);
}

.partner-speaks-1 {
  width: 200px;
  color: #094e8f;
  font-family: Poppins;
  font-size: 44.84px;
  font-weight: 600;
  line-height: 67.26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.partner-speaks-2 {
  width: 165px;
  font-family: Poppins;
  font-size: 44.84px;
  font-weight: 600;
  line-height: 67.26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

@media (max-width: 480px) {
  .partner-speaks-container .slider-box .section .react-responsive-3d-carousel {
    height: 235px !important;
  }
  .partner-speaks-container .section {
    height: 240px;
  }
  .partner-speaks-nav {
    width: 95%;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mob-WTN-text {
    display: block;
    width: 140px;
    margin: 0px auto;
    font-family: Poppins;
    font-size: 12.08px;
    font-weight: 600;
    line-height: 18.11px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 99px;
    margin-bottom: 10px;
    color: #094e8f;
  }
  .nav-btn {
    /* width: 66px; */
    width: auto;
    height: 12px;

    font-family: Poppins;
    font-size: 8px;
    font-weight: 500;
    line-height: 12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .nav-btn.active-btn {
    justify-content: center;
    width: 66px;
    height: 14px;
    align-items: center;
    display: flex;
    border-radius: 0px 8px 0px 8px;
    opacity: 1;
    align-self: center;
    text-align: center;
    background: linear-gradient(0deg, #094e8f, #094e8f),
      linear-gradient(90deg, rgba(237, 28, 36, 0.4) 20.12%, rgba(255, 255, 255, 0.4) 100%);

    color: #000; /* Text color */
    text-align: center;
    background: linear-gradient(to right, #d8c9d9, #d8e1f1); /* Gradient background */
    border: none; /* Remove border */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  .nav-btn:nth-child(3) {
    display: none;
    width: 327px;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #094e8f;
  }

  .partner-speak-title {
    width: 152.74px;
    height: 20.03px;
    gap: 0px;
    border-radius: 0px 4.83px 0px 4.83px;
  }

  .partner-speaks-1 {
    font-family: Poppins;
    font-size: 13.62px;
    font-weight: 600;
    line-height: 20.42px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 61px;
    height: 20px;
    left: 104.76px;
    gap: 0px;
    width: 61px;
    height: 20px;
    left: 104.76px;
    gap: 0px;
  }
  .partner-speaks-2 {
    /* box-shadow: 0px 0.99px 0.99px 0px #00000040; */
    /* background: #FFFFFF; */
    font-family: Poppins;
    font-size: 13.62px;
    font-weight: 600;
    line-height: 20.42px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 50.91px;
    height: 19.99px;
    left: 167.31px;
    gap: 0px;
  }
}
