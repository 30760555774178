.events-desktop-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  /* width: 321px; */
  margin: 0px auto;
}
.box-1x1 {
  width: 50%;
}
.box-1x1 .with-watch-now-main-component {
  width: 100% !important;
  height: 230px !important;
}
.box-1x1 .with-watch-now-sub-title {
  font-family: Poppins;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.box-1x1 .with-watch-now-sub-title {
  color: #094e8f;
  font-family: Poppins;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 10px;
  min-height: 0px;
}

.box-1x1 .with-watch-now-title {
  font-family: Poppins;
  font-size: 15.92px;
  font-weight: 600;
  line-height: 23.87px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.box-1x1 .with-watch-now-btn {
  margin-left: 0px;
}
.box-1x1 .read-more-btn {
  margin-left: 0px;
}
.box-1x1 .read-more-btn a {
  margin-left: 0px;
  text-decoration: none;
}
.box-1x1 .with-watch-now-info-box {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 480px) {
  .mediaEvent-main-container .section {
    height: 230px;
  }
}
