._mde_container {
  display: flex;
  width: 100%;
  flex-direction: column;
}
._mde_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
._mde_header ._mde_logo {
  width: 156px;
  height: 100px;
}
._mde_header ._mde_logo img {
  width: 100%;
}
._mde_header ._mde_title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 32.6793px;
  text-align: center;
  text-transform: uppercase;
  color: #094e8f;
}
._mde_solutions_settings {
  width: 100%;
  display: flex;
  margin-top: 40px;
}

@media (max-width: 1112px) {
  ._mde_container {
    padding: 0 16px;
  }
  ._mde_header ._mde_logo {
    width: 117px;
    height: 77px;
  }
  ._mde_solutions_settings {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  ._mde_container {
    padding: 0;
  }
  ._mde_header ._mde_title {
    font-size: 20px;
    margin: 10px 0;
  }
}
