.contactUs-contain {
  width: 70%;
  margin-bottom: 15px;
}

.contactUs-contain-header {
  width: 100%;
  font-family: 'poppins', sans-serif;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #094e8f;
}

.contactUs-contain-para {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}

@media (max-width: 1112px) {
  .contactUs-banner {
    display: flex;
    flex-direction: column-reverse;
  }

  .contactUs-contain-header {
    width: 100%;
    font-family: 'poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
  }

  .contactUs-contain-para {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
  .contactUs-contain {
    width: 100%;
    margin-top: 10px;
  }
}
