/* Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px; /* Add padding for smaller screens */
  box-sizing: border-box;
}

/* Popup Content */
.popup-content {
  background: #094e8f;
  padding: 10px;
  border-radius: 8px;
  /* width: 100%;
  max-width: 885px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  width: 60%;
  overflow-y: auto;
  max-height: 90%;
}
.popup-content::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  /* height: 10px; Height of the scrollbar (for horizontal scrollbars) */
}

.popup-content::-webkit-scrollbar-thumb {
  background: #555; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

.popup-content::-webkit-scrollbar-thumb:hover {
  background: #333; /* Darker color on hover */
}

.popup-content::-webkit-scrollbar-track {
  background: #eee; /* Background color of the scrollbar track */
  border-radius: 10px; /* Rounded corners for the track */
}

.popup-content::-webkit-scrollbar-track:hover {
  background: #ddd; /* Lighter track background on hover */
}

/* Close Button */
.close-btn {
  position: absolute;
  top: -4px;
  right: 2px;
  font-size: 24px;
  /* font-weight: bold; */
  color: #fff;
  cursor: pointer;
}

/* Main Content */
.popup-main-content {
  /* display: flex; */
  gap: 0px;
  margin-bottom: 20px;
  margin-top: 12px;
  flex-direction: column;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}
.popup-main-content iframe {
  border-radius: 4px;
  width: 828px;
  margin: 0px auto;
  padding: 10px 10px;
}

.popup-image {
  width: 100%;
  /* max-width: 250px; */
  max-width: 830pxpx;
  height: auto;
  border-radius: 8px;
}
.popup-main-content iframe {
  border-radius: 4px;
  width: 100%;
  margin: 0px auto;
  height: 300px;
  /* padding: 10px 10px; */
}
.popup-header {
  padding: 0px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.popup-header-text {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: auto;
  color: #fff;
}
.popup-description {
  flex: 1;
  text-align: start;
  min-width: 200px;
  padding: 0px 10px 10px;
}

.popup-description h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #fff;
}

.popup-description p {
  color: #fff;
  font-size: 1rem;
  line-height: 1.5;
  font-size: 18px;
}

/* Slider Section */
.other-event-text {
  margin-bottom: 40px;
  font-size: 1.2rem;
  text-align: start;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
}
.other-event-wrapper {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  gap: 14px;
  max-height: 60px;
}

.slider-container {
  margin-top: 10px;
}

.slider-item {
  text-align: center;
  width: 265px;
  height: 120px;
  position: relative;
}
.slider-item img {
  width: 100%;
}

.slider-image {
  width: 80px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 5px;
}

.slide-text {
  margin-top: 5px;
  position: absolute;
  top: 25%;
  left: 20%;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 60px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 166px;
  margin: 0px auto;
  color: #fff;
}
.other-event-item {
  width: 100px;
}

.line-with-dot {
  display: flex;
  align-items: center;
  position: relative;
  margin: 10px 0;
  width: 100%;
}

.line-with-dot::before {
  content: '';
  width: 10px; /* Size of the dot */
  height: 10px;
  background-color: white; /* Dot color */
  border-radius: 50%;
  position: absolute;
  left: 0; /* Position the dot at the start of the line */
}

.line-with-dot::after {
  content: '';
  flex-grow: 1;
  height: 2px;
  width: 100%;
  position: relative;
  right: 5px;
  background-color: white;
  margin-left: 15px;
}
.popupImageSlider-box {
  padding: 15px;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.popupImageSlider-box .slick-slide {
  width: 160px;
  /* height: 500px; */
}
.popupImageSlider-box .slick-slide img {
  width: 100%;
  /* aspect-ratio: 2/1; */
  aspect-ratio: 5/3;
}
.popupImageSlider-box .slick-slider .slick-arrow.slick-next:before,
.popupImageSlider-box .slick-slider .slick-arrow.slick-prev:before {
  display: none;
}
.popupImageSlider-box .slick-slider .slick-arrow.slick-next {
  background-image: url('../../assets/images/nextArrow.png');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}
.popupImageSlider-box .slick-slider .slick-arrow.slick-prev {
  background-image: url('../../assets/images/prevArrow.png');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}
.playBTN {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 43%;
  left: calc((100% - 100px) / 2);
}
.playBTN img {
  width: auto;
  aspect-ratio: auto !important;
}
/* Responsive Design */
@media (max-width: 768px) {
  .popup-content {
    background: #094e8f;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 90%;
  }

  .popup-main-content {
    flex-direction: column; /* Stack image and text vertically */
    align-items: start;
    margin-bottom: 0px;
  }
  .other-event-text {
    font-family: Poppins;
    font-size: 5.96px;
    font-weight: 600;
    line-height: 8.94px;
    text-align: justified;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-top: 5px;
  }
  .with-watch-now-btn {
    margin-left: 8px;
  }
  .popup-other-events {
    padding-left: 10px;
  }
  .popup-main-content iframe {
    border-radius: 4px;
  }

  .popup-image {
    max-width: 100%;
  }

  .popup-description h3 {
    font-size: 1.2rem;
  }

  .popup-description p {
    font-size: 0.9rem;
  }

  /* .slider-image {
    width: 60px;
    height: 60px;
  } */
  .slider-item {
    text-align: center;
    width: 67px;
  }
  .slider-item img {
    width: 100%;
  }

  .slider-item p {
    font-size: 0.8rem;
  }
  .other-event-wrapper {
    justify-content: flex-start;
    display: flex;
    align-items: start;
    gap: 14px;
    max-height: 60px;
  }
  .popupImageSlider-box .slick-slide img {
    width: 100%;
    aspect-ratio: 0;
  }
  .playBTN {
    width: 33px;
    height: 33px;
    position: absolute;
    top: 40%;
    left: calc((100% - 33px) / 2);
  }
}

@media (max-width: 480px) {
  .popup-content {
    background: #094e8f;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 90%;
  }

  .popup-description h3 {
    font-size: 1rem;
  }

  .popup-description p {
    font-size: 10px;
  }

  .slider-image {
    width: 50px;
    height: 50px;
  }

  .slider-item p {
    font-size: 0.7rem;
  }
  .slider-item {
    text-align: center;
    width: 90px;
  }
  .slider-item img {
    width: 100%;
  }
  .other-event-wrapper {
    justify-content: flex-start;
    display: flex;
    align-items: start;
    gap: 14px;
    max-height: 60px;
  }
  .other-event-text {
    font-family: Poppins;
    font-size: 5.96px;
    font-weight: 600;
    line-height: 8.94px;
    text-align: justified;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .slide-text {
    font-family: Poppins;
    font-size: 13.24px;
    font-weight: 600;
    line-height: 19.86px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0px auto;
    top: 15%;
    width: 55px;
    left: calc((100% - 55px) / 2);
  }
  .popup-header-text {
    font-family: Poppins;
    font-size: 13.24px;
    font-weight: 600;
    line-height: 19.86px;
    text-align: justified;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    min-width: 32px;
  }
  .line-with-dot {
    display: flex;
    align-items: center;
    position: relative;
    margin: 10px 0;
  }

  .line-with-dot::before {
    content: '';
    width: 10px; /* Size of the dot */
    height: 10px;
    background-color: white; /* Dot color */
    border-radius: 50%;
    position: absolute;
    left: 0; /* Position the dot at the start of the line */
  }

  .line-with-dot::after {
    content: '';
    flex-grow: 1;
    height: 2px;
    width: 185px;
    position: relative;
    right: 5px;
    background-color: white;
    margin-left: 15px;
  }
  .popup-main-content iframe {
    border-radius: 4px;
    width: 100%;
    margin: 0px auto;
    height: 170px;
    /* padding: 10px 10px; */
  }
  .popupImageSlider-box .slick-slide img {
    width: 100%;
    /* aspect-ratio: 2; */
    aspect-ratio: 5/3;
  }
  .playBTN {
    width: 33px;
    height: 33px;
    position: absolute;
    top: 40%;
    left: calc((100% - 33px) / 2);
  }
}
