._mdesolutions_plot {
  display: flex;
  flex-direction: column;
  width: 50%;
}
._mdesolutions_plot .solutions_title {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 29px;
  color: #060606;
  margin-bottom: 12px;
}
.solutions_points {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.solutions_list {
  float: left;
  list-style: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: #060606;
}
.solutions_list img.bullets {
  float: left;
  width: 22px;
  margin-right: 15px;
}
.bullets-text {
  float: left;
  width: calc(100% - 37px);
  line-height: 25px;
  margin-bottom: 20px;
}
.mde_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 164px;
  background-color: transparent;
  border: 2px solid #ed1c24;
  transition: transform 0.8s;
  border-radius: 29.0636px;
}
.mde_button:hover {
  -ms-transform: scale(1); /* IE 9 */
  -webkit-transform: scale(1); /* Safari 3-8 */
  transform: scale(1);
}
.mde_button ._play-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ed1c24;
  border-radius: 50%;
}
.mde_button ._play-icon .play {
  width: 16px;
}
.mde_button p.text-watch {
  width: calc(100% - 40px);
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #ed1c24;
  padding-right: 8px;
}
@media (max-width: 1112px) {
  ._mdesolutions_plot {
    width: 100%;
  }
  ._mdesolutions_plot ._video_button {
    height: unset;
  }
}

@media (max-width: 480px) {
  ._mdesolutions_plot .solutions_title {
    font-size: 16px;
  }
  .solutions_list {
    font-size: 14px;
  }
  .solutions_list img.bullets {
    width: 12px;
    margin-top: 8px;
  }
  .mde_button {
    width: 121px;
  }
  .mde_button ._play-icon {
    width: 30px;
    height: 30px;
  }
  .mde_button p.text-watch {
    width: calc(100% - 30px);
    font-size: 12px;
  }
}
