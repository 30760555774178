:root {
  --bs-font: 'Poppins', 'Lato', sans-serif;
  --animate-delay: 2s;
  --animate-duration: 2s;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  scroll-padding-top: 130px;
  scroll-behavior: smooth;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: var(--bs-font);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lato-400 {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
.lato-500 {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
.LineHeight19 {
  line-height: 19.2px;
}
.LineHeight21 {
  line-height: 21.6px;
}
.poppins-500 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}
.poppins-600 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

*:focus {
  outline: none;
}

.page-content {
  margin-top: 150px;
}

.page-container {
  padding: 0 8%;
}

@media (max-width: 991.98px) {
  .page-container {
    padding: 0 16px;
  }

  .page-content {
    margin-top: 88px;
  }
}
@media (min-width: 1440px) {
  .desktop-view {
    max-width: 1440px;

    margin-left: auto;

    margin-right: auto;
  }

  .header-navbar {
    width: 1440px !important;
  }

  .breadCrumb {
    width: 1440px !important;

    padding: 0 110px !important;
  }
}
