img {
  width: 100%;
}
.perks-banner {
  background-image: url('../../assets/images//background.png');
  width: 100%;
  margin: 50px 0;
}
.perks-heading {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #094e8f;
  text-align: center;
  padding: 30px 0;
}
.image-logos-container {
  display: flex;
}
.perks-banner-left {
  width: 46%;
  /* padding-right: 24px; */
}
.perks-banner-right {
  width: 57%;
}
.mobile-logos-container {
  display: none;
}
.perk-row1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.perk-row2 {
  display: flex;
  justify-content: space-evenly;
  padding: 1rem 3rem;
}
.perk-row3 {
  display: flex;
  justify-content: space-evenly;
  padding: 0rem 8rem;
}

@media (max-width: 991.98px) {
  .image-logos-container {
    display: block;
  }
  .perks-banner {
    margin-top: 30px;
  }
  .perks-banner-left {
    width: 100%;
    padding-right: 0;
  }
  .perks-heading {
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
    padding: 0.5rem 0;
  }
  .perks-banner-right {
    width: 100%;
    margin: 1rem 0;
  }
  .perk-row1 {
    display: none;
  }
  .perk-row2 {
    display: none;
  }
  .perk-row3 {
    display: none;
  }
  .mobile-logos-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .mobile-logos-container > div {
    display: flex;
    flex-basis: 33.33%;
    justify-content: space-around;
  }
}
