@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  /* line-height: 0; */
}

.core-management-wrapper {
  width: 1050px;
  margin: 0 auto;
}

.title {
  width: 511px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #094e8f;
  margin-bottom: 26px;
  margin: 10px auto;
  margin-bottom: 30px;
}

.core_team_main {
  /* display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 0px;
  row-gap: 50px; */
  display: flex;
  gap: 30px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.linkedIn_img {
  position: absolute;
  top: 53%;
  right: -7%;
  width: 38px;
}

.linkedIn_img img {
  width: 100%;
  border-radius: 50%;
  background-color: #ffffff;
}
.team_member_plot {
  display: flex;
  /* width: calc((100% - 120px) / 5); */
  width: 270px;
  margin-right: 32px;
  flex-direction: column;
  margin-bottom: 50px;
}

/* .team_member_plot:nth-child(5n) {
  margin-right: 0;
} */
.team_member {
  position: relative;
  /* width: 165px; */
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-bottom: 25px;
  border-radius: 50%;
}

.team_member img {
  display: block;
  width: 100%;
}

.member_name {
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 5px;

  font-family: Poppins;
  font-size: 21.79px;
  font-weight: 400;
  line-height: 32.68px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  text-align: center;
}

.designation {
  color: #ef1923;
  font-family: Poppins;
  font-size: 21.79px;
  font-weight: 500;
  line-height: 25.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px auto;
  text-align: center;
}

.team_member_plot .team_member {
  width: 165px;
}
@media (max-width: 1112px) {
  .core-management-wrapper {
    width: 100%;
    margin: 0 auto;
  }

  .core_team_main {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 40px;
    column-gap: 70px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0px;
  }
  .team_member_plot:nth-child(5n) {
    margin-right: 32px;
  }
  .core-team {
    display: flex;
    flex-direction: column;
  }
  .designation {
    /* width: 280px; */
  }
}

@media (max-width: 790px) {
  .team_member_plot {
    width: 183px;
  }

  .team_member_plot:nth-child(3n) {
    margin-right: 0;
  }
  .core_team_main {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 35px; */
  }
}

@media (max-width: 520px) {
  .core-management-wrapper {
    width: 100%;
    margin: 0 auto;
  }

  .title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #094e8f;
    margin-bottom: 15px;
    width: 246px;
  }

  .team_member_plot {
    width: 250px;
    margin-right: 0;
  }
  .team_member_plot:nth-child(5n) {
    margin-right: 0px;
  }

  .core_team_main {
    /* display: grid;
    grid-template-columns: 1fr;
    row-gap: 40px; */
  }
  .team_member {
    margin-bottom: 10px;
  }
}
