redressal-main-container {
  position: relative;
}
.grievance-redressal-info-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.grievance-redressal-title-box {
  float: left;
  margin: 20px 0px;
}
.grievance-redressal-heading {
  width: 420px;
  font-size: 40px;
  color: #094e8f;
  font-weight: 600;
  line-height: 60px;
}
.grievance-redressal-sub-heading {
  width: 420px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}
.grievance-redressal-image-box {
  width: 362px;
  margin: 25px 85px;
}
.grievance-redressal-image-box img {
  width: 100%;
}

.grievance-redressal-cin-line {
  display: flex;
  align-items: center;
  /* margin: 30px 0px; */
  height: 75px;
  position: absolute;
}
.grievance-redressal-cin-line img {
  height: 100%;
}
.grievance-redressal-social-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}
/* .grievance-redressal-contact-box {
  display: flex;
  flex-direction: column;
} */

.grievance-redressal-contact-box {
  display: flex;
  flex-direction: column;
  width: 291px;
  align-items: end;
  text-align: end;
}
.grievance-redressal-title-img {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.grievance-redressal-img {
  width: 32px;
  height: 32px;
}
.grievance-redressal-img img {
  width: 100%;
  height: unset !important;
}
.grievance-redressal-title {
  font-weight: 600;
  font-size: 16px;
}
.grievance-redressal-info {
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  line-height: 24px;
}
.grievance-redressal-info a {
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  text-decoration: none;
  color: #000000;
}
.grievance-redressal-top-line {
  width: 913px;
  margin: 0px auto;
  align-self: center;
}
.grievance-redressal-bottom-line {
  width: 469px;
  margin: 0px auto;
}
@media (max-width: 1112px) {
  .grievance-redressal-info-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }
  .grievance-redressal-bottom-line {
    width: 135px;
    margin: 0px auto;
  }
  .grievance-redressal-top-line {
    width: 307px;
    margin: 0px auto;
    align-self: center;
  }
}

@media screen and (max-width: 991.98px) {
  .grievance-redressal-info-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }
  .grievance-redressal-top-line {
    width: 307px;
    margin: 0px auto;
    align-self: center;
  }
}

@media (max-width: 780px) {
  .grievance-redressal-info-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }
  .grievance-redressal-top-line {
    width: 307px;
    margin: 0px auto;
    align-self: center;
  }
}
@media (max-width: 480px) {
  .grievance-redressal-info-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .grievance-mob-contact .grievance-redressal-title-img {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  .grievance-mob-email .grievance-redressal-title-img {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    position: relative;
    right: 8px;
  }
  .grievance-redressal-image-box {
    width: auto;
    margin: 10px 25px;
  }
  .grievance-redressal-image-box img {
    width: 100%;
  }
  .grievance-redressal-heading {
    width: auto;
    font-size: 25px;
    color: #094e8f;
    font-weight: 600;
    line-height: 60px;
  }
  .grievance-redressal-sub-heading {
    width: 314px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }
  .grievance-redressal-social-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 10px 0px 10px;
    flex-direction: column;
    /* gap: 15px; */
  }
  .grievance-redressal-title {
    /* font-weight: 600;
    font-size: 14px; */
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.1em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .grievance-mob-container {
    width: 100%;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .grievance-mob-contact .grievance-redressal-info {
    /* font-size: 10px;
    font-weight: 600;
    margin-top: 10px;
    line-height: 24px; */
    width: 108px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 10px auto 0px auto;
  }
  .grievance-mob-email .grievance-redressal-info {
    /* font-size: 10px;
    font-weight: 600;
    margin-top: 10px;
    line-height: 24px; */
    width: 220px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 6px auto 0px auto;
  }
  .grievance-mon-middle-line {
    width: 307px;
    margin: 0px auto;
  }
  .grievance-mon-middle-line img {
    width: 100%;
  }
  .grievance-mon-bottom-line {
    width: 135px;
    margin: 0px auto;
  }
  .grievance-mon-bottom-line img {
    width: 100%;
  }
  .grievance-redressal-info a {
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    line-height: 24px;
  }
  .grievance-redressal-mail-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .grievance-redressal-img {
    width: 21px;
    height: 21px;
  }
  .grievance-redressal-img img {
    width: 100%;
    height: unset !important;
  }
  .grievance-redressal-cin-line {
    display: none;
  }
  .grievance-redressal-top-line {
    width: 307px;
    margin: 0px auto;
    align-self: center;
  }
}
