@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&family=Work+Sans:wght@200&display=swap');

img {
  width: 100%;
}

.banner-slide {
  width: 100%;
  position: relative;
}

.home-banner-img {
  width: 100%;
  position: relative;
}

/* .home-banner-img:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #ed1c24;
  background-blend-mode: multiply;
  opacity: 0.6;
} */

.banner-desc-slide3 {
  width: 53%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bottom-mr {
  margin-bottom: 30px;
}
.banner-title {
  font-size: 30px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
}

.banner-heading {
  font-size: 50px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  text-align: center;
  margin: 20px 0;
}

.mob-title {
  font-size: 25px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #ffffff;
  padding: 0 8%;
}
.landing_product_block {
  position: relative;
}
/* .know-more-slide3 {
  width: auto;
  background-color: #fff;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #094e8f;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  border-radius: 20px;

  margin-top: 8%;
  font-weight: 500;
  z-index: 1;
} */
.u-tube-btn {
  margin-top: 40px;
  z-index: 1;
}
.know-more-middle {
  position: absolute;
  bottom: -80px;
}
.home-banner-img .desktop-img {
  display: block;
}
.home-banner-img .mob-img {
  display: none;
}
.know-more:hover {
  -ms-transform: scale(1.1) !important; /* IE 9 */

  -webkit-transform: scale(1.1) !important; /* Safari 3-8 */

  transform: scale(1.1) !important;

  transition: 0.6s !important;

  -webkit-transition: 0.6s !important;
}
@media (max-width: 1112px) {
  .banner-title {
    font-size: 20px;
  }
  .banner-heading {
    font-size: 25px;
  }
  .mob-title {
    font-size: 18px;
  }
  .home-banner-img {
    /* height: 100vh; */
  }
  .home-banner-img img {
    /* height: 100%; */
    height: calc(92.3vh - 48px) !important;
  }

  .know-more {
    font-size: 12px;
  }
  .home-banner-img .desktop-img {
    display: none;
  }
  .home-banner-img .mob-img {
    display: block;
  }
  .know-more-middle {
    margin-top: 12px !important;
    padding: 5px 35px !important;
    position: inherit !important;
    bottom: auto !important;
  }
}

@media (max-width: 480px) {
  .know-button {
    margin-top: 20%;
  }

  .home-banner-plot {
    margin-top: 48px !important;
  }
  .banner-heading {
    margin: 30px 16px;
  }
  .mob-title {
    padding: 0 53px 10px;
    font-family: 'Lato', sans-serif;
  }

  .know-more-slide3 {
    margin-top: 50px;
  }
  .know-more-middle {
    margin-top: 12px !important;
    padding: 5px 35px !important;
    position: inherit !important;
    bottom: auto !important;
  }

  .landing_product_wrapper {
    padding: 20px 14px !important;
  }

  .font-lato {
    font-family: 'Lato', sans-serif !important;
  }

  ._landing_product_icon img {
    height: 16px !important;
    width: auto;
  }

  ._landing_product_icon {
    width: auto !important;
    text-align: center;
  }

  .banner-desc-slide3 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 110px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .u-tube-btn {
    margin-top: 0px;
    z-index: 1;
  }
}

@media screen and (min-height: 721) and (max-width: 480px) {
  .home-banner-img img {
    height: calc(92.3vh - 48px) !important;
  }
}

@media screen and (max-height: 721) and (max-width: 480px) {
  .home-banner-img img {
    height: calc(91.3vh - 48px) !important;
  }
}

@media screen and (max-height: 801px) and (max-width: 480px) {
  ._landing_product_desc {
    font-size: 18px;
  }
  .landing_product_block {
    margin-bottom: 20px;
  }
  .landing_product_wrapper {
    padding-top: 12px !important;
  }
}

@media screen and (max-height: 650px) and (max-width: 480px) {
  ._landing_product_desc {
    font-size: 18px;
  }
  .landing_product_block {
    margin-bottom: 20px;
  }
  .home-banner-img img {
    height: calc(100vh - 48px) !important;
  }
  .landing_product_wrapper {
    padding-top: 10px !important;
  }
}

@supports (-webkit-touch-callout: none) {
  .home-banner-img img {
    height: calc(90.2vh - 48px) !important;
  }
}
