.daily-union-desk-wrappwer {
  margin: 40px auto;
  display: flex;
}

.daily-union-left-section {
  width: 647px;
}
.paddingBootm {
  padding-bottom: 50px;
}

.daily-farmer-right {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .daily-union-mob-wrappwer {
    margin: 20px auto;
  }
}
