.saas-info-section {
  display: flex;
  justify-content: space-around;
  align-items: self-start;
  padding-top: 10px;
}
.saas-title-box {
  width: 649px;
}
.saas-heading-title {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
  width: 583px;
  text-align: left;
  padding-top: 30px;
}
.saas-heading-sub-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #060606;
  width: 649px;
  margin: 20px 0px 0px 0px;
}
.saas-image-box {
  width: 347px;
}
.saas-image-box img {
  width: 100%;
}
.saas-multiple-page-section {
  /* float: left; */
  width: 100%;
  margin-top: 40px;
}
.saas-option-bar {
  background-color: #094e8f;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  height: 50px;
}

.saas-dgv-navigation-btn {
  background: #094e8f;
  border: 0.5px solid #002e5833;
  width: 212px;
  height: 30px;
  /* gap: 0px; */
  border-radius: 200px;
  text-align: center;
  padding: 2px;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-family: Calibri;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.97px;
  /* text-align: left; */
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.saas-dgv-navigation-btn.active {
  box-shadow: 0px 1px 4px 0px #ffffff;
  box-shadow: 0px 0px 4px 0px #ffffff;
}

@media (max-width: 480px) {
  .saas-info-section {
    display: flex;
    justify-content: space-around;
    align-items: self-start;
    padding-top: 15px;
    flex-direction: column-reverse;
  }
  .saas-image-box {
    width: 210px;
    margin: 0px auto;
  }
  .saas-image-box img {
    width: 100%;
  }
  .saas-title-box {
    width: 100%;
  }
  .saas-heading-title {
    width: 294px;
    height: 76px;
    opacity: 1px;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 37.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding-top: 2px;
  }
  .saas-heading-sub-title {
    width: 314px;
    height: 181px;
    top: 354.65px;
    left: 17px;
    gap: 0px;
    opacity: 1px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .saas-mob-multiple-page-section {
    display: flex;
    flex-direction: column;
    margin: 30px auto 0px auto;
    gap: 20px;
  }
  .saas-mob-nav-btn {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    background-color: #094e8f;
  }
  .saas-mob-btn-name {
    font-family: Calibri;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.97px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    /* width: 129.59px; */
    height: 22px;
    /* top: 1285px;
    left: 29px;
    gap: 0px; */
    opacity: 1px;
    color: #ffffff;
  }
  .saas-mob-btn-img {
    height: 20px;
    width: 20px;
  }
  .saas-mob-btn-img img {
    width: 100%;
  }
  .saas-mob-nav {
    margin-top: 20px;
  }
}
