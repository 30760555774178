.without-watch-now-section-top {
  width: 647px;
  border-radius: 6px;
  box-shadow: 2.7px 2.7px 27.02px 0px rgba(0, 0, 0, 0.15);
}

.without-watch-now-image-section {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  /* background: rgba(109, 98, 192, 1); */
}
.without-watch-now-image-box {
  width: 335px;
  height: 186px;
  margin: 0px auto;
}
.middle-line {
  width: 610px;
  border: 1.41px 0px 0px 0px;
  border: 1.41px solid rgba(224, 225, 227, 1);
  margin: 10px auto;
}
.without-watch-now-title-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
}
.without-watch-now-dics {
  color: #094e8f;
  width: 480px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 5px auto 10px;
}
