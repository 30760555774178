._productinfo_plot {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

@media (max-width: 1112px) {
  ._productinfo_plot {
    width: 100%;
    display: flex;
    margin-top: 0px;
    flex-direction: column;
  }
}
