@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

img {
  width: 100%;
}
.visit-us {
  width: 100%;
  /* padding: 10px; */
  margin-top: 45px;
  display: flex;
  margin-bottom: 33px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15), 0px -2px 0px rgba(0, 0, 0, 0.1);
}
.visit-us-header {
  background: #094e8f;
  width: 50px;
  padding: 0px 0px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  display: flex;
  justify-content: center;
}

.visit-us-head {
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 30px;
  width: 100%;

  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.offices {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-left: 0px;
  width: 100%;
  justify-content: space-around;
}

.Office-header {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #094e8f;
  margin-bottom: 15px;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: center;
}

.Office-para {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.01em;
  word-wrap: break-word;
  margin-bottom: 15px;
  color: #000000;
  height: 95px;
}
.stateHead_office {
  width: 376px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.01em;
  word-wrap: break-word;
  margin-bottom: 15px;
  color: #000000;
  height: 95px;
  text-align: center;
}
.register_office {
  /* width: 290px; */
  width: 376px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.01em;
  word-wrap: break-word;
  margin-bottom: 15px;
  color: #000000;
  height: 95px;
  text-align: center;
}
.corporate_office {
  /* width: 324px; */
  width: 376px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.01em;
  word-wrap: break-word;
  margin-bottom: 15px;
  color: #000000;
  height: 95px;
  text-align: center;
}
.Contact-line {
  position: absolute;
  width: 138px;
  height: 0px;
}

.location-contain {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Location-icon {
  width: 22px;
  height: 29px;
}

.viwe-on-map {
  width: auto;
  /* font-family: 'Poppins', sans-serif; */
  /* font-weight: 600;
  font-size: 20px;
  color: #094e8f; */
  border-bottom: 1.6px solid #094e8f;
  margin-left: 10px;
}

.viwe-on-map a {
  text-decoration: none;
  color: #094e8f;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
}

.call-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.number {
  display: flex;
  justify-content: space-evenly;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
}

.call-center-text {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
}

.call-img {
  width: 17px;
  display: flex;
}

.number-one {
  /* margin-right: 20px; */
}

.contact-medium {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.contact-medium-wrapper {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.visit-us-wrapper {
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

.office-address-info {
  /* width: 70%; */
  width: calc(40.33333% - 110px);
  position: relative;
}
.office-address-info:not(:last-child) {
  margin-right: 22px;
  padding-right: 28px;
  /* background-image: url(../../assets/images/Contact-us-line.png); */
  background-position: right;
  background-repeat: no-repeat;
}

.Contact-us-line {
  width: 16px;
  margin-right: 30px;
  margin-left: 1px;
}

.Contact-us-line-call {
  width: 10px;
}
.Contact_us_hori_line {
  width: 874px;
  margin: 10px 0 2px 0;
}
.join-us {
  display: flex;
  justify-content: center;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #060606;
}
.emailid {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #060606;
}
.mail-icon-background {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ffdfe1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
.Email-icon {
  display: flex;
  width: 19px;
}

.visit-us-CIN-wrapper {
  margin-top: 40px;
}
.visit-cin-box {
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff; /* Adjust as needed */
  box-shadow: 0px -2px 0px 0px #0000001a, /* Top shadow */ 0px 4px 4px 0px #00000026; /* Bottom shadow */
  /* position: absolute; */
  /* border-radius: 10px; Optional, for rounded corners */
  height: 105px;
  /* background-image: url('../../assets//images/contactUs-cin-bg-banner-horz.png'); */
  /* width: 963px; */
}
.visit-cin-line {
  display: flex;
  align-items: center;
  /* margin: 30px 0px; */
  height: 75px;
  position: absolute;
}
.visit-cin-line img {
  height: 100%;
}
.visit-cin-tech {
  position: relative;
  float: left;
  right: 30px;
}
.visit-us_title {
  font-size: 20px;
  color: #094e8f;
  font-weight: 600;
}
.visit-us_cin {
  font-size: 17px;
  font-weight: 500;
}
.office-type {
  text-align: center;
  color: #094e8f;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 10px;
}
.office-addr {
  text-align: center;
  color: #000000;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 10px;
}

.verticle-line {
  width: 5px;
}
.corporate {
  width: 321px;
  /* margin-bottom: 40px; */
}
.registered {
  width: 335px;
  /* margin-bottom: 40px; */
}
.state-head {
  width: 380px;
}
.office-section {
  margin-left: -10px;
}
.office-section:nth-child(1) {
  margin-left: 5px;
  /* height: 200px; */
}
.office-section:nth-child(2) {
  margin-left: -10px;
  height: 200px;
}
.office-section:nth-child(3) {
  margin-left: -10px;
  /* height: 200px; */
}

@media (max-width: 1112px) {
  .Contact_us_hori_line {
    width: 720px;
    margin: 10px 0;
  }
  .stateHead_office {
    width: 307px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.01em;
    word-wrap: break-word;
    margin-bottom: 15px;
    color: #000000;
    height: 95px;
  }
  .offices {
    width: 320px;
    margin: 0px;
  }
  .viwe-on-map a {
    text-decoration: none;
    color: #094e8f;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
  }
  .register_office {
    width: 248px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.01em;
    word-wrap: break-word;
    margin-bottom: 15px;
    color: #000000;
    height: 95px;
  }
  .corporate_office {
    width: 285px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.01em;
    word-wrap: break-word;
    margin-bottom: 15px;
    color: #000000;
    height: 95px;
  }
  .office-type {
    text-align: center;
    color: #094e8f;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.01em;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .office-addr {
    text-align: center;
    color: #000000;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

@media (max-width: 991.98px) {
  .visit-us {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: none;
    margin-bottom: 0px;
  }

  .visit-us-header {
    width: 100%;
    padding: 0px 0px;
    writing-mode: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(0deg);
  }
  .visit-us-head {
    display: block;
    text-align: center;
  }

  .office-address-info {
    width: 100%;
    background-image: none;
    padding: 0;
    margin-bottom: 20px;
  }
  .office-address-info:not(:last-child) {
    background-image: none;
    padding-right: 0px;
  }

  .visit-us-wrapper {
    width: 100%;
    padding: 10px;
  }
  .offices {
    display: block;
    /* margin: 10px 0; */
  }

  .contact-medium {
    display: block;
  }
  .number {
    display: flex;
    justify-content: flex-start;
    color: black;
    font-size: 16px;
  }
  .number-one:not(:last-child) {
    border-right: 0.5px solid #000;
    padding-right: 20px;
    padding-left: 7px;
  }
  .contact-medium-wrapper {
    margin-bottom: 50px;
    align-items: flex-start;
  }
  .Office-header {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 15px;
    /* text-align: start; */
    text-align: center;
  }

  .Office-para {
    font-weight: 400;
    font-size: 16px;
    color: black;
    width: 100%;
  }

  .call-center {
    margin-bottom: 18px;
  }

  .Contact-us-line {
    display: none;
  }

  .Contact-us-line-call {
    display: none;
  }
  .Contact_us_hori_line {
    display: none;
  }
  .join-us {
    justify-content: left;
  }
  .stateHead_office {
    width: 307px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.01em;
    word-wrap: break-word;
    margin-bottom: 15px;
    color: #000000;
    height: 95px;
  }
  .offices {
    width: 320px;
    margin: 0px;
  }
  .viwe-on-map a {
    text-decoration: none;
    color: #094e8f;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
  }
  .register_office {
    width: 248px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.01em;
    word-wrap: break-word;
    margin-bottom: 15px;
    color: #000000;
    height: 95px;
  }
  .corporate_office {
    width: 285px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.01em;
    word-wrap: break-word;
    margin-bottom: 15px;
    color: #000000;
    height: 95px;
  }

  .office-type {
    text-align: center;
    color: #094e8f;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.01em;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .office-addr {
    text-align: center;
    color: #000000;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .corporate {
    width: 325px;
    /* margin-bottom: 10px; */
  }
  .registered {
    width: 300px;
    /* margin-bottom: 10px; */
  }
}

@media (max-width: 480px) {
  .visit-us-head {
    font-size: 20px;
    padding: 5px 0;
  }
  .viwe-on-map {
    font-size: 16px;
  }
  .join-us span {
    color: #ed1c24;
    font-size: 18px;
  }
  .emailid span {
    color: #ed1c24;
    font-size: 18px;
  }
  .office-address-info:not(:last-child) {
    /* background-image: none; */
    /* padding-right: 0px; */
    /* margin-right: 0px; */
  }

  .visit-cin-box {
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0px -2px 0px 0px #0000001a, 0px 4px 4px 0px #00000026;
    /* position: absolute; */
    height: auto;
    /* background-image: url(../../assets//images/contactUs-cin-bg-banner-horz.png); */
    /* width: 963px; */
    flex-direction: column;
    gap: 15px;
    padding: 10px 0px;
  }
  .visit-cin-tech {
    position: relative;
    float: left;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .visit-us_title {
    font-size: 14px;
    color: #094e8f;
    font-weight: 600;
  }
  .visit-us_cin {
    font-size: 14px;
    font-weight: 500;
  }
  .visit-cin-line {
    display: flex;
    align-items: center;
    /* margin: 30px 0px; */
    /* height: 75px; */
    width: 290px;
    position: absolute;
  }
  .visit-cin-line img {
    height: unset;
  }
  .stateHead_office {
    width: 307px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.01em;
    word-wrap: break-word;
    margin-bottom: 15px;
    color: #000000;
    height: 95px;
  }
  .offices {
    width: 320px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .viwe-on-map a {
    text-decoration: none;
    color: #094e8f;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
  }
  .register_office {
    width: 315px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.01em;
    word-wrap: break-word;
    margin-bottom: 15px;
    color: #000000;
    height: 95px;
  }
  .corporate_office {
    width: 307px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.01em;
    word-wrap: break-word;
    margin: 0px auto 15px auto;
    color: #000000;
    height: 95px;
  }

  .office-type {
    text-align: center;
    color: #094e8f;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.01em;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .office-addr {
    width: 95%;
    margin: 0px auto;
    text-align: center;
    color: #000000;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .corporate {
    width: 321px;
    margin-bottom: 10px;
  }
  .registered {
    width: 335px;
    margin-bottom: 10px;
  }
  .office-section:nth-child(2) {
    margin-left: -10px;
    height: auto;
  }
}
