.nav-links {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding-right: 10%;
  line-height: 19.2px;
  z-index: 2;
}

.menu-items {
  text-decoration: none;
  color: #094e8f;
  cursor: pointer;
  display: inline-block;
}

.menu-items::after {
  content: '';
  display: block;
  height: 2px;
  background: transparent;
  width: 34%;
  transition: width 0.3s;
  margin-top: 2px;
}

.menu-items:hover::after {
  background: #ed1c24;
  margin: 2px auto 0;
  transition: width 0.3s;
}

.nav-items {
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: 0 0.5rem;
  position: relative;
}

.sub-items {
  position: absolute;
  top: 34px;
  text-decoration: none;
  list-style: none;
  /* min-width: 145px; */
  min-width: 180px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: none;
}

.arrow {
  border: solid #ed1c24;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 0.2rem;
}

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 6px;
  position: relative;
  top: -2px;
}

.nav-items:hover .sub-items {
  display: block;
}

.nav-items:hover .arrow-down {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-left: 6px;
  vertical-align: middle;
}

.sub-items li {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  padding: 7px 3px;
}

.sub-items li:hover {
  background-color: #eab5b5;
}

.mobile-menu {
  display: none;
}
.Hamburger-Footer-Img {
  display: none;
}
@media screen and (max-width: 991.98px) {
  .browser-menu {
    display: none;
  }

  .mobile-menu {
    display: block;
  }

  .arrow {
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    margin-left: 0.4rem;
  }

  .nav-links {
    position: fixed;
    display: block;
    background-color: #094e8f;
    color: #ffffff;
    left: 0;
    top: 48px;
    width: 100%;
    height: 100%;

    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  .menu-items::after {
    display: none;
  }

  .nav-items {
    color: white;
    text-align: left;
    padding: 16px 10px;
    width: 100%;
    transition: all 1s ease;
  }

  .sub-items {
    position: static;
    flex-direction: column;
    background-color: #094e8f;
    box-shadow: none;
    margin-top: 10px;
  }
  .sub-items li {
    padding: 10px 3px;
  }
  .sub-items li:hover {
    /* background-color: #ffffff; */
  }
  .nav-items:hover .sub-items {
    display: flex;
  }
  .hamurger-ft-img-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 99;
    bottom: 90px;
  }
  .hamurger-ft-img-container img {
    width: 80px;
  }
  .Hamburger-Footer-Img {
    display: block;
  }
}
