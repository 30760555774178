.dgv-life-main-container {
  position: relative;
}
.dgv-gradient {
  position: relative;
  width: 100%;
  height: 156px;
  top: 305px;
  gap: 0px;
  opacity: 1px;
  background: linear-gradient(
    360deg,
    #ffffff 0%,
    rgba(236, 246, 255, 0.826055) 31.63%,
    rgba(181, 206, 230, 0.630575) 67.17%,
    #a7bcd0 100%
  );
}
/* .dgv-life-section {
  position: relative;
  top: 0px;
  left: calc((100% - 503px) / 2);
} */
.dgv-life-title {
  background-color: #b5cadd;
  width: 450px;
  height: 61.19px;
  border-radius: 0px 15.89px 0px 15.89px;
  opacity: 1px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  top: 5px;
  left: calc((100% - 450px) / 2);
  position: absolute;
}
.dgv-text-1 {
  width: 150px;
  color: #094e8f;
  font-family: Poppins;
  font-size: 44.84px;
  font-weight: 600;
  line-height: 67.26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.dgv-text-2 {
  width: 100px;
  font-family: Poppins;
  font-size: 44.84px;
  font-weight: 600;
  line-height: 67.26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.dgv-life-image {
  display: flex;
  width: 90%;
  margin: 0px auto;
  position: relative;
  top: -95px;
  align-items: center;
  justify-content: center;
}
.image-box {
  z-index: 1;
}
.dgv-life-image .image-box:nth-child(1) .image {
  width: 210px;
}
.dgv-life-image .image-box:nth-child(2) .image {
  width: 596px;
}
.dgv-life-image .image-box:nth-child(3) .image {
  width: 210px;
}

.watch-more-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.watch-more-title {
  color: #094e8f;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-align: center;
  /* width: 246px; */
  margin: 0px auto 30px;
}
.watch-more-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.component-4X2-section {
  border-radius: 6.76px;
  box-shadow: 2.7px 2.7px 27.04px 0px #00000026;
  border: 1.42px solid #e0e1e3;
}

.component-4X2-wrapper {
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.component-4X2-images:first-child img {
  border-top-left-radius: 4px; /* For the first image */
}

.component-4X2-images:last-child img {
  border-top-right-radius: 4px; /* For the last image */
}
.line {
  width: 90%;
  margin: 10px auto 20px;
  border: 1.42px solid #e0e1e3;
}
.component-4X2-text {
  width: max-content;
  /* width: 744px; */
  font-family: Poppins;
  font-size: 24.02px;
  font-weight: 500;
  line-height: 36.03px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
  margin: 0px auto;
  padding-bottom: 40px;
}

.topFouth-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.two-image-box {
  width: 33%;
}
.third-last-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
}
.fouth-last-section {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
}
.fouth-left-box {
  display: flex;
  width: 70%;
  flex-direction: column;
  gap: 5px;
}
.fouth-right-box {
  width: 50%;
}
.two-component-wrapper {
  display: flex;
}
.dgv-life-section .section .react-responsive-3d-carousel {
  height: 330px !important;
}

@media (max-width: 480px) {
  .dgv-life-title {
    width: 154px;
    top: 5px;
    left: calc((100% - 154px) / 2);
    height: 40px;
    gap: 5px;
  }
  .dgv-gradient {
    background: linear-gradient(
      360deg,
      #ffffff 0%,
      rgba(236, 246, 255, 0.826055) 31.63%,
      rgba(181, 206, 230, 0.630575) 67.17%,
      #a7bcd0 100%
    );
    width: 327px;
    height: 85px;
    top: 115px;
    gap: 0px;
    margin: 0px auto;
  }
  .watch-more-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .dgv-text-1 {
    width: 50px;
    color: #094e8f;
    font-family: Poppins;
    font-size: 13.84px;
    font-weight: 600;
    line-height: 67.26px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .dgv-text-2 {
    width: 25px;
    font-family: Poppins;
    font-size: 13.84px;
    font-weight: 600;
    line-height: 67.26px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
  }
  .dgv-life-image {
    display: flex;
    width: 325px;
    margin: 0px auto;
    position: relative;
    top: -40px;
    margin: 0px auto;
  }

  .dgv-life-image .image-box:nth-child(1) .image {
    width: 66px;
  }
  .dgv-life-image .image-box:nth-child(2) .image {
    width: 195px;
  }
  .dgv-life-image .image-box:nth-child(3) .image {
    width: 66px;
  }

  .watch-more-title {
    font-family: Poppins;
    font-size: 12.08px;
    font-weight: 600;
    line-height: 18.11px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-align: center;
    /* width: 75px; */
    margin: 0px auto 20px;
  }
  .line {
    width: 90%;
    margin: 5px auto 5px;
    border: 1.42px solid #e0e1e3;
  }
  .two-component-wrapper {
    display: flex;
    margin: 10px auto;
    gap: 5px;
  }
  /* .component-4X2-text {
    width: 222px;
    font-family: Poppins;
    font-size: 8px;
    font-weight: 600;
    line-height: 12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 9px;
  }
  .component-4X2-images {
    width: 100%;
  } */

  .mob-4x2-section {
    width: 50%;
  }
  .two-block-container {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin: 10px auto;
  }
  .full-width {
    width: 100%;
  }
  .dgv-life-section .section {
    height: 190px;
  }
  .dgv-life-section .section .react-responsive-3d-carousel__item.fixed-height > * {
    /* height: 100%; */
    object-fit: cover;
  }
}
