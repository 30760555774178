.partner-component-main-component {
  margin-top: 40px;
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  gap: 15px;
  margin-bottom: 40px;
}
.partner-components-left {
  width: 440px;
}
.partner-components-right {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (max-width: 480px) {
  .partner-component-main-component {
    margin-top: 20px;
    display: flex;
    /* align-items: center; */
    /* justify-content: space-between; */
    gap: 15px;
    margin-bottom: 40px;
  }
  .reusable-partners-component-mob {
    display: flex;
    justify-content: center;
    width: 315px;
    /* width: 95%; */
  }

  .mob-info-section {
    height: 107px;
  }
  .small-img {
    width: 335px;
    height: 186px;
    margin: 0px auto;
  }
  .mob-pertner-title {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 600;
    line-height: 12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #094e8f;
    width: 166px;
  }
  .mob-pertner-watch-now-btn {
    margin: 10px 0px 10px;
  }
  .mob-pertner-disc {
    color: #094e8f;
    font-family: Poppins;
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 182px;
  }

  .mob-info-box {
    height: 97px;
    display: flex;
    background: #fff;
    align-self: end;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 15px;
    padding: 8px;
  }
  .partner-mob-wrappwer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
