/* .info-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #094e8f;
  border-radius: 100px 0px 0px 100px;
  padding: 0.5rem;
  width: 400px;
  color: #ffffff;
} */

/* changes by monali */
.info-wrapper {
  display: flex;

  align-items: center;

  justify-content: center;

  background-color: #094e8f;

  border-radius: 100px 0px 0px 100px;

  padding: 0.5rem;

  width: 430px;

  color: #ffffff;

  padding-right: 20%;

  margin-bottom: 11px;
  height: 40px;
}
.contact-img {
  width: 20px;
  padding-top: 5px;
}
.phone {
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: 2px solid #ffffff;
}

.phone a {
  color: #ffffff;
  text-decoration: none;
}
.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 5rem;
}
