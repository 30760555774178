.OfferingsCircles-wrapper {
  display: flex;
  width: 120px;
  height: 120px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* width: calc((100% - 240px) / 7);
  height: 124.23px; */
  margin-right: 40px;
  margin-bottom: 40px;
}
.OfferingsCircles-wrapper:nth-child(7n) {
  margin-right: 0;
}
.offeringCircles-logo {
  width: 32px;
  height: 32px;
}
.offeringCircles-heading {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #555657;
  text-align: center;
}

@media (max-width: 1368px) {
  .OfferingsCircles-wrapper {
    width: 110px;
    height: 110px;
  }
}
@media (max-width: 1112px) {
  .OfferingsCircles-wrapper {
    width: calc((100% - 60px) / 3);
    height: 181.33px;
    margin-right: 30px;
  }
  .OfferingsCircles-wrapper:nth-child(3n) {
    margin-right: 0px;
  }
  /* .OfferingsCircles-wrapper:nth-child(4n) {
    margin-right: 0px;
  } */
  .OfferingsCircles-wrapper:nth-child(7n) {
    margin-right: 30px;
  }
}

@media (max-width: 480px) {
  .OfferingsCircles-wrapper {
    width: 85px;
    height: 85px;
  }

  .offeringCircles-heading {
    font-size: 10px;
  }
}
