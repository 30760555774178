.react-responsive-3d-carousel__item {
  box-shadow: none !important;
}
.margin-b-carousel {
  margin-bottom: 1.2rem;
}

.image-title {
  text-align: center;
  line-height: 1.4rem;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  font-weight: 600;
  color: #094e8f;
}
.read-more-btn {
  width: 164px;
  height: 40px;
  border-radius: 29.06px;
  border: 2px solid #ed1c24;
  text-align: center;
  color: #ed1c24;
  /* font-family: Montserrat; */
  padding-top: 9px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background-color: #fff;
}
.read-more-btn a {
  text-decoration: none;
  color: #ed1c24;
}
.read-more-btn:hover {
  text-decoration: none;
}
@media (max-width: 480px) {
  .section {
    height: 230px;
  }
  .image-title {
    font-size: 0.6rem;
  }
  .read-more-btn {
    width: 61.05px;
    height: 15.26px;
    top: 165px;
    left: 150px;
    gap: 0px;
    border-radius: 10px;

    /* font-family: Montserrat; */
    font-size: 5.21px;
    font-weight: 600;
    line-height: 7.82px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding-top: 2px;
    text-decoration: none;
  }

  .read-more-btn:hover {
    text-decoration: none;
  }
}

@media (max-width: 480px) {
  .image-title {
    text-align: center;
    line-height: 1rem;
    /* margin-top: 0.6rem;
    margin-bottom: 0.6rem; */
    margin-top: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #094e8f;
  }
}
