img {
  width: 100%;
}
._popup_component {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 5;
}
