.disclaimer-text {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: justify;
  padding: 10px;
}
.red-disclaimer-text {
  color: #ed1c24;
  font-weight: 600;
}
.blue-disclaimer-text {
  color: #094e8f;
}
.disclaimer-read-more-btn,
.read-less-btn {
  color: #007bff;
  background: none;
  border: none;
  cursor: pointer;
  /* font-size: 14px; */
  margin-left: 5px;
  font-family: Poppins, sans-serif;
  font-size: 20px;
}

.disclaimer-read-more-btn:hover,
.read-less-btn:hover {
  text-decoration: underline;
}

@media screen and (max-width: 992px) {
  .disclaimer-text {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    text-align: justify;
    padding: 5px;
  }
}

@media (max-width: 480px) {
  .disclaimer-read-more-btn,
  .read-less-btn {
    color: #007bff;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;
    font-family: Poppins, sans-serif;
    /* font-size: 20px; */
  }
}
