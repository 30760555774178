.carrers-first-apply-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 1rem 0;
  margin: 50px 30px;
  background: rgba(255, 196, 68, 0.05);
  /* opacity: 0.05; */
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
.careers-firstApply-title {
  font-family: 'Poppins', sans-serif;
  font-style: Medium;
  font-size: 30px;
  line-height: 45px;
  font-weight: 500;
}
.careers-firstApply-Secondtitle {
  font-family: 'Poppins', sans-serif;
  font-style: Medium;
  font-size: 20px;
  line-height: 45px;
  font-weight: 400;
}
.apply-carres-btn {
  margin-top: 25px;
}

.careers-join-us {
  font-weight: 600;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
}
.careers-join-us a {
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
  color: black;
}

@media screen and (max-width: 992px) {
  .carrers-first-apply-container {
    margin: 50px 0 40px;
    text-align: center;
  }
  .careers-firstApply-title {
    font-family: 'Poppins', sans-serif;
    font-style: Medium;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  .careers-firstApply-Secondtitle {
    font-family: 'Poppins', sans-serif;
    font-style: Medium;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    padding: 8px;
  }
  .apply-carres-btn {
    margin-top: 25px;
  }
  .careers-join-us {
    display: none;
  }
}
