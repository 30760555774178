.disclaimer {
  margin-top: 20px;
  width: 100%;
  padding: 0 8%;
}
.disclaimer-head {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  size: 20px;
  color: #ed1c24;
}
.disclaimer-image {
  width: 600px;
  margin-left: 30px;
}

.disclaimer-image img {
  content: url('../../assets/images/disclaimer.png');
}

@media (max-width: 680px) {
  .disclaimer-image img {
    content: url('../../assets/images/mobile_disclaimer.png');
  }
  .disclaimer-image {
    width: 300px;
  }
  .disclaimer {
    margin-top: 5px;
  }
}
@media (max-width: 520px) {
  .disclaimer-image img {
    content: url('../../assets/images/mobile_disclaimer.png');
  }
  .disclaimer-image {
    width: 300px;
  }
  .disclaimer {
    margin-top: 5px;
  }
}
