.contactUs-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.contactUs-main {
  margin-top: 20px;
}
.Drop-a-message {
  width: 55%;
}

@media (max-width: 1112px) {
  .contactUs-wrapper {
    display: block;
  }

  .Drop-a-message {
    width: 100%;
  }
}
