.footer {
  display: flex;
  width: 100%;
  background: #ed1c24;
  -webkit-clip-path: polygon(0 25%, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 25%, 100% 0, 100% 100%, 0% 100%);
  color: #ffffff;
  /* margin-top: 45px; */
}
.footer-link {
  text-decoration: none;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}
.footer-link-our-network {
  text-decoration: none;
  color: #ffffff;
}

.footer-wrap {
  display: flex;
  flex-direction: column;
  background-color: #094e8f;
  width: 100%;
  -webkit-clip-path: polygon(0 25%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0 15%, 100% 0%, 100% 100%, 0% 100%);
  position: relative;
  padding: 8% 8% 2%;
  margin-top: 5%;
}

.footer-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.footer-col-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32%;
}

.footer-col .bold {
  font-weight: 700;
  font-size: 18px;
}

.dgvIcon {
  margin-bottom: 19px;
  width: 177px;
}

.footer-subtitles {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  text-align: center;
}

.footer-col-logo .socialMedia {
  float: left;
  margin: 35px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list {
  width: 100%;
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
}

.footer-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 0.5px solid #ffffff;
  width: calc(100% / 4);
  position: relative;
}
.footer-col:first-child {
  border-left: 0;
}
.footer-col-wrapper {
  display: flex;
  /* width: 75%; */
  width: 100%;
  padding-left: 3%;
}

.footer-col::after {
  content: '';
  background-image: url(../../assets/images/Diamond.png);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: -11px;
  top: calc((100% - 35px) / 2);

  width: 20px;
  height: 20px;
}

.footer-col:first-child::after {
  display: none;
}

.footer-col li {
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #ffffff;
}

.copyright {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #094e8f;
  margin-top: 45px;
}
.PCI {
  width: 30%;
}
.social-icon {
  float: left;
  width: 35px;
}
.footer-head {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  font-family: 'lato', sans-serif;
  margin-bottom: 20px;
  padding: 0 20px;
}

.allRights {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
}
.allRights:first-child {
  border-right: 1px solid #fff;
  padding-right: 8px;
  margin-right: 8px;
}

.footer-col a {
  text-decoration: none;
}

@media (max-width: 1112px) {
  .dgvIcon {
    width: 162px;
  }
  .footer {
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  }

  .footer-wrap {
    clip-path: polygon(0 7%, 100% 0%, 100% 100%, 0% 100%);
    padding: 16% 2% 2%;
  }

  .footer-container {
    flex-direction: column;
    margin-top: 20px;
  }

  .footer-col-logo {
    width: 100%;
  }
  .footer-col-wrapper {
    width: 100%;
    padding-left: 0;
    margin-top: 30px;
  }
  .footer-col-logo .socialMedia {
    /* justify-content: flex-start; */
    justify-content: center;
    align-items: center;
    margin-bottom: 36px;
  }
  .footer-link-our-network {
    text-decoration: none;
    color: #ffffff;
    display: contents;
  }
  .PCI {
    width: 20%;
  }
}
@media (max-width: 480px) {
  .footer {
    clip-path: polygon(0 3%, 100% 0, 100% 100%, 0% 100%);
  }
  .footer-wrap {
    float: left;
    width: 100%;
    clip-path: polygon(0 2%, 100% 0%, 100% 100%, 0% 100%);
    padding: 16px 5% 2%;
  }
  .footer-container {
    display: block;
    align-self: flex-start;
    /* margin-top: 100px; */
    margin-top: 45px;
  }

  .footer-col-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 40px;
  }

  .footer-subtitles {
    margin-bottom: 17px;
    display: flex;
    text-align: left;
  }

  .footer-col-wrapper {
    display: block;
    padding: 0;
  }

  .footer-col {
    border-left: 1px solid #ffffff;
    width: 100%;
    margin-bottom: 50px;
    align-self: flex-start;
  }

  .footer-col::after {
    content: '';
    background-image: url(../../assets/images/Diamond.png);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 20px;
    height: 20px;
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding-left: 17px;
  }

  .footer-head {
    padding: 0 20px;
    text-align: left;
  }

  .footer .footer-wrap .container .footer-col .list li {
    margin-bottom: 10px;
  }

  .copyright {
    display: flow-root;
    width: 100%;

    margin-top: 0;
  }

  .allRights {
    text-align: start;
  }
  .footer-col:first-child {
    border-left: 0.5px solid #fff;
  }
  .footer-col:first-child::after {
    display: block;
  }

  .allRights:first-child {
    border-right: 0px solid #fff;
    padding-right: 0px;
    margin-right: 0px;
    margin-bottom: 17px;
  }
  .footer-link-our-network {
    text-decoration: none;
    color: #ffffff;
    display: contents;
  }
  .footer-col-logo .socialMedia {
    justify-content: flex-start;
    margin-bottom: 24px;
  }
  .PCI {
    width: 50%;
  }
}
