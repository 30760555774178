/* General Styling */
.c-3x2-main-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  /* margin: 20px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  box-shadow: 2.19px 2.19px 21.91px 0px #00000026;
}

.c-3x2-image-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* padding: 10px; */
  justify-content: center;
}

.c-3x2-single {
  width: 100%;
  display: flex;
  justify-content: center;
}

.c-3x2-single img {
  width: 100%;
  height: auto;
  border-radius: 5px 5px 0px 0px;
}

.c-3x2-multiple {
  flex: 1 1 calc(50% - 10px);
  position: relative;
}

.image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0px 0px;
}

.c-3x2-info-section {
  padding: 20px;
  text-align: center;
}

.c-3x2-disc {
  color: #094e8f;
  font-family: Poppins;
  font-size: 16.22px;
  font-weight: 500;
  line-height: 24.33px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.c-3x2-title {
  text-shadow: 0px 2.64px 2.64px #00000040;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
}

.c-3x2-expore-btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border-radius: 25px;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  transition: background-color 0.3s ease;
  box-shadow: 0px 1.91px 9.55px 0px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(237, 28, 36, 1);
  color: #ed1c24;
}
.c-3x2-four-image-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 10px;
  /* margin: 0px auto; */
  width: 100%;
  /* gap: 7px; */
  justify-content: center;
}
/* .c-3x2-four-multiple {
  width: 240px;
} */
.c-3x2-four-multiple {
  width: calc((100% - 7px) / 2);
  margin-right: 7px;
}
.c-3x2-four-multiple:nth-child(2n) {
  margin-right: 0px;
}
.c-3x2-four-title {
  font-size: 40px;
  color: #094e8f;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-bottom: 15px;
}

/* Desktop View */
@media screen and (min-width: 1112px) {
  .c-3x2-main-container {
    /* flex-direction: row;
    align-items: center; */
    /* padding: 20px; */
  }

  .c-3x2-image-section {
    /* width: 60%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 7px;
  }

  .c-3x2-single {
    width: 100%;
  }

  .c-3x2-multiple {
    flex: 1 1 calc(30% - 10px);
    /* max-width: 30%; */
  }

  .c-3x2-info-section {
    width: 100%;
    padding-left: 20px;
    text-align: left;
  }

  .c-3x2-title {
    font-size: 24px;
  }
}

/* Responsive View */
@media screen and (max-width: 768px) {
  .c-3x2-main-container {
    padding-bottom: 10px;
    display: inline-block;
    width: 50%;
  }

  .c-3x2-single img,
  .image-box img {
    border-radius: 5px 5px 0px 0px;
    aspect-ratio: 3/2;
    /* object-fit: contain; */
    /* mix-blend-mode: color-burn; */
  }

  .c-3x2-title {
    font-size: 18px;
  }

  .c-3x2-disc {
    font-size: 8px;
    line-height: 12px;
  }

  .c-3x2-expore-btn {
    font-size: 5px;
    padding: 3px 6px;
  }
  .c-3x2-info-section {
    padding: 4px;
    text-align: start;
  }

  .c-3x2-image-section {
    width: 100%;
    display: inline-block;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 7px;
  }

  .c-3x2-image-section .slick-slide {
    width: 156px;
    height: 150px;
  }
}
@media (max-width: 480px) {
  .full-width {
    width: 100%;
    text-align: center;
  }
}
