/* StateMap.css */
.state-geography {
  cursor: pointer;
  transition: transform 0.3s ease, fill 0.3s ease;
  z-index: 1;
}
.pulse-circle {
  r: 5px;
  fill: #fff;
  stroke: #fff;
  stroke-width: 2px;
}
.map-state-text {
  fill: #fff;
  /* fill: black; */
  font-family: Lato;
  font-weight: 300;
}
.custom-map-container-ui {
  position: relative;
}

.floating-map-content {
  position: absolute;
  bottom: 4rem;
  right: 3rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.floating-map-content-element {
  display: flex;
  align-items: center;
  gap: 8px;
}
.floating-map-content-element > span:first-child {
  width: 0.5rem;
  height: 0.5rem;
}
.floating-map-content-element > span {
  font-size: 0.5rem;
  font-weight: 500;
}
@media (max-width: 480px) {
  .pulse-circle {
    r: 5px;
    fill: #fff;
    stroke: #ff0000;
    stroke-width: 1px;
    animation: pulse-svg 2s infinite;
  }
  .floating-map-content-element > span:first-child {
    width: 0.8rem;
    height: 0.8rem;
  }
  .floating-map-content-element > span {
    font-size: 1.2rem;
    font-weight: 500;
  }
  .floating-map-content {
    position: absolute;
    bottom: 4rem;
    right: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  .floating-map-content {
    position: absolute;
    bottom: 2rem;
    right: 3rem;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .floating-map-content-element {
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .floating-map-content-element > span:first-child {
    width: 0.3rem;
    height: 0.3rem;
  }
  .floating-map-content-element > span {
    font-size: 0.3rem;
    font-weight: 500;
  }

  .network-map-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  /* .map-box {
    width: 310px;
    margin: 0px auto;
  } */
  .map-box {
    width: 235px;
    /* width: 330px; */
    margin: 0px auto;
  }
  .country-map-box {
    /* width: 235px; */
    width: 440px;
    margin: 0px auto;
  }
}
.rsm-geographies {
  width: 800px; /* Set your desired width */
  height: 500px; /* Set your desired height */
}

@keyframes pulse-svg {
  0% {
    r: 5px;
  }
  50% {
    r: 2px;
    opacity: 0.6;
  }
  100% {
    r: 5px;
  }
}
/* .state-geography:hover {
   transform: scale(1.1); 
  z-index: 10; 
} */
