.testimonial-container {
  /* padding-right: 3rem; */
  margin-top: 5rem;
  padding: 0 1.5rem;
}

.testimonial-bg {
  min-height: 317px;
  background-color: #d0e3f5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-employee-img {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 145px;
}
/* 
.avatar-img {
 
  height: 146px;
} */

.testimonial-employee-info-container {
  padding: 1rem;
  margin-top: 3rem;
}

.name-heading {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
  padding: 0.2rem;
  text-align: center;
}

.post-heading {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #e30513;
  text-align: center;
  padding: 0.2rem;
}

.experience-description {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding: 0.2rem;
}
.testimonial-slider-dots .slick-dots {
  bottom: -28px;
}

@media screen and (max-width: 992px) {
  .testimonial-container {
    margin-top: 0;
    padding: 0;
  }
  .testimonial-bg {
    min-height: 200px;
    background-color: #d0e3f5;
    margin-top: 2.5rem;
  }

  .avatar-img {
    width: 80px;
    height: 80px;
  }

  .testimonial-employee-info-container {
    padding: 0.5rem;
    margin-top: 2rem;
  }

  .name-heading {
    font-weight: 500;
    font-size: 11.3446px;
    line-height: 14px;
    padding: 0.2rem;
  }

  .post-heading {
    font-weight: 400;
    font-size: 10.2101px;
    line-height: 15px;
  }

  .experience-description {
    font-weight: 400;
    font-size: 9.07568px;
    line-height: 14px;
  }
}
