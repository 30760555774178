.product-info-box {
  display: flex;
  padding: 17px 0;
}
.main-container {
  overflow-x: hidden;
}
/* .m-top1 {
  margin-top: 5rem;
} */

.benefit-Container {
  /* width: 100%; */
  display: flex;
  justify-content: space-around;
}
.connect-title-text {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #060606;
  text-align: center;
  margin-bottom: 30px;
}

.animate__animated {
  -webkit-animation-duration: 3s !important;
  animation-duration: 3s !important;
}

.benefit-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.benefit-image {
  height: 495px;
  width: 445px;
}

.Product-footer {
  position: relative;
  bottom: 0;
}

.image-container {
  width: 100%;
  margin: 60px 0;
}

.money-image-web {
  display: block;
}

.money-image-mobile {
  display: none;
}
/* common css for ipad and mobile */

.connect-image {
  width: 967px;
  /* height: 520.34px; */
  top: 625.38px;
  left: 237px;
  padding: 55px;
  gap: 0px;
  opacity: 1px;
  margin: 50px auto;
}
.connect-image img {
  width: 100%;
}
.scanner-container {
  background: #e6f2fd;
  box-shadow: 0px 2px 4px 0px #00000040;
  box-shadow: -2px 2px 4px 0px #00000040;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  opacity: 1px;
  padding: 10px 0px 10px 0px;
}
.scanner-text {
  font-family: Poppins;
  font-size: 51px;
  font-weight: 300;
  line-height: 68.85px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 797px;
}

.scanner-image {
  width: 214px;
  height: 200px;
}
.scanner-image img {
  width: 100%;
}

.connect-feature-container {
  background-image: url('../../../assets/images/connect-feature-bg.png');
  background-size: 75%;
  background-repeat: no-repeat;
  height: 398px;
  background-position: center;
  position: relative;
}
.feature-text {
  position: absolute;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  /* line-height: 60px;
  text-align: left; */
  /* text-underline-position: from-font;
  text-decoration-skip-ink: none; */
  width: 175px;
  /* height: 60px; */
  /* top: 75px; */
  top: 14%;
  left: calc((100% - 175px) / 2);
  gap: 0px;
  opacity: 1px;
  color: #094e8f;
}
.feature-list-box {
  float: left;
  /* background: green; */
  /* width: 220px; */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -3px;
}
/* .feature-list-box:nth-child(4) {
  position: absolute;
  right: -260px;
  top: -7px;
} */
.feature-logo-info {
  width: 72%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 80px;
  position: absolute;
  bottom: 64px;
  left: calc((100% - 72%) / 2);
}

@media (min-width: 1395px) {
  .feature-logo-info {
    width: 70%;
    left: calc((100% - 70%) / 2);
  }
}

.feature-icon:nth-child(1) {
  height: 58px;
  width: 58px;
}
.feature-icon:nth-child(2) {
  height: 63px;
  width: 63px;
}
.feature-icon:nth-child(3) {
  height: 63px;
  width: 63px;
}
.feature-icon:nth-child(4) {
  height: 101px;
  width: 101px;
}
.feature-text-disc {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
  line-height: 25.7px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.feature-list-box:nth-child(1) .feature-text-disc {
  width: 183px;
  color: #616c6a;
}
.feature-list-box:nth-child(2) .feature-text-disc {
  width: 183px;
  color: #00bdc1;
}
.feature-list-box:nth-child(3) .feature-text-disc {
  width: 190px;
  color: #ffa800;
}
.feature-list-box:nth-child(4) .feature-text-disc {
  width: 183px;
  color: #424242;
}

@media (max-width: 991.98px) {
  .product-info-box {
    flex-direction: column;
  }

  .slider {
    width: 100%;
  }

  .money-image-web {
    display: none;
  }

  .money-image-mobile {
    display: block;
  }

  .benefit-Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    flex-wrap: wrap;
  }
  .connect-title-text {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 30px;
    text-align: center;
  }
  .benefit-image {
    height: 325px;
    order: 1;
    width: 307px;
  }

  .benefit-title {
    order: 2;
    margin-top: 1.8rem;
  }

  .m-top2 {
    margin-top: 2.8rem;
  }

  /* Adding patch to fix horizontal scrollbar issue, because of aos animation library*/
  .main-container {
    overflow-x: hidden;
  }

  .connect-image {
    width: 322px;
    /* height: 520.34px; */
    top: 625.38px;
    left: 237px;
    padding: 0px;
    gap: 0px;
    opacity: 1px;
    margin: 0px auto;
  }
  .connect-image img {
    width: 100%;
  }

  .scanner-text {
    /* font-family: Poppins;
    font-size: 15px;
    font-weight: 300;
    line-height: 68.85px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 315px; */
    font-family: Poppins;
    font-size: 15px;
    font-weight: 300;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 215px;

    opacity: 1px;
  }
  .scanner-image {
    width: 70px;
    height: 65px;
  }
  .scanner-image img {
    width: 100%;
  }

  .feature-mob-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .feature-mob-title {
    width: 308px;
    height: 58px;
    background-color: white;
    border-radius: 15px;
    text-align: center;
    /* display: flex; */
    align-items: center;
    align-content: center;
    box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 22.6px;
    font-weight: 500;
    line-height: 33.9px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #094e8f;
  }
  .feature-dots-box {
    width: 76px;
    margin-top: 10px;
  }
  .feature-mob-info-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
  }
  .feature-mob-info-box {
    width: 146px;
    height: 154px;
    display: flex;
    /* justify-self: center; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 22.03px;
    padding: 5px;
  }
  .feature-mob-info-box:nth-child(1) {
    border: 3.39px solid #a2b1ae;
  }
  .feature-mob-info-box:nth-child(2) {
    border: 3.39px solid #00bdc1;
  }
  .feature-mob-info-box:nth-child(3) {
    border: 3.39px solid #ffa800;
  }
  .feature-mob-info-box:nth-child(4) {
    border: 3.39px solid #4c4949;
  }

  .feature-mob-dics {
    text-align: center;
    font-size: 11px;
  }
  .feature-mob-logo img {
    width: 100%;
    height: auto;
  }
  .feature-mob-info-box:nth-child(1) .feature-mob-logo {
    width: 32px;
    height: unset;
  }
  .feature-mob-info-box:nth-child(2) .feature-mob-logo {
    width: 35px;
    height: unset;
  }
  .feature-mob-info-box:nth-child(3) .feature-mob-logo {
    width: 35px;
    height: unset;
  }
  .feature-mob-info-box:nth-child(4) .feature-mob-logo {
    width: 57px;
    height: unset;
  }
  .feature-mob-info-box:nth-child(1) .feature-mob-dics {
    color: #a2b1ae;
    width: 103px;
  }
  .feature-mob-info-box:nth-child(2) .feature-mob-dics {
    color: #00bdc1;
    width: 103px;
  }
  .feature-mob-info-box:nth-child(3) .feature-mob-dics {
    color: #ffa800;
    width: 123px;
  }
  .feature-mob-info-box:nth-child(4) .feature-mob-dics {
    color: #424242;
    width: 103px;
  }
  .scanner-container {
    background: #e6f2fd;
    box-shadow: 0px 2px 4px 0px #00000040;
    box-shadow: -2px 2px 4px 0px #00000040;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    opacity: 1px;
    padding: 10px 0px 10px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .benefit-Container {
    align-items: center;
  }
  .connect-image {
    width: 322px;
    /* height: 520.34px; */
    top: 625.38px;
    left: 237px;
    /* padding: 55px; */
    gap: 0px;
    opacity: 1px;
    margin: 0px auto;
  }
  .connect-image img {
    width: 100%;
  }

  .feature-mob-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .feature-mob-title {
    width: 308px;
    height: 58px;
    background-color: white;
    border-radius: 15px;
    text-align: center;
    /* display: flex; */
    align-items: center;
    align-content: center;
    box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 22.6px;
    font-weight: 500;
    line-height: 33.9px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #094e8f;
  }
  .feature-dots-box {
    width: 76px;
    margin-top: 10px;
  }
  .feature-mob-info-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .feature-mob-info-box {
    width: 146px;
    display: flex;
    /* justify-self: center; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .feature-mob-dics {
    text-align: center;
  }
  .feature-mob-info-box:nth-child(1) .feature-mob-dics {
    color: #424242;
  }
  .feature-mob-info-box:nth-child(2) .feature-mob-dics {
    color: #424242;
  }
  .feature-mob-info-box:nth-child(3) .feature-mob-dics {
    color: #ffa800;
  }
  .feature-mob-info-box:nth-child(4) .feature-mob-dics {
    color: #424242;
  }
  .scanner-container {
    background: #e6f2fd;
    box-shadow: 0px 2px 4px 0px #00000040;
    box-shadow: -2px 2px 4px 0px #00000040;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    opacity: 1px;
    padding: 10px 0px 10px 0px;
  }
}
