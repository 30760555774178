.dailog-main-container {
  background-color: #094e8f;
}
.video-container {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.close-icon {
  position: absolute;
  right: 24px;
  top: 6px;
  width: 24px;
  height: 24px;
}
