.button-container {
  display: flex;
  justify-content: end;
  align-items: center;
  /* background: linear-gradient(to right, #80000000, #ff000080); */
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 0px 25px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgb(0 0 0 / 0%);
}
.button-text {
  /* margin-right: 25px; */
  /* font-family: Teko; */
  font-size: 25.6px;
  font-weight: 700;
  line-height: 22.62px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background: linear-gradient(to right, #80000000, #ff000080);
  padding: 10px 24px;
}

.check-icon {
  width: 60px;
  font-size: 20px;
  /* color: #00ff00; */
  position: absolute;
  right: -16px;
  top: -11px;
}
