.career-content {
  overflow-x: hidden;
}
.carrer-info-plot {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.heading-text {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #094e8f;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 30px;
  position: relative;
}
.openposition-heading {
  font-family: 'Poppins', sans-serif;
  font-style: 'Medium';
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  color: #094e8f;
}
.spinner-style {
  width: 100px;
  height: 80px;
}
@media screen and (max-width: 991.98px) {
  .carrer-info-plot {
    flex-direction: column;
    margin-top: 0;
  }

  .heading-text {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 18px 0;
    color: #094e8f;
  }
  .openposition-heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
