.play-icon img {
  width: 100%;
  padding: 10px;
}

button {
  border: 0;
  cursor: pointer;
}
._video_button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 164px;
  border: 2px solid #ed1c24;
  filter: drop-shadow(0px 1.26364px 1.26364px rgba(0, 0, 0, 0.25));
  border-radius: 29.0636px;
  background-color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 600;
  /* line-height: 38px; */
  color: #ed1c24;
  height: 41px;
}

.play-icon {
  width: 40px;
  height: 39px;
  background-color: #ed1c24;
  border-radius: 55%;
  margin-right: 15px;
  margin-left: -1px;
}

@media (max-width: 1112px) {
  /* ._video_button {
    margin-bottom: 40px;
  } */
  ._video_button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 120px;
    border: 2px solid #ed1c24;
    filter: drop-shadow(0px, 0.93px rgba(0, 0, 0, 0.25));
    border-radius: 29.0636px;
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 600;
    /* line-height: 38px; */
    color: #ed1c24;
    height: 30px;
  }
  .play-icon {
    width: 28px;
    height: 28px;
    background-color: #ed1c24;
    border-radius: 21px;
    margin-right: 7px;
    margin-left: -1px;
  }
  .watch-txt {
    font-size: 12px;
  }
}

._video_button:hover {
  transform: scale(1.1) !important;
  transition: 0.6s !important;
  -webkit-transition: 0.6s !important;
}

@media (max-width: 480px) {
  ._video_button-small {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 60px;
    height: 15px;
    border: 2px solid #ed1c24;
    filter: drop-shadow(0px, 0.93px rgba(0, 0, 0, 0.25));
    border-radius: 29.0636px;
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #ed1c24;
    height: 14px;
  }
  .play-icon-small {
    width: 10px;
    height: 10px;
    background-color: #ed1c24;
    border-radius: 7px;
    margin-right: 7px;
    margin-left: -1px;
  }
  .watch-txt-small {
    font-size: 5px;
  }
  .play-icon-small img {
    width: 100%;
    padding: 1px 2px 5px 2px;
  }
}
