.job-title-accordion {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: '20px';
  line-height: '30px';
  color: '#000000';
}
.job-accordian-btn-center {
  display: flex;
  justify-content: center;
  margin: 1.5rem;
  align-items: center;
}
.job-details {
  padding: 10px 20px;
}
.job-details .bold-job-text {
  font-family: 'poppins', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding: 0;
}
.font-wt-500 {
  font-family: 'poppins', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0;
}
.job-details div {
  font-family: 'poppins', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding: 1rem 0;
}
.job-details p {
  font-family: 'poppins', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

@media screen and (max-width: 992px) {
  .job-title-accordion {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: '16px';
    line-height: '24px';
    color: '#000000';
  }

  .job-details {
    padding: 10px;
  }
  .job-details div {
    font-family: 'poppins', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    padding: 0.5rem 0;
  }
  .job-details p {
    font-family: 'poppins', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
}
