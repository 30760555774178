.slider {
  width: 49%;
}
.br-radius {
  border-radius: 10px;
}
.carl-img {
  border-radius: 10px;
}
.slick-dots li button:before {
  color: #d6e9fb;
  opacity: 1.25;
  font-size: 11px;
}

.slick-dots li.slick-active button:before {
  color: #094e8f;
  opacity: 1.75;
  font-size: 14px;
}

.slick-dots li {
  margin: 0 4px;
}

.slick-dots {
  bottom: -20px;
}

@media (max-width: 1112px) {
  .slider {
    order: 1;
    width: 100%;
  }
  .br-radius {
    border-radius: 5px;
  }
  .carl-img {
    border-radius: 5px;
  }

  .slick-dots li button:before {
    color: #d6e9fb;
    opacity: 1.25;
    font-size: 10px;
  }

  .slick-dots li.slick-active button:before {
    color: #094e8f;
    opacity: 1.75;
    font-size: 12px;
  }
  .slick-dots {
    bottom: -25px;
  }
}

@media (max-width: 480px) {
  .slider {
    width: 100%;
  }
}
