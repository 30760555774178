img {
  width: 100%;
}
.partner-company-logo {
  width: 118px;
  margin: 5px auto;
}
.partner-words {
  text-align: center;
  margin-bottom: 40px;
  padding: 0 60px;
  font-family: 'Poppins', sans-serif;
  font-style: SemiBold;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}
.video-content-partnerInfo {
  width: 30%;
  height: 250px;
  margin: 40px auto;
}
.video-partner-tab {
  width: 100%;
  height: 100%;
}
.partners-company-container .slick-dots {
  bottom: 6px !important;
}
.fedralBank-img {
  width: 136px;
  height: 44px;
  margin: 35px auto;
}

@media (max-width: 991.98px) {
  .partner-company-logo {
    width: 114px;
  }
  .partner-words {
    padding: 0 25px;
  }
  .video-content-partnerInfo {
    height: 201px;
    padding: 0 10px;
    width: 100%;
  }
}
