.career-intro-section {
  width: 49%;
}
.Life-dgv-link {
  color: #ed1c24;
}
.career-intro-heading {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #094e8f;
  padding: 0.8rem 0;
}

.career-intro-sec-heading1 {
  padding: 0.8rem 0;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #060606;
}

@media screen and (max-width: 992px) {
  .career-intro-heading {
    font-size: 25px;
    line-height: 38px;
    padding: 0.6rem 0;
  }
  .career-intro-sec-heading1 {
    padding: 0.6rem 0;
    font-size: 14px;
    line-height: 21px;
  }
  .career-intro-section {
    order: 2;
    width: 100%;
    padding-right: 0;
    margin-top: 20px;
  }
}
