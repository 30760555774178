img {
  width: 100%;
}
.form-text-field {
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
}
._job_popup {
  width: 31%;
  /* height: 80vh; */
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  padding: 25px 25px;
}

.job-sub-title {
  font-size: 18px;
  font-weight: 600;
  color: #262626;
  line-height: 27px;
  position: relative;
}

.job-title {
  font-size: 25px;
  font-weight: 600;
  color: #074e8d;
  line-height: 45px;
}

._form_plot {
  width: calc(100% + 16px);
  /* max-height: 60vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 16px;
  /* overflow-y: auto; */
  margin-top: 10px;
}

/* ._form_plot::-webkit-scrollbar {
  width: 5px;
} */

/* Track */
._form_plot::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
._form_plot::-webkit-scrollbar-thumb {
  background: #074e8d;
  border-radius: 10px;
}

/* Handle on hover */
._form_plot::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
.errorImg {
  width: 10px;
  height: 10px;
  margin-right: 2px;
  margin-left: 2px;
}
.errorText {
  color: #ed1c24;
  /* text-transform: capitalize; */
  font-size: 10px;
  min-height: 2px;
}

@media (max-width: 1112px) {
  ._job_popup {
    width: 90%;
  }
  .job-sub-title {
    font-size: 12px;
    line-height: 14px;
  }
  .job-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 10px;
  }
}
