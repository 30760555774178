.daily-societies-desk-wrappwer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 40px auto 40px;
}

@media (max-width: 480px) {
  .daily-societies-mob-wrappwer {
    margin: 20px auto;
  }
}
