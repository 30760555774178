.product-info-box {
  display: flex;
  padding: 17px 0;
}

.image-container {
  width: 100%;
  margin: 60px 0 50px;
}

.money-image-web {
  display: block;
}

.money-image-mobile {
  display: none;
}

.money-image-container {
  width: 936px;
  margin: 80px auto 0px auto;
}
.money-image-container img {
  width: 100%;
}
.money-features-container {
  width: 923px;
  margin: 10px auto;
}
.money-features-title {
  width: 175px;
  height: 60px;
  top: 1248px;
  left: 639.5px;
  gap: 0px;
  opacity: 1px;
  margin: 50px auto 30px auto;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 60px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
}
.money-feature-list-section {
  width: 923px;
  height: auto;
  display: flex;
  justify-content: space-between;
}
.money-feature-box {
  border-radius: 46.03px;
  width: 279px;
  height: 637px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.money-feature-box:nth-child(1) {
  border: 1.84px solid #e9b42c;
}
.money-feature-box:nth-child(2) {
  border: 1.84px solid #aa395f;
}
.money-feature-box:nth-child(3) {
  border: 1.84px solid #5606b5;
}
.feature-logo-text-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 195px;
}
.feature-desk-logo {
  width: 89px;
  margin: 10px auto;
}
.feature-desk-logo img {
  width: 100%;
}
.feature-desk-text {
  color: #f3732a;
  width: 200px;
  height: 66px;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.money-feature-box:nth-child(3) .feature-logo {
  width: 210px;
  height: 215px;
  margin-top: 15px;
}

.feature-list {
  width: 245px;
  height: auto;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 20px 0px 0px 25px;
}
.feature-list ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  list-style-type: square;
}
li::marker {
  /* content: ''; */
  color: #094e8f;
}

@media screen and (max-width: 991.98px) {
  /* Adding patch to fix horizontal scrollbar issue, because of aos animation library*/
  .money-container {
    overflow-x: hidden;
  }

  .money-image-web {
    display: none;
  }

  .money-image-mobile {
    display: block;
  }

  .product-info-box {
    flex-direction: column;
  }

  .slider {
    width: 100%;
  }
  .image-container {
    margin: 0px 0px 40px;
  }

  .money-image-container {
    width: 328px;
    margin: 0px auto 0px auto;
  }
  .money-features-title {
    width: 110px;
    height: auto;
    top: 1248px;
    left: 639.5px;
    gap: 0px;
    opacity: 1px;
    margin: 10px 15%;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 60px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #094e8f;
  }

  .money-feature-list-section {
    width: 342px;
    height: 488px;
    display: flex;
    justify-content: space-between;
    margin: 0px 20px;
    gap: 27px;
  }
  .money-feature-box {
    border-radius: 46.03px;
    width: 205px;
    height: 468px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* .feature-logo {
    width: 117px;
    height: 135px;
    margin-top: 15px;
  } */
  .money-feature-box:nth-child(1) .feature-logo {
    width: 135px;
    height: 139px;
    margin: 12px auto 22px auto;
  }
  .money-feature-box:nth-child(2) .feature-logo {
    width: 115px;
    height: 138px;
    margin-top: 15px;
  }
  .money-feature-box:nth-child(3) .feature-logo {
    width: 160px;
    height: 139px;
    margin: 12px auto 22px auto;
  }
  .feature-list {
    font-family: Poppins;
    font-size: 13.24px;
    font-weight: 500;
    line-height: 19.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 10px 0px 0px 25px;
  }
  .money-feature-box:nth-child(2) .feature-list {
    margin: 35px 0px 0px 25px;
  }
  .feature-list ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
    list-style-type: square;
    width: 170px;
  }
  .money-feature-list-section .slick-initialized .slick-slide {
    /* display: block; */
    margin-right: 25px;
    height: 475px;
  }

  .feature-logo-text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin-top: 15px;
    height: 140px;
  }
  .feature-mob-logo {
    height: 65px;
    width: 65px;
  }
  .feature-mob-logo img {
    width: 100%;
  }
  .feature-mob-text {
    font-family: Poppins;
    font-size: 16.18px;
    font-weight: 600;
    line-height: 24.27px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #f3732a;
    width: 138px;
    margin: 10px auto;
  }
  /* .slick-slider .slick-track,
  .slick-slider .money-feature-list-section.slick-list {
    transform: translate3d(0, 0, 0);
    width: 390px;
  } */
  .money-info-slider-box .product-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 93%;
  }
}
