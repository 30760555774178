@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&family=Work+Sans:wght@200&display=swap');

img {
  width: 100%;
}

.banner-slide {
  width: 100%;
  position: relative;
}

.home-banner-img {
  width: 100%;
  position: relative;
}

/* .home-banner-img:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #ed1c24;
  background-blend-mode: multiply;
  opacity: 0.6;
} */

/* .banner-desc {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.new-banner-title {
  font-size: 27.75px;
  /* font-size: 30px; */
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  line-height: 41.62px;
  width: 311px;
}

.banner-heading {
  font-size: 50px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  text-align: center;
  margin: 20px 0;
}

.sub-title {
  font-size: 25px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #ffffff;
  padding: 0 1%;
  font-size: 27.75px;
  line-height: 41.62px;
  padding-bottom: 10px;
}
.landing_product_block {
  position: relative;
}
.know-more {
  position: absolute;
  bottom: 20%;
  right: 166px;
  width: auto;
  background-color: #fff;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #094e8f;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  border-radius: 20px;
  padding: 7px 35px;
  /* margin: 0px 50px; */
  /* margin-top: 6%; */
  font-weight: 500;
  z-index: 1;
}
/* .know-more {
  width: auto;
  background-color: #fff;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #094e8f;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  border-radius: 20px;
  padding: 7px 35px;
  margin: 0px 50px;
  margin-top: 6%;
  margin-left: 36%;
  font-weight: 500;
  z-index: 1;
} */
.u-tube-btn_slide {
  margin-top: 8%;
  z-index: 1;
}
.know-more-middle {
  position: absolute;
  bottom: -80px;
}
.home-banner-img .desktop-img {
  display: block;
}
.home-banner-img .mob-img {
  display: none;
}
.know-more:hover {
  -ms-transform: scale(1.1) !important; /* IE 9 */

  -webkit-transform: scale(1.1) !important; /* Safari 3-8 */

  transform: scale(1.1) !important;

  transition: 0.6s !important;

  -webkit-transition: 0.6s !important;
}
.know_and_watch {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1112px) {
  .banner-title {
    font-size: 20px;
  }
  .banner-heading {
    font-size: 25px;
  }
  .sub-title {
    font-size: 18px;
    line-height: 28.65px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
  }
  .home-banner-img {
    /* height: 100vh; */
  }
  .home-banner-img img {
    /* height: 100%; */
    height: calc(92.3vh - 48px) !important;
  }

  .know-more {
    font-size: 12px;
  }
  .home-banner-img .desktop-img {
    display: none;
  }
  .home-banner-img .mob-img {
    display: block;
  }
  .know-more-middle {
    margin-top: 12px !important;
    padding: 5px 35px !important;
    position: inherit !important;
    bottom: auto !important;
  }
}

.gradient-bg {
  /* background-image: url(../../assets/images/homeBanner_1_graddian.png); */
  background: linear-gradient(to left, #094e8f, #094e8f00);
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.banner-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 375px;
  font-family: 'Poppins', sans-serif;
  /* border-bottom: 2px dashed #9fd982; */
  margin-left: 186px;
}
.dashed-line {
  width: 100%;
  border-bottom: 2px dashed #9fd982;
}
.banner-disc {
  margin-top: 20px;
  width: 343px;
  text-align: center;
  margin-left: 195px;
}
@media (max-width: 1112px) {
  .gradient-bg {
    /* background-image: url(../../assets/images/mobile-gradient.png); */
    background: linear-gradient(to bottom, #094e8f, #094e8f00);
    /* position: absolute; */
    background-repeat: no-repeat;
    background-size: cover;
    right: unset;
    left: 0;
    top: -50px;
    width: 100%;
    height: 50%;
    /* display: flex;
  justify-content: center;
  align-items: center;
  color: white; */
  }
  .banner-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 260px;
    margin-left: 0px;
  }
  .new-banner-title {
    font-size: 19.1px;
    /* font-size: 30px; */
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    line-height: 41.62px;
    width: 215px;
    display: flex;
    justify-content: center;
  }
  .banner-disc {
    margin-top: 20px;
    width: 236px;
    text-align: center;
    font-size: 11px;
    line-height: 16px;
    margin-left: 0px;
  }
  .know-more {
    position: absolute;
    bottom: 55px;
    left: 45%;
    width: auto;
    background-color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #094e8f;
    cursor: pointer;
    text-decoration: none;
    text-transform: capitalize;
    border-radius: 20px;
    /* padding: 7px 35px; */
    /* margin: 0px 50px; */
    /* margin-top: 40%; */
    /* margin-left: 36%; */
    font-weight: 500;
    z-index: 1;
  }
  /* .know-more {
    width: auto;
    background-color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #094e8f;
    cursor: pointer;
    text-decoration: none;
    text-transform: capitalize;
    border-radius: 20px;
    padding: 7px 35px;
    margin: 0px 50px;
    margin-top: 6%;
    font-weight: 500;
    z-index: 1;
  } */
}
@media (max-width: 480px) {
  .know-button {
    margin-top: 20%;
  }

  .home-banner-plot {
    margin-top: 48px !important;
  }
  .banner-heading {
    margin: 30px 16px;
  }
  .sub-title {
    padding: 0 14px 10px;
    font-family: 'Poppins', sans-serif;
    /* font-family: 'Lato', sans-serif; */
  }
  .know-more {
    position: absolute;
    bottom: 55px;
    left: 30%;
    width: 160px;
    background-color: #fff;
    font-size: 16px !important;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #094e8f;
    cursor: pointer;
    text-decoration: none;
    text-transform: capitalize;
    border-radius: 20px;
    /* padding: 7px 35px; */
    /* margin: 0px 50px; */
    /* margin-top: 40%; */
    /* margin-left: 36%; */
    font-weight: 500;
    z-index: 1;
  }
  .know-more-middle {
    margin-top: 12px !important;
    padding: 5px 35px !important;
    position: inherit !important;
    bottom: auto !important;
  }

  .landing_product_wrapper {
    padding: 20px 14px !important;
  }

  .font-lato {
    font-family: 'Lato', sans-serif !important;
  }

  ._landing_product_icon img {
    height: 16px !important;
    width: auto;
  }

  ._landing_product_icon {
    width: auto !important;
    text-align: center;
  }

  .gradient-bg {
    /* background-image: url(../../assets/images/mobile-gradient.png); */
    /* position: absolute; */
    background: linear-gradient(to bottom, #094e8f, #094e8f00);
    background-repeat: no-repeat;
    background-size: cover;
    right: unset;
    left: 0;
    top: -7%;
    width: 100%;
    height: 50%;
    /* display: flex;
  justify-content: center;
  align-items: center;
  color: white; */
  }
}

@media screen and (min-height: 721) and (max-width: 480px) {
  .home-banner-img img {
    height: calc(92.3vh - 48px) !important;
  }
  .know_and_watch {
    display: flex;
    flex-direction: column;
  }
  .gradient-bg {
    /* background-image: url(../../assets/images/mobile-gradient.png); */
    /* position: absolute; */
    background: linear-gradient(to bottom, #094e8f, #094e8f00);
    background-repeat: no-repeat;
    background-size: cover;
    right: unset;
    left: 0;
    top: -50px;
    width: 100%;
    height: 50%;
    /* display: flex;
  justify-content: center;
  align-items: center;
  color: white; */
  }
}

@media screen and (max-height: 721) and (max-width: 480px) {
  .home-banner-img img {
    height: calc(91.3vh - 48px) !important;
  }
  .know_and_watch {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-height: 801px) and (max-width: 480px) {
  ._landing_product_desc {
    font-size: 18px;
  }
  .landing_product_block {
    margin-bottom: 20px;
  }
  .landing_product_wrapper {
    padding-top: 12px !important;
  }
  .know_and_watch {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-height: 650px) and (max-width: 480px) {
  ._landing_product_desc {
    font-size: 18px;
  }
  .landing_product_block {
    margin-bottom: 20px;
  }
  .home-banner-img img {
    height: calc(100vh - 48px) !important;
  }
  .landing_product_wrapper {
    padding-top: 10px !important;
  }
  .know_and_watch {
    display: flex;
    flex-direction: column;
  }
}

@supports (-webkit-touch-callout: none) {
  .home-banner-img img {
    height: calc(90.2vh - 48px) !important;
  }
  .know_and_watch {
    display: flex;
    flex-direction: column;
  }
}
