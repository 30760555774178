@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

body {
  font-family: 'Poppins', 'Lato', sans-serif;
}

.solution-page-comp {
  margin-bottom: 30px;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
}

.desktop-tab {
  background-color: #094e8f;
  display: flex;
  column-gap: 27px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 13px;
  list-style: none;
  position: relative;
  margin-top: 81px;
}

.solutions-mobile-active {
  display: none;
}

.tab-title {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #094e8f;
  padding: 4px 75px;
}

.tab-title:hover,
.tab-title:focus {
  box-shadow: 0px -1px 4px #ffffff, 0px 1px 4px #ffffff;
  border-radius: 200px;
  padding: 4px 75px;
  cursor: pointer;
}

.desktop-tab-active {
  box-shadow: 0px -1px 4px #ffffff, 0px 1px 4px #ffffff;
  border-radius: 200px;
  padding: 4px 75px;
  cursor: pointer;
}

.solutions-arrows {
  display: none;
}

/* UNIONS */

.dairy_unions_container,
.dairy_societies_container,
.dairy_farmers_container,
.dairy_partners_container {
  background-image: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)),
    url('../../components/SolutionPage/images/solutions_background_image.png');
  background-position: center;
  background-size: cover;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.union-title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  padding-top: 21px;
  text-align: center;
  margin-bottom: 21px;
}

.union-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 45px;
  text-align: center;
}

.union-box1,
.union-box2 {
  border-right: 2px solid #094e8f;
}

.pay-img {
  margin: 0 auto;
  width: 157px;
  margin-bottom: 25px;
  padding-top: 14px;
}

.money-img {
  width: 201px;
  margin: 0 auto;
  margin-bottom: 25px;
  padding-top: 14px;
}

.connect-dgv-img {
  width: 226px;
  margin: 0 auto;
  margin-bottom: 25px;
  padding-top: 14px;
}

.pay-img img,
.money-img img,
.connect-dgv-img img {
  width: 100%;
}

.union-desc {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 20px;
}

.union-video-content .slick-slider {
  width: 100%;
}

.union-video-content {
  display: flex;
  justify-content: center;
  margin: 0 60px;
}

.other-video-contents {
  margin: 0 auto;
  width: 60%;
}

.union-video {
  width: 100% !important;
  height: 250px;
  margin-bottom: 58px;
  padding: 0 10px;
}

.union-video-content .slick-slider .slick-dots li button::before {
  margin-top: -45px;
  color: rgba(237, 28, 36, 0.15);
  box-shadow: 0px 0px 2px #ffffff;
  font-size: 13px;
}

.union-video-content .slick-dots li.slick-active button:before {
  color: #ed1c24;
}

.union-video-content .slick-slider .slick-prev {
  left: -58px;
}

.union-video-content .slick-slider .slick-next {
  right: -15px;
}

.union-video-content .slick-slider .slick-prev::before {
  background-image: url('../../assets/images/leftArrow.png');
  background-size: cover;
}

.union-video-content .slick-slider .slick-next::before {
  background-image: url('../../assets/images/RightArrow.png');
  background-size: cover;
}

.union-video-content .slick-slider .slick-prev::before,
.union-video-content .slick-slider .slick-next::before {
  color: transparent;
  padding: 12px;
  opacity: 1;
  font-size: 42px;
}

/* SOCIETIES */

.societies-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 53px;
  text-align: center;
}

.societies-title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  padding-top: 21px;
  text-align: center;
  margin-bottom: 33px;
}

.societies-box1,
.societies-box2,
.societies-box3 {
  border-right: 2px solid #094e8f;
}

/* FARMERS */

.farmers-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 38px;
  text-align: center;
}

.farmers-title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  padding-top: 21px;
  text-align: center;
  margin-bottom: 21px;
}

.farmers-box1,
.farmers-box2 {
  border-right: 2px solid #094e8f;
}

/* PARTNERS */

.partners-title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  padding-top: 21px;
  text-align: center;
  margin-bottom: 30px;
}

.partners-box {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 51px;
  text-align: center;
}

.dgv-solutions-img {
  width: 181px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.dgv-solutions-img img {
  width: 100%;
}

/* MEDIA QUERIES */

@media (max-width: 1189px) {
  .wrapper {
    width: 100%;
    margin: 0 auto;
  }

  .tab-title {
    font-size: 16px;
    line-height: 18px;
    padding: 4px 35px;
  }

  .tab-title:hover,
  .tab-title:focus {
    padding: 4px 35px;
  }

  .desktop-tab-active {
    padding: 4px 35px;
  }

  .union-title,
  .societies-title,
  .farmers-title,
  .partners-title {
    padding: 20px 20px 0 20px;
    font-size: 16px;
  }

  .union-desc {
    font-size: 16px;
  }

  .union-box1,
  .union-box2,
  .union-box3,
  .societies-box1,
  .societies-box2,
  .societies-box3,
  .farmers-box1,
  .farmers-box2,
  .farmers-box3 {
    padding: 0 10px;
  }
}

@media (max-width: 870px) {
  .desktop-tab {
    column-gap: 15px;
  }

  .tab-title {
    font-size: 14px;
    padding: 4px 15px;
  }

  .tab-title:hover,
  .tab-title:focus {
    padding: 4px 15px;
  }

  .desktop-tab-active {
    padding: 4px 15px;
  }

  .union-title,
  .societies-title,
  .farmers-title,
  .partners-title {
    padding: 20px 20px 0 20px;
    font-size: 14px;
  }

  .union-desc {
    font-size: 14px;
    line-height: 17px;
  }

  .pay-img {
    width: 107px;
  }

  .money-img {
    width: 151px;
  }

  .connect-dgv-img {
    width: 176px;
  }

  .dgv-solutions-img {
    width: 131px;
  }

  .union-box,
  .farmers-box {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
    margin-bottom: 30px;
  }

  .union-box3,
  .farmers-box3 {
    grid-column: span 2;
  }

  .union-box2,
  .farmers-box2 {
    border-right: none;
  }

  .solution-page-comp {
    margin: 0px 0px 35px;
  }
}

@media (max-width: 600px) {
  .other-video-contents {
    width: 100%;
  }

  .union-video-content .slick-list {
    margin: 0 -2px;
  }

  .desktop-tab {
    display: none;
  }

  .solutions-mobile-active {
    display: block;
  }

  .solutions-mobile-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 4px 6px 4px 13px !important;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    background: #094e8f;
  }

  .dairy_unions_container,
  .dairy_societies_container,
  .dairy_farmers_container,
  .dairy_partners_container {
    margin-top: -20px;
    margin-bottom: 20px;
    background-image: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .solutions-arrows img {
    width: 100%;
  }

  .solutions-arrows {
    display: flex;
    align-items: center;
    width: 20px;
  }

  .union-title,
  .societies-title {
    padding: 12px 12px 0 12px;
    text-align: left;
    font-size: 16px;
    padding-top: -20;
    margin-bottom: 25px;
  }

  .farmers-title,
  .partners-title {
    text-align: left;
    font-size: 18px;
    margin-bottom: 25px;
    padding: 12px 12px 0 12px;
  }

  .union-box,
  .farmers-box,
  .societies-box {
    grid-template-columns: 1fr;
    row-gap: 40px;
    margin-bottom: 40px;
  }
  .union-box3,
  .farmers-box3 {
    grid-column: span 1;
  }
  .union-box1,
  .union-box2,
  .union-box3,
  .societies-box1,
  .societies-box2,
  .societies-box3,
  .farmers-box1,
  .farmers-box2,
  .farmers-box3,
  .partners-box {
    padding: 0;
    text-align: left;
  }

  .union-desc {
    font-size: 14px;
    line-height: 21px;
    padding-left: 35px;
    text-indent: -15px;
    margin-bottom: 10px;
    padding-right: 20px;
  }

  .union-desc::before {
    content: '■';
    color: #ed1c24;
    width: 8px;
    height: 8px;
    font-size: 20px;
    margin-right: 8px;
  }

  .union-desc:last-child {
    margin: 0;
  }

  .union-box1,
  .farmers-box1,
  .societies-box1 {
    border-right: none;
  }

  .pay-img,
  .money-img,
  .connect-dgv-img {
    padding-top: 0;
    margin-bottom: 15px;
  }

  .union-video {
    /* width: 100% !important; */
    height: 201px;
    margin-bottom: 40px;
    margin-left: 0;
    margin-right: 0;
  }

  .union-video-content {
    margin: 0;
  }

  .union-video-content .slick-slider {
    margin: 0;
  }

  .union-video-content .slick-slider .slick-dots li button::before {
    margin-top: -35px;
  }
}

@media (max-width: 350px) {
  .union-video {
    height: 150px;

    margin-bottom: 40px;
    margin-left: 0;
    margin-right: 0;
  }

  .union-video-content {
    margin: 0;
  }

  .union-video-content .slick-slider .slick-prev {
    display: none;
  }

  .union-video-content .slick-slider .slick-next {
    display: none;
  }
}
