@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&family=Work+Sans:wght@200&display=swap');

.home-banner-img img {
  width: 100%;
  /* height: 100vh; */
  height: calc(100vh - 90px);
}

.home-banner-plot {
  width: 100%;
  /* height: calc(100vh - 78px); */
  margin-top: 90px;
}

.banner-slide {
  width: 100%;
  position: relative;
}

.home-banner-img {
  width: 100%;
  height: 100%;
  position: relative;
}

/* .home-banner-img:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #ed1c24;
  background-blend-mode: multiply;
  opacity: 0.6;
} */

.banner-desc {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 80px;
  left: 0;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.banner-title {
  font-size: 30px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
}

.banner-heading {
  font-size: 50px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  text-align: center;
  margin: 20px 0;
}

.sub-title {
  font-size: 25px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #ffffff;
  padding: 0 14%;
}

.landing_product_wrapper {
  width: 100%;
  display: flex;
  padding: 20px 8%;
}

.landing_product_block {
  width: calc((100% - 40px) / 3);
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  /* padding: 0 25px; */
}

.landing_product_block:not(:last-child) {
  margin-right: 20px;
}

._landing_product_icon {
  /* width: 20%; */
  height: 14vh;
  margin-bottom: 30px;
}
._landing_product_icon img {
  object-fit: contain;
  height: 100%;
}
._landing_product_desc {
  width: auto;
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  text-align: center;
  color: #fff;
}
.know-more {
  width: auto;
  background-color: #fff;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #094e8f;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  border-radius: 20px;
  padding: 7px 35px;
  margin-top: 8%;
}

.slick-dots {
  bottom: 35px;
}

.home-banner-plot .slick-dots li button:before {
  color: #cccaca;
  font-size: 14px;
  opacity: 1;
  top: -3px;
}

.home-banner-plot .slick-dots li.slick-active button:before {
  font-size: 16px;
  color: #ffffff;
  opacity: 1;
}

.home-banner-plot .slick-dots {
  bottom: 16px;
}

.home-banner-plot .slick-dots li {
  margin: 0 6px;
  width: 11px;
  height: 11px;
}

.home-banner-plot .slick-dots li button {
  padding: 0;
  width: 11px;
  height: 11px;
}

.home-banner-plot .slick-dots .slick-active {
  width: 11px;
  height: 11px;
}

.landing_product_block .desktop-img {
  display: block;
}
.landing_product_block .mob-img {
  display: none;
}

@media (max-width: 1112px) {
  .home-banner-plot {
    width: 100%;
    /* height: calc(100vh - 78px); */
    margin-top: 48px !important;
  }
  .banner-title {
    font-size: 20px;
  }
  .banner-heading {
    font-size: 25px;
  }
  .sub-title {
    font-size: 18px;
    padding: 0 12%;
  }
  .home-banner-img {
    /* height: 100vh; */
  }
  /* .home-banner-img img {
    height: 100vh;
  } */
  .slick-dots {
    bottom: 30px;
  }
  .landing_product_block {
    width: 100%;
    margin-bottom: 20px;
  }
  .landing_product_wrapper {
    flex-direction: column;
  }
  ._landing_product_desc {
    font-size: 18px;
  }
  .banner-desc {
    top: 20%;
  }
  .know-more {
    font-size: 12px;
  }

  ._landing_product_icon {
    width: 42%;
    height: unset;
    margin-bottom: 10px;
  }
  .landing_product_block .desktop-img {
    display: none;
  }
  .landing_product_block .mob-img {
    display: block;
  }
  .width-50 {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .banner-desc {
    top: 5%;
  }
  .landing_product_block {
    width: 100%;
    padding: 0;
    margin-bottom: 33px;
  }
  .landing_product_block:not(:last-child) {
    margin-right: 0px;
  }

  .banner-desc-slide-2 {
    top: 2% !important;
  }

  .landing_product_block:last-child {
    margin-bottom: 20px;
  }

  ._landing_product_desc {
    line-height: 1.3;
  }
}

.animate__delay-6s {
  animation-delay: 6s;
}
.animate__delay-7s {
  animation-delay: 7s;
}
.animate__delay-8s {
  animation-delay: 8s;
}
.animate__delay-9s {
  animation-delay: 9s;
}

.animate__delay-10s {
  animation-delay: 10s;
}

.animate__delay-11s {
  animation-delay: 11s;
}

.animate__delay-12s {
  animation-delay: 12s;
}

.animate__delay-13s {
  animation-delay: 13s;
}

.animate__delay-14s {
  animation-delay: 14s;
}

.animate__delay-15s {
  animation-delay: 15s;
}
