.meet-our-CEO-main {
  display: flex;
  flex-direction: column;
}
.meet-our-CEO-header {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  display: flex;
  justify-content: center;
  color: #094e8f;
}
.meet-our-CEO-photo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.CEO-img {
  width: 600px;
  /* height: 400px; */
}
.CEO-name {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  margin-top: 14px;
  display: flex;
  justify-content: center;
}
.CEO-founder {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  color: #094e8f;
  display: flex;
  justify-content: center;
}
.CEO-para {
  font-family: 'Poppins', sans-serif;

  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: justify;
}
.CEO-linkedin {
  width: 40px;
  height: 40px;
  display: flex;
  align-self: center;
  margin-top: 10px;
}
@media screen and (max-width: 991.98px) {
  .meet-our-CEO-photo {
    margin-top: 10px;
  }
  .meet-our-CEO-header {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    justify-content: center;
  }

  .CEO-img {
    width: 100%;
    /* height: 200px; */
  }

  .CEO-name {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin-top: 14px;
  }
  .CEO-founder {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #094e8f;
  }
  .CEO-para {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: justify;
    margin-top: 10px;
  }
}
