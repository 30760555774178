@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

* {
  margin: 0;
  padding: 0;
}

.investorPartner {
  /* background-image: url('../../assets/images/background_img.png'); */
  background-size: cover;
  padding-bottom: 10px;
}

.investor-partner-wrapper {
  width: 100%;
  /* margin-top: 50px; */
  /* margin: 19px auto; */
  display: block;
}

.investor-heading,
.partner-heading {
  text-align: center;
  /* margin-bottom: 30px; */
}

.partner-heading {
  margin-top: 43px;
}

.investor-heading h1,
.partner-heading h1 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #094e8f;
}

.investor-heading p,
.partner-heading p {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  letter-spacing: -0.02em;
  color: #262626;
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 40px;
  margin-top: 20px;
}

.investor-logo-1 {
  width: 208.07px;
}
.investor-logo-2 {
  width: 311px;
}
.investor-logo-3 {
  width: 262.07px;
}
.investor-logo img {
  width: 100%;
}

.partner-logo {
  width: 190.96px;
}

.partner-logo-sid-farm {
  width: 111.202px;
}
.partner-logo-jersey {
  width: 185.087px;
}
.partner-logo-muktaa {
  width: 165px;
}
.partner-logo-bank {
  width: 190.96px;
  cursor: pointer;
}

.partner-logo img {
  width: 100%;
}
.partner-logo-BOM {
  width: 202px;
}
.BOM-logo {
  height: 66px;
}

@media (max-width: 860px) {
  .partner-heading {
    margin-top: 40px;
  }

  .investor-heading,
  .partner-heading {
    text-align: center;
    margin-bottom: 20px;
  }

  .investor-heading h1,
  .partner-heading h1 {
    font-size: 32px;
    line-height: 52px;
  }
  .investor-heading p,
  .partner-heading p {
    font-size: 22px;
    line-height: 32px;
  }

  .investor-logo-1 {
    width: 157.17px;
    margin: 0px auto;
  }
  .investor-logo-2 {
    width: 236px;
    margin: 0px auto;
  }
  .investor-logo-3 {
    width: 200px;
    margin: 0px auto;
  }

  .partner-logo {
    width: 186.85px;
  }
  .partner-logo-sid-farm {
    width: 141.529px;
  }
  .partner-logo-jersey {
    width: 185.087px;
  }
  .partner-logo-muktaa {
    width: 165px;
  }
}
@media (max-width: 480px) {
  .investor-heading {
    margin-bottom: 22px;
  }
  .partner-heading {
    margin-top: 40px;
    margin-bottom: 19px;
  }

  .investor-heading h1,
  .partner-heading h1 {
    font-weight: 600;
    font-size: 25px;
    line-height: 37px;
    margin-bottom: 10px;
  }

  .investor-heading p,
  .partner-heading p {
    font-size: 16px;
    line-height: 24px;
  }

  .logo-wrapper {
    flex-direction: column;
    align-items: center;
    row-gap: 2px;
  }
}
