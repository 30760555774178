.bovine-main-component {
  position: relative;
  margin-bottom: 40px;
  cursor: default;
}
.bovine-title-image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.bovine-logo {
  width: 243px;
  margin: 0px auto;
}
.bovine-logo img {
  width: 100%;
}
.bovine-title {
  width: 307px;
  height: 45px;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
  margin: 20px auto;
}

.bovine-footprints-section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* align-items: flex-end; */
}
.bovine-footprint-box:nth-child(1) {
  width: 318px;
}
.bovine-footprint-box:nth-child(2) {
  width: 284px;
}
.bovine-footprint-box:nth-child(3) {
  width: 264px;
  margin-bottom: 40px;
}
.bovine-footprint-box img {
  width: 100%;
}
.bovine-feature-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-bottom: 150px;
}
.bovine-feature-text {
  color: #094e8f;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 180px;
}
.bovine-feature-info {
  width: 295px;
  position: relative;
}

.bovine-feature-info:nth-child(1) {
  width: 295.4px;
  height: 539.52px;
  top: 200.48px;
  left: 85.56px;
  gap: 0px;
  z-index: 2;
}
.bovine-feature-info:nth-child(1) .bovine-info-text {
  width: 140.92px;
  height: 230px;
  top: 105.77px;
  left: 44.15px;

  position: absolute;
}
.bovine-feature-info:nth-child(1) .bovine-info-logo {
  width: 64px;
  position: absolute;
  bottom: 151px;
  left: 79px;
}

.bovine-feature-info:nth-child(2) {
  width: 295.4px;
  height: 539.52px;
  top: 100.88px;
  left: 50.74px;
  gap: 0px;
  z-index: 1;
}
.bovine-feature-info:nth-child(2) .bovine-info-text {
  width: 150.92px;
  height: 230px;
  top: 109.77px;
  left: 34.15px;
  position: absolute;
}
.bovine-feature-info:nth-child(2) .bovine-info-logo {
  width: 56px;
  position: absolute;
  bottom: 156px;
  left: 85px;
}
.bovine-feature-info:nth-child(3) {
  width: 295.41px;
  height: 539.52px;
  top: 0px;
  left: 10.9px;
  gap: 0px;
}
.bovine-feature-info:nth-child(3) .bovine-info-text {
  width: 150.92px;
  height: 230px;
  top: 109.77px;
  left: 34.15px;
  position: absolute;
}
.bovine-info-logo {
  width: 60px;
  position: absolute;
  bottom: 157px;
  left: 82px;
}
.bovine-feature-info:nth-child(4) .bovine-info-text {
  width: 140.92px;
  height: 230px;
  top: 102.77px;
  left: 38.15px;
  position: absolute;
}
.bovine-feature-info:nth-child(4) {
  width: 295.4px;
  height: 539.52px;
  top: 100.88px;
  left: -25.34px;
  gap: 0px;
  z-index: -1;
}
.bovine-feature-info:nth-child(4) .bovine-info-logo {
  width: 55px;
  position: absolute;
  bottom: 158px;
  left: 80px;
}
.bovine-feature-info:nth-child(5) {
  width: 295.41px;
  height: 539.52px;
  top: 200.48px;
  left: -65.29px;
  gap: 0px;
  z-index: -2;
}
.bovine-feature-info:nth-child(5) .bovine-info-text {
  width: 130.92px;
  height: 230px;
  top: 100.77px;
  left: 34.15px;
  position: absolute;
}
.bovine-feature-info:nth-child(5) .bovine-info-logo {
  width: 50px;
  position: absolute;
  bottom: 158px;
  left: 85px;
}

.bovine-feature-wrapper {
  display: flex;
  width: 1180px;
  height: 500px;
}
.bovine-feature-info img {
  width: 100%;
}
@media (max-width: 480px) {
  .bovine-main-component {
    position: relative;
    margin-bottom: 40px;
    cursor: default;
  }
  .bovine-feature-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-bottom: 0px;
  }
  .bovine-title-image-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .bovine-logo {
    width: 220px;
    margin: 0px auto;
  }
  .bovine-logo img {
    width: 100%;
  }
  .bovine-title {
    width: 307px;
    height: 45px;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #094e8f;
    margin: 20px auto;
  }
  .bovine-feature-text {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 37.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 113px;
  }
  /* .bovine-title {
    width: 263px;
    height: 38px;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 37.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  } */

  .bovine-footprints-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* align-items: flex-end; */
  }
  .bovine-footprint-box:nth-child(1) {
    width: 236px;
    margin: 0px auto;
    margin-top: 10px;
  }
  .bovine-footprint-box:nth-child(2) {
    width: 211px;
    margin: 0px auto;
    margin-top: 10px;
  }
  .bovine-footprint-box:nth-child(3) {
    width: 241px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .bovine-footprint-box img {
    width: 100%;
  }
  .bovine-feature-info {
    width: 295px;
    position: relative;
  }
  .bovine-feature-info:nth-child(1) {
    width: 295.4px;
    height: 539.52px;
    top: 0px;
    left: 0px;
    gap: 0px;
    z-index: 2;
  }
  .bovine-feature-info:nth-child(2) {
    width: 295.4px;
    height: 539.52px;
    top: 0px;
    left: 0;
    gap: 0px;
    z-index: 1;
  }
  .bovine-feature-info:nth-child(3) {
    width: 295.41px;
    height: 539.52px;
    top: 0px;
    left: 0px;
    gap: 0px;
  }
  .bovine-feature-info:nth-child(4) {
    width: 295.4px;
    height: 539.52px;
    top: 0.88px;
    left: 0px;
    gap: 0px;
    z-index: 0;
  }
  .bovine-feature-info:nth-child(5) {
    width: 295.41px;
    height: 539.52px;
    top: 0.48px;
    left: 0px;
    gap: 0px;
    z-index: 0;
  }
  .bovine-info-logo {
    width: 45px;
    position: absolute;
    bottom: 168px;
    left: 87px;
  }
  .bovine-feature-info:nth-child(1) .bovine-info-logo {
    width: 45px;
    position: absolute;
    bottom: 168px;
    left: 87px;
  }
  .bovine-feature-info:nth-child(2) .bovine-info-logo {
    width: 50px;
    position: absolute;
    bottom: 158px;
    left: 85px;
  }
  .bovine-feature-info:nth-child(3) .bovine-info-logo {
    width: 50px;
    position: absolute;
    bottom: 158px;
    left: 85px;
  }
  .bovine-feature-info:nth-child(4) .bovine-info-logo {
    width: 50px;
    position: absolute;
    bottom: 158px;
    left: 85px;
  }
  .bovine-feature-info:nth-child(5) .bovine-info-logo {
    width: 50px;
    position: absolute;
    bottom: 158px;
    left: 85px;
  }
}
