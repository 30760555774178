/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

._form_input_row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  display: flex;
  width: auto;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #262626;
  /* margin-bottom: 10px; */
}
.fClass {
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #262626;
  border: 1px solid rgba(173, 152, 152, 0.5);
  border-radius: 4px;
  /* margin-bottom: 10px; */
  padding: 10px;
  resize: none;
}
._text_box {
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #262626;
  border: 1px solid rgba(173, 152, 152, 0.5);
  border-radius: 4px;
  /* border: 1px solid #262626; */
  /* opacity: 0.3; */
  resize: none;
}

input::placeholder,
textarea::placeholder {
  /* text-transform: capitalize; */
  opacity: 0.5;
  color: #060606;
}
