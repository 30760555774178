@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&family=Work+Sans:wght@200&display=swap');

img {
  width: 100%;
}

.banner-slide {
  width: 100%;
  position: relative;
}

.home-banner-img {
  width: 100%;
  position: relative;
}

.insuarnce-banner-title {
  width: 240px;
  position: absolute;
  top: 30px;
  z-index: 2;
  left: calc((100% - 290px) / 2);
}
.insuarnce-banner-title img {
  width: 100%;
}

.slide-col-first {
  width: 160px;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 135px;
  left: calc((100% - 600px) / 2);
}
.slide-col-second {
  width: 160px;
  height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 150px;
  right: calc((100% - 510px) / 2);
}
.slide-col-block {
  width: 100%;
}
.slide-col-block img {
  width: 100%;
  height: unset;
}
/* .home-banner-img:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #ed1c24;
  background-blend-mode: multiply;
  opacity: 0.6;
} */

.banner-desc-slide3 {
  width: 53%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bottom-mr {
  margin-bottom: 30px;
}
.insuarnce-banner-title {
  font-size: 30px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  width: 240px;
  position: absolute;
  top: 12px;
  z-index: 2;
  left: calc((100% - 290px) / 2);
}

.banner-heading {
  font-size: 50px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  text-align: center;
  margin: 20px 0;
}

.mob-title {
  font-size: 25px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #ffffff;
  padding: 0 8%;
}
.landing_product_block {
  position: relative;
}
/* .know-more-slide3 {
  width: auto;
  background-color: #fff;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #094e8f;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  border-radius: 20px;

  margin-top: 8%;
  font-weight: 500;
  z-index: 1;
} */
.u-tube-btn {
  margin-top: 40px;
  z-index: 1;
}
.know-more-middle {
  position: absolute;
  bottom: -80px;
}
.home-banner-img .desktop-img {
  display: block;
}
.home-banner-img .mob-img {
  display: none;
}
.know-more:hover {
  -ms-transform: scale(1.1) !important; /* IE 9 */

  -webkit-transform: scale(1.1) !important; /* Safari 3-8 */

  transform: scale(1.1) !important;

  transition: 0.6s !important;

  -webkit-transition: 0.6s !important;
}
.image-bannner-box {
  position: absolute;
  width: 607px;
  top: 120px;
  left: calc((100% - 452px) / 2);
}
.image-bannner-box img {
  width: 100%;
  height: unset !important;
}
.subject-layer-box {
  position: absolute;
  /* width: 607px; */
  top: -50px;
  /* left: calc((100% - 452px) / 2); */
}
.subject-layer-box img {
  width: 100%;
  height: unset !important;
}
.group-section-wrapper {
  position: absolute;
  top: 120px;
  left: calc((100% - 35%) / 2);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 22px;
  width: 43%;
}
.group-section-one {
  width: 334px;
  height: 74px;
}
.group-section-two {
  width: 273px;
  height: 74px;
}
/* .group-section-wrapper :first-child .group-section {
  width: 334px;
  height: 74px;
}

.group-section-wrapper :last-child .group-section {
  width: 273px;
  height: 74px;
} */
.group-section img {
  width: 100%;
  /* height: unset !important; */
  /* height: 8vh; */
}
.group-section-2 {
  width: 273px;
}
.group-section-2 img {
  width: 100%;
  /* height: unset !important; */
  height: 8vh;
}
.image-grid {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr; /* Middle column is wider */
  grid-template-rows: 1fr 2fr 1fr; /* Middle row is taller */
  gap: 10px; /* Space between images */
  width: 100%;
  max-width: 600px; /* Optional: Set a max width */
  margin: auto; /* Centers the grid container */
}

.corner-image {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.middle-image {
  grid-column: 2 / 3; /* Centers horizontally in the middle column */
  grid-row: 1 / span 3; /* Spans vertically across all rows */
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

/* Assign specific grid areas to place corner images */
.corner-image:nth-child(1) {
  grid-area: 1 / 1 / 2 / 2;
} /* Top Left */
.corner-image:nth-child(2) {
  grid-area: 1 / 3 / 2 / 4;
} /* Top Right */
.corner-image:nth-child(4) {
  grid-area: 3 / 1 / 4 / 2;
} /* Bottom Left */
.corner-image:nth-child(5) {
  grid-area: 3 / 3 / 4 / 4;
} /* Bottom Right */

.insurance-grid {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
}
.insurance-col-1 {
  width: 25%;
  float: left;
}
.col-block {
  width: 100%;
  height: calc((100% - 5px) / 2);
  margin-bottom: 5px;
}
.col-block:nth-child(2) {
  margin-bottom: 0px;
}
.col-block img {
  width: 100%;
  height: 100%;
}
.middle-col {
  width: 44%;
  height: 20vh;
  float: left;
}
.middle-col img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1282px) {
  .group-section-wrapper {
    position: absolute;
    top: 110px;
    left: calc((100% - 35%) / 2);
    display: flex;
    justify-content: center;
    /* align-items: center; */
    gap: 22px;
    width: 43%;
  }
  .group-section-one {
    width: 334px;
    height: 50px;
  }
  .group-section-one img {
    height: 100%;
  }
  .group-section-two {
    width: 273px;
    height: 50px;
  }
  .group-section-two img {
    height: 100%;
  }
}

@media (max-width: 1112px) {
  .insuarnce-banner-title {
    font-size: 30px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    width: 160px;
    position: absolute;
    top: 30px;
    z-index: 2;
    left: calc((100% - 160px) / 2);
  }
  .insuarnce-banner-title img {
    width: 100%;
    height: unset !important;
  }
  .connect-option-wrpper {
    position: absolute;
    top: 75px;
    left: 0;
    width: 1169px;
  }
  .connect-option {
    width: 491px;
  }
  .connect-option:nth-child(1) {
    margin-left: -20px;
  }
  .connect-option:nth-child(2) {
    margin-left: 50px;
  }
  .connect-option:nth-child(3) {
    margin-left: 100px;
  }
  .connect-option:nth-child(4) {
    margin-left: 175px;
  }
  .connect-option img {
    width: 100%;
    height: unset !important;
  }

  .mob-group-section-wrapper {
    position: absolute;
    top: 150px;
    width: 292px;
    left: calc((100% - 293px) / 2);
  }
  .mob-subject-img {
    position: absolute;
    bottom: 3%;
    left: 0;
  }
  .mob-subject-img img {
    width: 100%;
    height: unset !important;
  }
}
@media (max-width: 600px) {
  .insuarnce-banner-title {
    font-size: 30px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    width: 160px;
    position: absolute;
    top: 25px;
    z-index: 2;
    left: calc((100% - 160px) / 2);
  }
  .insuarnce-banner-title img {
    width: 100%;
    height: unset !important;
  }
  .connect-option-wrpper {
    position: absolute;
    top: 75px;
    left: 0;
    width: 1169px;
  }
  .connect-option {
    width: 260px;
  }
  .connect-option:nth-child(1) {
    margin-left: -27px;
  }
  .connect-option:nth-child(2) {
    margin-left: 13px;
    width: 263px;
  }
  .connect-option:nth-child(3) {
    margin-left: 35px;
    width: 276px;
  }
  .connect-option:nth-child(4) {
    margin-left: 105px;
    width: 235px;
  }
  .connect-option img {
    width: 100%;
    height: unset !important;
  }

  .mob-group-section-wrapper {
    position: absolute;
    top: 14%;
    width: 315px;
    left: calc((100% - 315px) / 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .mob-group-section-box {
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .isure-banner-wrapper {
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding-bottom: 18px;
    width: 85%;
  }
  .insur-banner-box img {
    height: 8vh;
  }
  .mob-subject-img {
    position: absolute;
    bottom: 3%;
    left: 0;
  }
  .mob-subject-img img {
    width: 100%;
    height: unset !important;
  }
}

@media (max-width: 480px) {
  .know-button {
    margin-top: 20%;
  }

  .home-banner-plot {
    margin-top: 48px !important;
  }
  .banner-heading {
    margin: 30px 16px;
  }
  .mob-title {
    padding: 0 53px 10px;
    font-family: 'Lato', sans-serif;
  }

  .know-more-slide3 {
    margin-top: 50px;
  }
  .know-more-middle {
    margin-top: 12px !important;
    padding: 5px 35px !important;
    position: inherit !important;
    bottom: auto !important;
  }

  .landing_product_wrapper {
    padding: 20px 14px !important;
  }

  .font-lato {
    font-family: 'Lato', sans-serif !important;
  }

  ._landing_product_icon img {
    height: 16px !important;
    width: auto;
  }

  ._landing_product_icon {
    width: auto !important;
    text-align: center;
  }

  .banner-desc-slide3 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 110px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .u-tube-btn {
    margin-top: 0px;
    z-index: 1;
  }
  .insur-banner-box img {
    height: 8vh;
  }
}
@media (max-width: 376) {
  .insur-banner-box img {
    height: 11vh;
  }
}
@media screen and (min-height: 721) and (max-width: 480px) {
  .home-banner-img img {
    height: calc(92.3vh - 48px) !important;
  }
}

@media screen and (max-height: 721) and (max-width: 480px) {
  .home-banner-img img {
    height: calc(91.3vh - 48px) !important;
  }
}

@media screen and (max-height: 801px) and (max-width: 480px) {
  ._landing_product_desc {
    font-size: 18px;
  }
  .landing_product_block {
    margin-bottom: 20px;
  }
  .landing_product_wrapper {
    padding-top: 12px !important;
  }
}

@media screen and (max-height: 650px) and (max-width: 480px) {
  ._landing_product_desc {
    font-size: 18px;
  }
  .landing_product_block {
    margin-bottom: 20px;
  }
  .home-banner-img img {
    height: calc(100vh - 48px) !important;
  }
  .landing_product_wrapper {
    padding-top: 10px !important;
  }

  .connect-option-wrpper .connect-option img {
    height: unset !important ;
  }
}

@supports (-webkit-touch-callout: none) {
  .home-banner-img img {
    height: calc(90.2vh - 48px) !important;
  }
}
