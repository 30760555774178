.mde-popup-wrap-plot {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 0;
  display: flex;
  opacity: 0;
  -webkit-transition: all 2s ease-in;
  -moz-transition: all 2s ease-in;
  -ms-transition: all 2s ease-in;
  -o-transition: all 2s ease-in;
  transition: all 2s ease-in;
  /* z-index: 6; */
}
.mde-popup-wrap-plot.fade {
  opacity: 1;
  z-index: 6;
}

._mde_popup_wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 0;
  -webkit-transition: all 2s ease-in;
  -moz-transition: all 2s ease-in;
  -ms-transition: all 2s ease-in;
  -o-transition: all 2s ease-in;
  transition: all 2s ease-in;
}
.mde-popup-wrap-plot.fade ._mde_popup_wrap {
  opacity: 1;
  z-index: 6;
}

._mde_popup {
  width: 475px;
  height: 383px;
  background-image: url(../../assets/images/MDE_popup_banner.png);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 7;
}

._mde_top_bar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 16px;
}
._mde_logo {
  width: 95px;
  height: 62px;
}
._mde_logo img {
  width: 100%;
}
._mde_popup_close {
  width: 16px;
  height: 16px;
  background-image: url(../../assets/images/close-black.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: transparent;
  position: absolute;
  right: 13px;
  top: 13px;
  cursor: pointer;
}
.MDE-text-plot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.MDE-text-plot .MDE-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 24.6763px;
  text-align: center;
  text-transform: uppercase;
  color: #ed1c24;
  text-shadow: 0px 3.22166px 3.22166px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
}
.MDE-text-plot .MDE-desc {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18.1079px;
  text-align: center;
  color: #000000;
}
.MDE-text-plot ._know_more {
  width: 120px;
  height: 30px;
  background: #ed1c24;
  border-radius: 79.393px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  line-height: 27px;
  margin-top: 20px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 480px) {
  ._mde_popup {
    width: 327px;
    height: 265px;
  }
  ._mde_logo {
    width: 66px;
    height: 43px;
  }
  ._mde_popup_close {
    width: 11px;
    height: 11px;
  }
  .MDE-text-plot .MDE-text {
    font-size: 17px;
    margin-bottom: 2px;
  }
  .MDE-text-plot .MDE-desc {
    font-size: 13px;
  }
  .MDE-text-plot ._know_more {
    width: 107px;
    height: 27px;
    font-size: 11px;
    margin-top: 10px;
  }
}
