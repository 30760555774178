.with-watch-now-main-component {
  width: 440px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.7px 2.7px 27.02px 0px;
  background: rgba(223, 255, 190, 0.6);
  box-shadow: 2.7px 2.7px 27.02px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
.with-watch-now-info-box {
  padding: 15px;
}
.with-watch-now-image-box {
  width: 100%;
}
.with-watch-now-image-box img {
  width: 100%;
}
.with-watch-now-info-box {
  padding: 15px;
  display: flex;
  flex-direction: column;
}
.with-watch-now-sub-title {
  color: #094e8f;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 10px;
  min-height: 75px;
}
.with-watch-now-disc {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
  margin-bottom: 10px;
}
.with-watch-now-title {
  font-family: Poppins;
  font-size: 44.84px;
  font-weight: 600;
  line-height: 67.26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
}
.with-watch-now-box {
  margin-top: 25px;
}

@media (max-width: 480px) {
  .with-watch-now-box {
    margin-top: 7px;
  }
}
