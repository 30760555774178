/* .daily-union-desk-wrappwer {
  display: flex;
  margin: 40px auto 40px;
  gap: 15px;
} */
.daily-union-desk-wrappwer {
  display: flex;
  margin: 40px auto 40px;
  /* gap: 15px; */
  justify-content: space-evenly;
}
.daily-union-right {
  display: flex;
  /* flex-direction: column; */
  gap: 15px;
  width: 620px;
  flex-wrap: wrap;
}

.daily-union-box {
  width: 300px;
}
.daily-union-box .without-watch-now-section-top {
  width: 300px;
}
.daily-union-box .middle-line {
  border: 1.41px 0px 0px 0px;
  border: 1.41px solid rgba(224, 225, 227, 1);
  margin: 10px auto;

  width: 278px;
}
.daily-union-box .without-watch-now-title-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
}
.daily-union-box .without-watch-now-title-text:nth-child(2) {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
  padding-bottom: 30px;
}
.daily-union-box .without-watch-now-image-box {
  width: 100%;
  height: 186px;
  margin: 0px auto;
}

.daily-union-right-section .with-watch-now-sub-title {
  color: #094e8f;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 10px;
  min-height: 33px;
}

.daily-union-left-top-box .without-watch-now-dics {
  color: #094e8f;
  width: 580px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 5px auto 10px;
}

.daily-union-right-section .with-watch-now-box {
  margin-top: 25px;
}
.daily-union-right-section .with-watch-now-title {
  font-family: Poppins;
  font-size: 44.84px;
  font-weight: 600;
  line-height: 54.26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #094e8f;
}
@media (max-width: 480px) {
  .daily-union-mob-wrappwer {
    margin: 20px auto;
  }
}
