.money-los-main-container {
  width: 100%;
  position: relative;
}

.money-los-image-box-section {
  width: 975px;
  height: 821px;
  margin: 20px auto;
}
.money-los-logo {
  width: 494px;
  margin: 10px auto;
}
.money-los-logo img {
  width: 100%;
}
.money-los-image {
  width: 975px;
  margin: 30px auto 0px auto;
}
.money-los-image img {
  width: 100%;
}
.money-los-feature-section {
  width: 1086px;
  margin: 0px auto 40px auto;
}
.money-los-feature-text {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 180px;
  height: 60px;
  margin: 0px auto 40px auto;
  color: #094e8f;
}
.money-los-feature-list {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  gap: 10px;
}
.money-los-feature:nth-child(1) {
  width: 915px;
  align-self: flex-start;
}
.money-los-feature:nth-child(2) {
  width: 832px;
  align-self: flex-end;
}
.money-los-feature:nth-child(3) {
  width: 915px;
  align-self: flex-start;
}
.money-los-feature img {
  width: 100%;
}
@media (max-width: 480px) {
  .money-los-main-container {
    width: 100%;
    position: relative;
  }
  .money-los-image-box-section {
    width: 323px;
    height: 288px;
    margin: 20px auto;
  }
  .money-los-logo {
    width: 291px;
    margin: 10px auto;
  }
  .money-los-logo img {
    width: 100%;
  }
  .money-los-image {
    width: 323px;
    margin: 15px auto;
  }
  .money-los-image img {
    width: 100%;
  }

  .money-los-feature-section {
    width: 100%;
    margin: 0px;
  }
  .money-los-feature-text {
    width: 112px;
    height: 38px;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 37.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0px auto 20px auto;
  }
  .money-los-feature-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0px auto;
    width: 269px;
  }
  .money-los-feature:nth-child(1) {
    width: 269px;
    align-self: center;
  }
  .money-los-feature:nth-child(2) {
    width: 269px;
    align-self: center;
  }
  .money-los-feature:nth-child(3) {
    width: 269px;
    align-self: center;
  }
  .money-los-feature img {
    width: 100%;
  }
}
