.group-bg-box {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 15px;
}
.group-title {
  width: 30%;
  position: absolute;
  top: 55%;
}
.group-title img {
  width: 100%;
}
.group-box-wrapper {
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  height: 100%;
}
.group-box-wrapper:nth-child(2) {
  left: 43%;
  top: 34px;
}

.group-box {
  display: flex;
  position: absolute;
  width: 170px;
  top: 50%;
  left: 10%;
}
@media (min-width: 1395px) {
  .group-box {
    top: 52%;
  }
}

.group-box:nth-child(2) {
}
.group-info-box {
  width: 170px;
  position: absolute;
  top: 50%;
  left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.group-info-box.dgv-riddhi-img {
  top: 4%;
  left: 43%;
}

.group-info-box.dgv-riddhi-img a {
  text-align: center;
}
.group-info-box.dgv-riddhi-img img {
  width: 75px;
}
.group-info-box.dgv-riddhi-img .group-year {
  width: auto;
  margin-top: 0;
  font-size: 24px;
}
@media (min-width: 1395px) {
  .group-info-box.dgv-riddhi-img .group-year {
    margin-top: 5px;
  }
}
.group-info-box.insurance-img {
  left: unset;
  right: 9%;
  top: 50%;
}
@media (min-width: 1395px) {
  .group-info-box.insurance-img {
    right: 10%;
  }
}
.group-info-box.insurance-img img {
  width: 108px;
}
.group-info-box.insurance-img .group-year {
  font-size: 27px;
  margin-top: 0;
}
@media (min-width: 1395px) {
  .group-info-box.insurance-img .group-year {
    margin-top: 6px;
  }
}
.group-info-box a {
  text-decoration: none;
  color: #094e8f;
  padding-top: 5px;
}
.group-year {
  width: 75px;
  display: flex;
  justify-content: center;
  margin-top: 8%;
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;
}
@media (min-width: 1395px) {
  .group-year {
    margin-top: 13%;
  }
}
.group-tech-img {
  position: absolute;
  width: 70px;
  top: 170px;
  left: 662px;
}
.group-tech-img img {
  width: 100%;
}

.group-tech-name {
  position: absolute;
  top: 265px;
  left: 642px;
  text-align: center;
  line-height: 20px;
}
.group-tech-year {
  position: absolute;
  top: 310px;
  left: 630px;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  color: #ffffff;
}

.group-insur-img {
  position: absolute;
  width: 100px;
  top: 360px;
  left: 942px;
}
.group-insur-img img {
  width: 100%;
}
.group-insur-name {
  position: absolute;
  top: 470px;
  left: 965px;
}
.group-insur-year {
  position: absolute;
  top: 503px;
  left: 958px;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;
}

/* .group-history-container {
  width: 100%;  
  height: 565px;
  background-color: wheat;
} */
.group-history-img {
  width: 100%;
  position: relative;
  top: 50%;
}
.Group-year-list {
  width: 100%;
  /* position: absolute; */
  top: 50%;
  display: flex;
  justify-content: space-around;
  background-color: #575757;
  border-radius: 20px;
  padding: 15px;
}
.list-box {
  font-weight: 700;
  color: #ffffff;
  font-size: 25px;
  font-family: 'Quantico', sans-serif;
  /* padding-top: 160px;
  margin-top: 0px; */
}
/* .Group-year-list :nth-child(1) {
  padding-top: 160px;
  margin-top: 0px;
} */
.group-history-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  /* top: 100px; */
}

.group-history-img-top-wrapper {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.group-history-img-bottom-wrapper {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.group-history-img-top:nth-child(1) {
  width: 160px;
  position: relative;
  top: 38px;
  left: -57px;
  z-index: 1;
}
.group-history-img-top:nth-child(2) {
  width: 136px;
  position: relative;
  top: 50px;
  left: 50px;
  z-index: 1;
}
.group-history-img-top:nth-child(3) {
  width: 133px;
  position: relative;
  top: 50px;
  left: 102px;
  z-index: 1;
}
.group-history-img-top:nth-child(4) {
  width: 135px;
  position: relative;
  top: 50px;
  left: 122px;
  z-index: 1;
}
.group-history-img-top:nth-child(5) {
  width: 129px;
  position: relative;
  top: 50px;
  left: 147px;
  z-index: 1;
}

.group-history-img-bottom:nth-child(1) {
  width: 144px;
  position: relative;
  top: -28px;
  left: -70px;
}
.group-history-img-bottom:nth-child(2) {
  width: 136px;
  position: relative;
  top: -28px;
  left: 70px;
}
.group-history-img-bottom:nth-child(3) {
  width: 133px;
  position: relative;
  top: -28px;
  left: 111px;
}
.group-history-img-bottom:nth-child(4) {
  width: 135px;
  position: relative;
  top: -28px;
  left: 132px;
}

@media (max-width: 600px) {
  .group-title {
    /* position: absolute; */
    width: 80%;
    /* height: 160px; */
    /* height: 110%; */
    top: 17%;
    left: calc((100% - 80%) / 2);
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 45px;
    font-weight: 700;
    color: #094e8f;
  }
  .group-title {
    width: 80%;
    position: absolute;
    top: 105%;
  }
  .group-title img {
    width: 100%;
  }
  .group-box {
    position: absolute;
    width: 37px;
    top: 165px;
    left: 65px;
  }
  .group-info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 77px;
    left: 10%;
  }
  .group-info-box:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    left: 10%;
  }
  .group-info-box:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 77px;
    left: 10%;
  }
  .group-info-box:nth-child(3) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 77px;
    left: 10%;
  }
  .group-info-box.dgv-riddhi-img img {
    width: 64px;
  }

  .group-info-box.dgv-riddhi-img .group-year {
    width: auto;
    margin-top: 8px;
    font-size: 7px;
  }
  .group-info-box.insurance-img img {
    width: 50px;
  }
  .group-info-box.insurance-img .group-year {
    font-size: 7px;
    margin-top: 3px;
  }
  .group-info-box a {
    text-decoration: none;
    color: #094e8f;
    padding-top: 3px;
    font-size: 5px;
  }
  .group-year {
    width: 67px;
    display: flex;
    justify-content: center;
    margin-top: 18%;
    font-size: 7px;
    font-weight: 700;
    color: #ffffff;
  }
  .group-tech-img {
    position: absolute;
    width: 27px;
    top: 13%;
    left: calc((100% - 20px) / 2);
  }
  .group-tech-img img {
    width: 100%;
  }
  .group-tech-name {
    position: absolute;
    top: 18%;
    left: calc((100% - 24px) / 2);
    font-size: 4px;
    text-align: center;
    line-height: 5px;
    width: 30px;
  }
  .group-tech-name a {
    text-decoration: none;
    color: #094e8f;
    padding-top: 3px;
    font-size: 4px;
  }
  .group-tech-year {
    position: absolute;
    top: 149px;
    left: 175px;
    text-align: center;
    font-size: 9px;
    font-weight: 700;
    color: #ffffff;
  }

  .group-insur-img {
    position: absolute;
    width: 38px;
    top: 165px;
    left: 292px;
  }
  .group-insur-img img {
    width: 100%;
  }
  .group-insur-name {
    position: absolute;
    top: 206px;
    left: 297px;
    font-size: 8px;
  }

  .group-insur-year {
    position: absolute;
    top: 222px;
    left: 298px;
    text-align: center;
    font-size: 9px;
    font-weight: 700;
    color: #ffffff;
  }
  .group-history-container {
    align-items: center;
    margin: 130px auto;
  }
  .group-history-right {
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
  .group-history-left {
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .group-history-right :nth-child(2) {
    top: 41%;
  }
  .group-history-right :nth-child(3) {
    top: 54%;
  }
  .group-history-right :nth-child(4) {
    top: unset;
    bottom: 13%;
  }
  .group-history-right :nth-child(5) {
    top: unset;
    bottom: 0%;
  }

  .group-history-left :nth-child(2) {
    top: 65%;
  }
  .group-history-left :nth-child(3) {
    top: unset;
    bottom: 2%;
  }
  .group-history-left :nth-child(3) {
    top: unset;
    bottom: 0%;
  }
  .right-img {
    width: 130px;
    position: absolute;
    right: 25px;
    top: 35px;
  }

  .left-img {
    width: 130px;
    position: absolute;
    left: 27px;
    top: 25%;
  }
  .Group-year-list {
    width: 25%;
    /* height: 1560px; */
    position: relative;
    /* top: 50%; */
    display: flex;
    background-color: #575757;
    border-radius: 20px;
    padding: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .list-box {
    font-weight: 700;
    color: #ffffff;
    font-size: 25px;
    font-family: 'Quantico', sans-serif;
    padding-top: 160px;
    margin-top: 0px;
  }
  .Group-year-list :nth-child(2) {
    padding-top: 97px;
  }
  .Group-year-list :nth-child(3) {
    padding-top: 215px;
    margin-top: 67px;
  }
  .Group-year-list :nth-child(4) {
    padding-top: 490px;
  }
  /* new */
  .year-2019 {
    position: absolute;
    /* right: 0px; */
    left: 50%;
    top: 0px;
    width: 500px;
    /* display: flex;
    align-items: start;
    justify-content: center; */
  }
  .year-number {
    /* display: flex
; */
    color: #ffffff;
    font-family: Quantico;
    font-size: 16px;
    font-weight: 700;
    line-height: 22.88px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .dgv-roadmap-mob {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .group-info-box.dgv-riddhi-img {
    top: 14%;
    left: 40%;
  }
  .group-info-box.dgv-riddhi-img .group-year {
    width: auto;
    margin-top: 9px;
    font-size: 7px;
  }
  .group-info-box {
    top: 55%;
    left: 11%;
  }
  .group-info-box.insurance-img {
    left: unset;
    right: 11%;
    top: 54%;
  }
  .group-info-box.insurance-img .group-year {
    font-size: 7px;
    margin-top: 5px;
  }
}
@media (max-width: 395px) {
  .group-info-box.dgv-riddhi-img {
    top: 14%;
    left: 40%;
  }
  .group-info-box.dgv-riddhi-img .group-year {
    width: auto;
    margin-top: 9px;
    font-size: 7px;
  }
  .group-info-box {
    top: 55%;
    left: 11%;
  }
  .group-info-box.insurance-img {
    left: unset;
    right: 11%;
    top: 54%;
  }
  .group-info-box.insurance-img .group-year {
    font-size: 7px;
    margin-top: 5px;
  }
}
@media (max-width: 380px) {
  .group-info-box.dgv-riddhi-img {
    top: 12%;
    left: 39%;
  }
  .group-info-box.dgv-riddhi-img .group-year {
    width: auto;
    margin-top: 8px;
    font-size: 7px;
  }
  .group-info-box {
    top: 51%;
    left: 11%;
  }
  .group-info-box.insurance-img {
    left: unset;
    right: 10%;
    top: 51%;
  }
  .group-info-box.insurance-img .group-year {
    font-size: 7px;
    margin-top: 5px;
  }
}
@media (max-width: 365px) {
  .group-info-box.dgv-riddhi-img {
    top: 12%;
    left: 38%;
  }
  .group-info-box.dgv-riddhi-img .group-year {
    width: auto;
    margin-top: 8px;
    font-size: 7px;
  }
  .group-info-box {
    top: 51%;
    left: 11%;
  }
  .group-info-box.insurance-img {
    left: unset;
    right: 10%;
    top: 51%;
  }
  .group-info-box.insurance-img .group-year {
    font-size: 7px;
    margin-top: 3px;
  }
}
