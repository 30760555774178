.product-content {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* padding-right: 25px; */
  width: 49%;
}

.product-info-box .product-content {
  /* flex: 1; */
  width: 50%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  color: #060606;
  padding-right: 2rem;
}
.product-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.product-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  /* font-size: 40px; */
  font-size: 2.5rem;
  line-height: 50px;
  color: #094e8f;
  /* width: 583px; */
}

.product-description {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  /* font-size: 20px; */
  font-size: 1.25rem;
  line-height: 30px;
  padding-top: 30px;
  padding-bottom: 50px;
  width: 545px;
}
.Utube-btn-container {
  display: flex;
  margin-top: 18px;
}
.youtube-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 40px;
}
.lang-text {
  font-size: 16px;
  line-height: 24px;
  font-family: 'poppins', sans-serif;
  font-weight: 400;
  color: #060606;
  padding-top: 2px;
}
.youtube-img {
  width: 164px;
  height: 41px;
  object-fit: contain;
  margin-top: 85px;
}

@media (max-width: 1112px) {
  .Utube-btn-container {
    display: flex;
    margin-bottom: 28px;
    margin-top: 0;
  }
  .flex-start {
    justify-content: start;
  }
  .flex-around {
    justify-content: space-around;
  }
  .product-info-box {
    flex-direction: column;
  }
  .product-content {
    order: 2;
    width: 100%;
    margin-top: 35px;
    padding-right: 0;
  }

  .product-title {
    font-size: 25px;
    line-height: 37.5px;
    width: 100%;
    color: #094e8f;
  }

  .product-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
  .youtube-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 0;
    padding-bottom: 0;
    padding-right: 20px;
  }
  .youtube-img {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .product-info-box .product-content {
    width: 100%;
  }
  /* .product-description {
    width: 315px;
  } */
  /* .product-title {
    width: 315px;
  } */
}
