img {
  width: 100%;
}

.apply_confirm_popup {
  width: 40%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
}

.apply_confirm_header {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #094e8f;
  padding: 46px 0;
  border-radius: 6px;
  position: relative;
}
.green_label {
  width: 60px;
  height: 60px;
  background-image: url(../../assets/images/green_tick.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  margin-top: 12px;
}

.apply_confirm_content {
  width: 100%;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 8% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.thanks-text {
  width: auto;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  color: #262626;
  font-weight: 600;
  text-align: center;
}

.applied-position {
  width: auto;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  color: #094e8f;
  font-weight: 600;
  text-align: center;
  margin-bottom: 25px;
}

.ref_id_title {
  width: auto;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  color: #262626;
  font-weight: 600;
  text-align: center;
  line-height: 15px;
  margin-bottom: 10px;
}
.ref-id {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  color: #262626;
  font-weight: 600;
  text-align: center;
  line-height: 20px;
  margin-bottom: 20px;
}

.clipboard {
  width: 13px;
  height: 13px;
  background-image: url(../../assets/images/clipboard.svg);
  background-position: center;
  background-size: 100%;
  cursor: pointer;
  border: 0;
  margin-left: 5px;
}

@media (max-width: 768px) {
  .apply_confirm_popup {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .apply_confirm_popup {
    width: 90%;
  }
  .clipboard {
    width: 16px;
    height: 16px;
  }
}
